import fetchData from '../../../../shared/common/fetch-data';
import { HttpMethods } from '../../../../shared/common/types/http';
import { DataTrackFromTimestamp, DataTrackLastTimestamps } from '../../types/data-track';
import { projectConfigInstance } from '../../../../shared/globals/project-config/project-config-accessor';
import { isDataTrackFromTimestampDTO } from '../../types/dto/validators';
import { joinPaths } from '../../../../shared/utils';

export function createDataTrackFromTimestampUrl(controlProcedureId: string): string {
  return joinPaths(
    projectConfigInstance.get('PMS_BACKEND_URL'),
    'control-procedures',
    controlProcedureId,
    'data-tracks',
    'from-timestamp',
  );
}

export async function fetchDataTracksFromTimestamp(
  accessToken: string,
  controlProcedureId: string,
  dataTracks: DataTrackLastTimestamps,
): Promise<DataTrackFromTimestamp[]> {
  const result = await fetchData(createDataTrackFromTimestampUrl(controlProcedureId), {
    method: HttpMethods.Post,
    headers: {
      Accept: 'application/json',
      'Accept-Encoding': 'gzip, deflate, br',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(dataTracks),
  });

  if (!Array.isArray(result)) {
    throw new Error('Expected to receive an array');
  }
  try {
    return result.map((dto) => {
      isDataTrackFromTimestampDTO(dto);

      return dto;
    });
  } catch {
    throw new Error('Invalid Data');
  }
}
