import { DataTrackType } from '../../common/types/process-record';
import { FRACTIONAL_DIGITS } from '../../common/types/process-record/process-record';

import {
  ChartVariant,
  ChartSettings,
  Param,
  Series,
  SeriesName,
  SeriesLegend,
  SeriesLegendData,
} from './time-series-chart.definitions';

export const DATA_ZOOM_HEIGHT = 40;

export const PLOT_HEIGHT = 320;
export const PLOT_SMALL_HEIGHT = 190;

export const CHART_TITLE_HEIGHT = 60;
export const CHART_TITLE_MARGIN = 11;

export const RESET_BUTTON_MARGIN = 4;
export const RESET_BUTTON_LEFT_MARGIN = 30;

export const MAX_SERIES_LENGTH = 8;

/** This is the amount of buffer that is left at the top and bottom of the Y-Axis in % */
export const YAXIS_RANGE_BUFFER = 0.2;

export const YAXIS_LABEL_HEIGHT = 102;
export const YAXIS_LABEL_SMALL_HEIGHT = 75;

export const LEGEND_HEIGHT = 45;
export const LEGEND_TOP = 40;
export const TOOLBOX_HEIGHT = 50;
export const GRID_MARGIN = 30;

export const CHART_HEIGHT = PLOT_HEIGHT + YAXIS_LABEL_HEIGHT;
export const CHART_SMALL_HEIGHT = PLOT_SMALL_HEIGHT + YAXIS_LABEL_SMALL_HEIGHT;

export function getChartSettings(
  variant: ChartVariant,
  combined: boolean,
  showZoom: boolean,
  showLegend: boolean,
): ChartSettings {
  const zoomHeight = showZoom ? DATA_ZOOM_HEIGHT : 0;
  const legendHeight = showLegend ? LEGEND_HEIGHT : 0;
  const setting = {
    small: {
      height: CHART_SMALL_HEIGHT + legendHeight + zoomHeight,
      chartHeight: CHART_SMALL_HEIGHT + zoomHeight,
      yAxisOffset: 30,
      fontSize: 8,
      lineHeight: 0.5,
      lineStyle: {
        width: 2,
      },
      markLine: {
        fontSize: 10,
      },
      dataZoom: {
        left: 38,
        height: zoomHeight,
      },
      toolbox: {
        itemGap: 30,
      },
      legend: {
        height: legendHeight,
      },
      grid: {
        ...(combined
          ? {
              bottom: YAXIS_LABEL_SMALL_HEIGHT + zoomHeight,
              right: 20,
            }
          : {
              height: PLOT_SMALL_HEIGHT - CHART_TITLE_HEIGHT,
              left: 40,
              right: 20,
            }),
      },
    },
    large: {
      height: CHART_HEIGHT + legendHeight + zoomHeight,
      chartHeight: CHART_HEIGHT + zoomHeight,
      yAxisOffset: 42,
      lineStyle: {
        width: 2,
      },
      markLine: {
        fontSize: 12,
      },
      dataZoom: {
        left: 63,
        height: zoomHeight,
      },
      toolbox: {
        itemGap: 8,
      },
      legend: {
        height: legendHeight,
      },
      grid: {
        ...(combined
          ? {
              bottom: YAXIS_LABEL_HEIGHT + zoomHeight,
              right: 20,
            }
          : {
              height: PLOT_HEIGHT - CHART_TITLE_HEIGHT,
              left: 65,
              right: 20,
            }),
      },
    },
  };
  return setting[variant];
}

// custom tooltip formatter as described in the echarts documentation
// https://echarts.apache.org/en/option.html#tooltip.formatter
export function useTooltipFormatter(
  dataTracks: Record<SeriesName, Series[]>,
  xAxisFormatter: (ts: number) => void,
) {
  function tooltipFormatter(params: Param[]): string {
    let tooltipContent = '';
    params.forEach((param, index) => {
      const timeFormatted = xAxisFormatter(param.data.ts);
      const dataTrack = Object.values(dataTracks)
        .flatMap((item) => item)
        .find((item: Series) => item.color === param.color);
      tooltipContent += `${timeFormatted} <br/>
        ${param.marker} <b>${param.data.v.toFixed(
        dataTrack?.fractionalDigits || FRACTIONAL_DIGITS,
      )}</b>
        ${param.seriesName} <br/>`;

      if (index + 1 < params.length) {
        tooltipContent += '<br/>';
      }
    });

    return tooltipContent;
  }

  return tooltipFormatter;
}

export const yAxisDataRangeMin = (value: { min: number; max: number }) => {
  const rangeBasedMin = Math.floor(value.min - (value.max - value.min) * YAXIS_RANGE_BUFFER);
  return value.min < 0 ? rangeBasedMin : Math.max(0, rangeBasedMin);
};

export function formatSeriesName(dataTrackType: DataTrackType, engineeringUnit: string) {
  return `${dataTrackType} (${engineeringUnit})`;
}

export function getLegendData(
  seriesLegend?: Record<DataTrackType, SeriesLegend>,
): Array<SeriesLegendData> | null {
  if (!seriesLegend) {
    return null;
  }
  return Object.keys(seriesLegend).map((type: DataTrackType) => ({
    name: formatSeriesName(type, seriesLegend[type].engineeringUnit),
    itemStyle: {
      color: seriesLegend[type].color,
    },
    lineStyle: {
      color: seriesLegend[type].color,
    },
  }));
}
