import { joinPaths } from '../../../../shared/utils';
import { DataTrack } from '../../../../shared/common/types/process-record';
import fetchData from '../../../../shared/common/fetch-data';
import { HttpMethods } from '../../../../shared/common/types/http';
import { projectConfigInstance } from '../../../../shared/globals/project-config/project-config-accessor';
import {
  CONTROL_PROCEDURES,
  ControlProcedureId,
  DATA_TRACKS,
  SETUP,
  SetupId,
} from '../../types/setup';
import { DataTrackCustom } from '../../../../shared/components/data-track-create-modal/data-track-create-modal.definitions';

export function createSaveDataTracksUrl(controlProcedureId: ControlProcedureId, setupId: SetupId) {
  return joinPaths(
    projectConfigInstance.get('PMS_BACKEND_URL'),
    CONTROL_PROCEDURES,
    controlProcedureId,
    SETUP,
    setupId,
    DATA_TRACKS,
  );
}

export async function saveDataTracks(
  controlProcedureId: ControlProcedureId,
  setupId: SetupId,
  dataTracks: DataTrackCustom[],
  accessToken: string,
) {
  return fetchData<DataTrack[]>(createSaveDataTracksUrl(controlProcedureId, setupId), {
    method: HttpMethods.Post,
    headers: {
      Accept: 'application/json',
      'Accept-Encoding': 'gzip, deflate, br',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(dataTracks),
  });
}
