import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { StageKey, onStage } from '../data-track-upload-modal.definitions';
import { FileInput } from '../file-input';
import { MetadataForm } from '../metadata-form';
import { DataTrack } from '../../../../shared/common/types/process-record';
import useProcessRecord from '../../../../shared/common/hooks/use-process-record';
import Dot from '../../../../shared/components/dot/dot';
import { UploadForm } from '../upload-form';
import { caclulateProgressFromStage } from '../data-track-upload-modal.helpers';

import { ModalContentProps } from './modal-content.definitions';

function ModalContent({ stage, setStage, processRecordId }: ModalContentProps) {
  const { data: processRecord } = useProcessRecord(processRecordId);

  const handleFile = (file: File) => setStage({ key: StageKey.Selected, file });

  const handleDeselectFile = () => setStage({ key: StageKey.Unselected });

  const handleMetadataChange = (data: DataTrack[], isValid: boolean) => {
    /** @type { Uploaded } prev */
    setStage((prev) => ({ ...prev, data, isValid }));
  };

  return (
    <>
      {onStage(stage, StageKey.Unselected) && <FileInput handleFile={handleFile} />}

      {onStage(stage, StageKey.SubmitFailed, StageKey.UploadFailed) && (
        <div className="mb-4 rounded border border-red-400 bg-red-25 p-4 text-red-400">
          <FormattedMessage
            description="Upload Or Submit Datatrack Network Error Message"
            defaultMessage="{message}"
            id="rUeL5h"
            values={{
              message: stage.error.message,
            }}
          />
        </div>
      )}

      {onStage(stage, StageKey.Selected, StageKey.Uploading, StageKey.UploadFailed) && (
        <UploadForm
          fileName={stage.file.name}
          handleDeselectFile={handleDeselectFile}
          progress={caclulateProgressFromStage(stage)}
          isUploading={stage.key === StageKey.Uploading}
        />
      )}

      {onStage(stage, StageKey.Uploaded, StageKey.Submitting, StageKey.SubmitFailed) && (
        <MetadataForm
          takenNames={processRecord?.dataTracks.map(({ displayName }) => displayName) ?? []}
          datatracks={stage.data}
          onChange={handleMetadataChange}
          isDisabled={onStage(stage, StageKey.Submitting)}
        />
      )}

      {onStage(stage, StageKey.Submitted) && (
        <FormattedMessage
          description="Submit Datatrack Success Message"
          defaultMessage="Datatracks were added to the proccess record."
          id="gUVNQ5"
        />
      )}

      {onStage(stage, StageKey.Unselected, StageKey.UploadFailed) && (
        <p className="mt-3">
          <FormattedMessage
            description="Upload Datatrack Format Sentence"
            defaultMessage="For a description of the file format we expect"
            id="/Pe+od"
          />
          <Link to="/analytics/process-records/datatrack-format" className="mx-1 text-blue">
            <FormattedMessage
              description="Upload Datatrack Format Sentence"
              defaultMessage="click here"
              id="WaGXJL"
            />
          </Link>
          <FormattedMessage
            description="Upload Datatrack Example Sentence"
            defaultMessage="or download this"
            id="RFkefe"
          />
          <a
            download="external-datatrack.csv"
            href="/assets/samples/external-datatrack.csv"
            className="ml-1 text-blue"
          >
            <FormattedMessage
              description="Upload Datatrack Example Link"
              defaultMessage="example file"
              id="ESZZWh"
            />
          </a>
          <Dot />
        </p>
      )}
    </>
  );
}

export default ModalContent;
