import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import { DataTrackId, ProcessRecordId, ProcessRecordObject } from '../../types/process-record';
import QKey from '../keys';

import { deleteDataTrack } from './use-delete-custom-data-track.helpers';

function useDeleteCustomDataTrack(processRecordId: ProcessRecordId) {
  /** query key for the process record to which this data track belongs and is stored */
  const sourceQueryKey = [QKey.PROCESS_RECORDS, processRecordId];

  const queryClient = useQueryClient();

  const { acquireAccessToken } = useAuthentication();

  return useMutation({
    mutationFn: async (dataTrackId: DataTrackId) => {
      const accessToken = await acquireAccessToken();
      return deleteDataTrack(processRecordId, dataTrackId, accessToken);
    },
    onMutate: (dataTrackId: DataTrackId) => {
      const previousProcessRecordData =
        queryClient.getQueryData<ProcessRecordObject>(sourceQueryKey);

      if (!previousProcessRecordData) {
        throw new Error(`Expected query with key ${JSON.stringify(sourceQueryKey)} to exist.`);
      }

      queryClient.setQueryData<ProcessRecordObject>(sourceQueryKey, {
        ...previousProcessRecordData,
        dataTracks: previousProcessRecordData.dataTracks.filter(
          (dt) => dt.dataTrackId !== dataTrackId,
        ),
      });

      return previousProcessRecordData;
    },
    onError: (_, __, previous) => {
      queryClient.setQueryData(sourceQueryKey, previous);
    },
  });
}

export default useDeleteCustomDataTrack;
