import fetchData from '../../../../shared/common/fetch-data';
import { HttpMethods } from '../../../../shared/common/types/http';
import { ProcessRecordDescriptor } from '../../../../shared/common/types/process-record';
import { createProcessRecordUrl } from '../../../../shared/common/hooks/use-multiple-process-records/use-multiple-process-records.helpers';
import { joinPaths } from '../../../../shared/utils';
import { projectConfigInstance } from '../../../../shared/globals/project-config';
import { ProcessRecordCreationDto } from '../../../components/add-process-record-modal/create-process-record-via-form/create-process-record-via-form.definitions';

export function createEditProcessRecordAttributesUrl(processRecordId: string) {
  return `${createProcessRecordUrl(processRecordId)}`;
}

export async function createProcessRecord(
  data: ProcessRecordCreationDto,
  accessToken: string,
): Promise<ProcessRecordDescriptor> {
  // set milliseconds and seconds to 0 as they cannot be editted in the frontend
  data.startTimestamp.setMilliseconds(0);
  data.stopTimestamp.setMilliseconds(0);
  data.inoculationTimestamp?.setMilliseconds(0);

  data.startTimestamp.setSeconds(0);
  data.stopTimestamp.setSeconds(0);
  data.inoculationTimestamp?.setSeconds(0);

  const json = JSON.stringify({
    ...data,
  });

  const response = await fetchData(
    joinPaths(projectConfigInstance.get('PRS_BACKEND_URL'), 'process-records'),
    {
      method: HttpMethods.Post,
      headers: {
        Accept: 'application/json',
        'Accept-Encoding': 'gzip, deflate, br',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: json,
    },
  );
  return response as ProcessRecordDescriptor;
}
