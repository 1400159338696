import React, { FunctionComponentElement } from 'react';

import useFormatNumber from '../../../shared/common/hooks/use-format-number';
import { FRACTIONAL_DIGITS } from '../../../shared/common/types/process-record/process-record';

import { DataTrackInfo } from './data-track-value.definitions';

export const valuePlaceholder = '--';

function DataTrackValue({
  engineeringUnit,
  value,
  fractionalDigits = FRACTIONAL_DIGITS,
}: DataTrackInfo): FunctionComponentElement<DataTrackInfo> {
  const valueFormatter = useFormatNumber();
  const valueFormatted =
    typeof value === 'number' ? valueFormatter(value, fractionalDigits) : valuePlaceholder;

  return (
    <span>
      {valueFormatted}&#8202;{engineeringUnit}
    </span>
  );
}

export default DataTrackValue;
