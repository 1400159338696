import fetchData from '../../../../shared/common/fetch-data';
import { HttpMethods } from '../../../../shared/common/types/http';
import { isDataTrackDTO } from '../../types/dto/validators';
import { projectConfigInstance } from '../../../../shared/globals/project-config/project-config-accessor';
import { DataTrackId, DataTrack } from '../../../../shared/common/types/process-record';
import { joinPaths } from '../../../../shared/utils';

export function createDataTrackUrl(setupId: string, dataTrackId: string): string {
  return joinPaths(
    projectConfigInstance.get('PMS_BACKEND_URL'),
    'setups',
    setupId,
    'data-tracks',
    dataTrackId,
  );
}

export async function fetchDataTrack(
  setupId: string,
  dataTrackId: DataTrackId,
  accessToken: string,
): Promise<DataTrack> {
  const result = await fetchData(createDataTrackUrl(setupId, dataTrackId), {
    method: HttpMethods.Get,
    headers: {
      Accept: 'application/json',
      'Accept-Encoding': 'gzip, deflate, br',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  try {
    isDataTrackDTO(result);
    return result;
  } catch {
    throw new Error('Invalid Data');
  }
}
