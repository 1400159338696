import { z } from 'zod';

import { DataTrackCustom } from '../data-track-create-modal.definitions';

export const DUPLICATE_ENTRY_MSG = 'This Data Track name was already entered.';
export const TAKEN_TYPE_MSG = 'A Data Track with this name already exists.';
export const EMPTY_TYPE_MSG = 'Please enter a Data Track name.';

export const dataTrackTypeSchema = z
  .string({ required_error: EMPTY_TYPE_MSG })
  .refine((val) => val.trim().length !== 0, {
    message: EMPTY_TYPE_MSG,
  });

/**
 * validate each neccessary field of a datatrack and check if the datatrack is valid as a whole
 * @param datatrack
 */
export const validate = (
  { dataTrackType }: Pick<DataTrackCustom, 'dataTrackType'>,
  localTypes: string[],
  takenTypes: string[],
) => {
  const typeRes = dataTrackTypeSchema
    .refine(
      (val) => localTypes.filter((type) => type.trim() === val.trim()).length <= 1, // should not be a duplicate entry
      {
        message: DUPLICATE_ENTRY_MSG,
      },
    )
    .refine(
      (val) => takenTypes.map((type) => type.trim()).includes(val.trim()) === false, // should not already exist
      {
        message: TAKEN_TYPE_MSG,
      },
    )
    .safeParse(dataTrackType);

  return {
    fields: { typeRes },
    isValid: typeRes.success,
  };
};
