import { useIntl } from 'react-intl';

import { DataPoint } from './data-point-create-modal.definitions';

/**
 * validate data points and find indexes of timestamp duplicates
 * @param dataPoints
 */
// eslint-disable-next-line import/prefer-default-export -- validators file
export function useValidate(dataPoints: DataPoint[], dataPointsSavedLength: number) {
  const intl = useIntl();
  const isNewDataPoints = dataPoints.length > dataPointsSavedLength;
  if (isNewDataPoints) {
    const isDataValid =
      Boolean(dataPoints.length) && dataPoints.every((item) => item.ts && item.v !== '');

    // array of timestamps in milliseconds
    const timestamps = dataPoints.map((item) => new Date(item.ts).getTime());

    const timestampDuplicates: number[] = [];
    timestamps.forEach((element, index) => {
      if (timestamps.indexOf(element) !== index) {
        timestampDuplicates.push(timestamps.indexOf(element));
        timestampDuplicates.push(index);
      }
    });

    const isTimestapmDuplicates = Boolean(timestampDuplicates.length);

    return {
      isValid: isDataValid && !isTimestapmDuplicates,
      validationError: isTimestapmDuplicates
        ? intl.formatMessage({
            defaultMessage: 'This time point does already exist.',
            description: 'Add data points validation: Timestamp duplicates error message',
            id: 'FGzYzT',
          })
        : '',
      timestampDuplicates,
    };
  }

  // return only invalid state if there are no new data points
  return {
    isValid: false,
    validationError: '',
    timestampDuplicates: [],
  };
}
