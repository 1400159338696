import { useQueryClient } from '@tanstack/react-query';

import QKey from '../../../../shared/common/hooks/keys';
import useUpload from '../../../../shared/common/hooks/use-upload';

import createUploadProcedureResultUrl from './use-upload-procedure-result.helpers';

export default function useUploadProcedureResult() {
  const queryClient = useQueryClient();
  return useUpload({
    key: [QKey.PROCESS_RECORDS],
    url: createUploadProcedureResultUrl(),
    onSuccess() {
      queryClient.cancelQueries({ queryKey: [QKey.PROCESS_RECORDS] }); // cancel any ongoing fetches
      queryClient.invalidateQueries({ queryKey: [QKey.PROCESS_RECORDS] }); // refetch new data
    },
  });
}
