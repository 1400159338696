import React, { FormEvent, FunctionComponentElement } from 'react';
import { Input } from '@biss/react-horizon-web';

import { MetadataFormProps } from './metadata-form.definitions';
import { validate } from './metadata-form.validation';

function MetadataForm({
  dataTracks,
  onChange,
  isDisabled,
  takenTypes,
  localTypes,
}: MetadataFormProps): FunctionComponentElement<MetadataFormProps> {
  const onChangeDataTrack = (event: FormEvent<HTMLInputElement>, index: number) => {
    const { value } = event.currentTarget;
    const { name } = event.currentTarget;
    const dataTrack = dataTracks[index];
    onChange({ ...dataTrack, [name]: value }, index);
  };
  return (
    <div className="flex flex-col">
      <div className="mb-4 grid grid-cols-2 gap-4">
        {dataTracks.map(({ dataTrackType, engineeringUnit, isEdited }, index) => {
          const { typeRes } = validate({ dataTrackType }, localTypes, takenTypes).fields;
          return (
            // eslint-disable-next-line react/no-array-index-key -- no unique prorerty
            <React.Fragment key={`dataTrack-${index}`}>
              <Input
                expand="auto"
                data-testid="dataTrackTypeInput"
                label="Data Track Name"
                type="text"
                value={dataTrackType}
                name="dataTrackType"
                error={!isEdited || typeRes.success ? undefined : typeRes.error.errors?.[0].message}
                onChange={(e) => onChangeDataTrack(e, index)}
                disabled={isDisabled}
              />
              <Input
                expand="auto"
                data-testid="engineeringUnitInput"
                label="Engineering Unit"
                type="text"
                value={engineeringUnit}
                name="engineeringUnit"
                onChange={(e) => onChangeDataTrack(e, index)}
                disabled={isDisabled}
              />
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}

export default MetadataForm;
