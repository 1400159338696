import { useQuery } from '@tanstack/react-query';

import QKey from '../keys';
import FKey from '../../feature-keys';
import useLogger from '../use-logger/use-logger';

import { fetchFeatureFlag } from './use-feature-flag.helpers';

const useFeatureFlag = (flag: FKey): boolean => {
  const logging = useLogger();

  const queryResult = useQuery({
    queryKey: [QKey.FEATURE, flag],
    queryFn: async () => fetchFeatureFlag(flag, logging),
    staleTime: 300_000, // 5min
  });

  return queryResult.data === true;
};

export default useFeatureFlag;
