/**
 * react query cache keys
 */
const enum QKey {
  // analysis
  PROCESS_RECORDS = 'process-records',
  /** used for data tracks that only exist client side (e.g. calculated data tracks that have not been saved) */
  LOCAL_DATA_TRACKS = 'local-data-tracks',
  EVENTS = 'events',
  PARSED_DATA_TRACK = 'parsed-data-track', // parsing
  CALCULATED_DATA_TRACK = 'calculated-data-track', // calculation
  EXTERNAL_DATA_TRACK = 'external-data-track', // submission

  // recipe-optimization
  RECIPE_OPTIMIZATION = 'recipe-optimization',

  // monitoring
  SETUPS = 'setups',
  DATA_POINTS = 'data-points',
  CUSTOM_DATA_TRACKS = 'custom-data-tracks',

  // user-mangement
  ORGANIZATIONS = 'organizations',
  DATAHOWLAB_CONFIGURATION = 'datahowlab-configuration',
  MEMBERS = 'members',
  SUBSCRIPTIONS = 'subscriptions',

  // global
  CONFIG = 'config',
  FEATURE = 'feature-flag',
  MAX_CANVAS_SIZE = 'max-canvas-size',
}

export default QKey;
