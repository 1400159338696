import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import {
  ProcessRecordDescriptor,
  ProcessRecordId,
  ProcessRecordObject,
} from '../../types/process-record';
import QKey from '../keys';

import { deleteProcessRecord } from './use-delete-process-record.helpers';

type PreviousData = {
  list: ProcessRecordDescriptor[] | undefined;
  record: ProcessRecordObject | undefined;
};

function useDeleteProcessRecord(
  onSuccess?: (data: void, variables: string, context: unknown) => unknown,
) {
  const queryClient = useQueryClient();

  const { acquireAccessToken } = useAuthentication();

  return useMutation<void, unknown, ProcessRecordId, PreviousData>({
    mutationFn: async (processRecordId) => {
      const accessToken = await acquireAccessToken();
      return deleteProcessRecord(processRecordId, accessToken);
    },
    onMutate: (processRecordId) => {
      const previousData = {
        list: queryClient.getQueryData<ProcessRecordDescriptor[]>([QKey.PROCESS_RECORDS]),
        record: queryClient.getQueryData<ProcessRecordObject>([
          QKey.PROCESS_RECORDS,
          processRecordId,
        ]),
      };

      queryClient.setQueryData<ProcessRecordDescriptor[]>([QKey.PROCESS_RECORDS], (prev) =>
        prev?.filter((pr) => pr.processRecordId !== processRecordId),
      );

      queryClient.removeQueries({
        queryKey: [QKey.PROCESS_RECORDS, processRecordId],
      });

      return previousData;
    },
    onError: (_, processRecordId, previous) => {
      queryClient.setQueryData([QKey.PROCESS_RECORDS], previous?.list);
      queryClient.setQueryData([QKey.PROCESS_RECORDS, processRecordId], previous?.record);
    },
    onSuccess: (...params) => {
      queryClient.invalidateQueries({
        queryKey: [QKey.PROCESS_RECORDS],
      });
      onSuccess?.(...params);
    },
  });
}

export default useDeleteProcessRecord;
