import React from 'react';
import { Input } from '@biss/react-horizon-web';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { YAxisRangeInputsProps } from './y-axis-range-inputs.definitions';
import { YAxisRange, yAxisRangeSchema } from './y-axis-range-inputs.validation';
import { coerceStringToNumber } from './y-axis-range-inputs.helpers';

function YAxisRangeInputs({ range, onRangeChange, className, style }: YAxisRangeInputsProps) {
  type Inputs = YAxisRange;
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: range,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
    resolver: zodResolver(yAxisRangeSchema),
  });

  const onSubmit: SubmitHandler<Inputs> = onRangeChange;

  return (
    <form style={style} className={className} onBlur={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="max"
        render={({ field }) => (
          <Input
            // eslint-disable-next-line react/jsx-props-no-spreading -- component controlled by <Controller />
            {...field}
            label="Y-Axis Max"
            size={6}
            value={field.value ?? ''}
            onChange={(e) => field.onChange(coerceStringToNumber(e.currentTarget.value))}
            error={errors.max?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="min"
        render={({ field }) => (
          <Input
            // eslint-disable-next-line react/jsx-props-no-spreading -- component controlled by <Controller />
            {...field}
            label="Y-Axis Min"
            size={6}
            value={field.value ?? ''}
            onChange={(e) => field.onChange(coerceStringToNumber(e.currentTarget.value))}
            error={errors.min?.message}
          />
        )}
      />
    </form>
  );
}

export default YAxisRangeInputs;
