import React, { FunctionComponentElement } from 'react';

import { CustomDataPointsProps } from './custom-data-points.definition';
import AddEditDataPoints from './add-edit-data-points';

function CustomDataPoints({
  setupId,
  controlProcedureId,
  dataTrack,
  open,
  updateDataTrack,
  onChangeModal,
  selectedDataTracks,
}: CustomDataPointsProps): FunctionComponentElement<CustomDataPointsProps> | null {
  if (open && controlProcedureId && setupId && dataTrack) {
    return (
      <AddEditDataPoints
        setupId={setupId}
        dataTrack={dataTrack}
        open={open}
        onChangeModal={onChangeModal}
        controlProcedureId={controlProcedureId}
        updateDataTrack={updateDataTrack}
        isSelected={selectedDataTracks.includes(dataTrack.dataTrackType)}
      />
    );
  }
  return null;
}

export default CustomDataPoints;
