import { subtractHours } from '../../../common/helpers';
import { SetupDescriptor } from '../../../common/types';
import { DataTrackLastTimestamp } from '../../../common/types/data-track';
import { AUTO_UPDATE_NUMBER_OVERVIEW, TimeSpan } from '../../../common/types/setup';

import { MONITORING_TIME_SPAN } from './setup-overview-unit/setup-overview-unit.definitions';

export function getIsDisconnected(workflow: Array<SetupDescriptor>): boolean {
  return workflow.some((item) => item.connectionState === 'Disconnected');
}

export function setTimestamp(
  dataTracksTimestamp: DataTrackLastTimestamp[],
): DataTrackLastTimestamp[] {
  const startTimestamp = subtractHours(new Date(), MONITORING_TIME_SPAN);
  const timestamp = new Date(startTimestamp).toISOString();

  return dataTracksTimestamp.map((track: DataTrackLastTimestamp) => ({
    ...track,
    timestamp,
  }));
}

export function isFromTimestamp(
  counter: number,
  timeSpan: TimeSpan,
  gapRecoveryTimestamp?: string | null,
) {
  return counter < AUTO_UPDATE_NUMBER_OVERVIEW[timeSpan] || Boolean(gapRecoveryTimestamp);
}
