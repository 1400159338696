import { Select } from '@biss/react-horizon-web';
import React, { FunctionComponentElement } from 'react';
import { FormattedMessage } from 'react-intl';

import { DataTrackTimeAlignment } from '../../scenes/process-record-detail/process-record-visualization/process-record-visualization.definitions';

import { ProcessRecordAlignmentOptionsProps } from './process-record-alignment-options.definitions';

function ProcessRecordAlignmentOptions({
  onChangeAlignment,
  hasInoculationTime,
  defaultValue = '',
  defaultOpen = false,
}: ProcessRecordAlignmentOptionsProps): FunctionComponentElement<ProcessRecordAlignmentOptionsProps> {
  const handleAlignmentOption = (value: string) => {
    onChangeAlignment(Number(value));
  };

  return (
    <div className="flex flex-row flex-wrap items-start gap-2">
      <div className="min-w-[12rem]">
        <Select
          data-testid="alignment-combobox"
          placeholder="Select alignment option"
          onValueChange={handleAlignmentOption}
          defaultValue={defaultValue}
          defaultOpen={defaultOpen}
          expand="auto"
          label="Timestamp Alignment"
        >
          <Select.Item value={DataTrackTimeAlignment.RelativeToStartTime.toString()}>
            <FormattedMessage
              description="ProcessRecordAlignmentOptions: Button to align data tracks relative to start time"
              defaultMessage="Relative to Start Time"
              id="pT4Jj5"
            />
          </Select.Item>
          <Select.Item
            disabled={!hasInoculationTime}
            value={DataTrackTimeAlignment.RelativeToInoculationTime.toString()}
          >
            <FormattedMessage
              description="ProcessRecordAlignmentOptions: Button to align data tracks relative to inoculation time"
              defaultMessage="Relative to inoculation time"
              id="yAesx9"
            />
          </Select.Item>
          <Select.Item value={DataTrackTimeAlignment.Absolute.toString()}>
            <FormattedMessage
              description="ProcessRecordAlignmentOptions: Button to select absolute time"
              defaultMessage="Absolute time"
              id="kjhCT7"
            />
          </Select.Item>
        </Select>
      </div>
      {!hasInoculationTime && (
        <div className="mt-[18px] rounded bg-gray-200 px-2 py-1">
          <FormattedMessage
            description="ProcessRecordAlignmentOptions: Inoculation time is not available in all process records"
            defaultMessage="Not all of the selected process records have a set inoculation time. Therefore, alignment along inoculation time is not available."
            id="hvFP2D"
          />
        </div>
      )}
    </div>
  );
}

export default ProcessRecordAlignmentOptions;
