import { createCheckers } from 'ts-interface-checker';

import { SetupDTO, SetupDescriptorDTO } from '../setup-dto';
import { isValidIsoTimestamp } from '../../../../../shared/common/types/timestamp/dto/validators';

import { Event } from '../../../../../shared/common/types/event';

import exportedTypeSuite from './setup-dto-validator-types';

function timestampsAreValid(object: SetupDescriptorDTO): boolean {
  return [
    isValidIsoTimestamp(object.startTimestamp),
    object.stopTimestamp ? isValidIsoTimestamp(object.stopTimestamp) : true,
    object.inoculationTimestamp ? isValidIsoTimestamp(object.inoculationTimestamp) : true,
  ].every(Boolean);
}

export function isSetupDescriptorDTO(object: unknown): asserts object is SetupDescriptorDTO {
  const { SetupDescriptor } = createCheckers(exportedTypeSuite);

  // throws an error when object is not in the correct format
  SetupDescriptor.check(object);
  if (!timestampsAreValid(object as SetupDescriptorDTO)) {
    throw new Error();
  }
}

export function isSetupDTO(object: unknown): asserts object is SetupDTO {
  const { Setup } = createCheckers(exportedTypeSuite);

  // throws an error when object is not in the correct format
  Setup.check(object);
  if (!timestampsAreValid(object as SetupDTO)) {
    throw new Error();
  }
}

export function isSetupEvent(object: unknown): asserts object is Event {
  const { SetupEvent } = createCheckers(exportedTypeSuite);

  // throws an error when object is not in the correct format
  SetupEvent.check(object);
}
