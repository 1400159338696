import React, { FunctionComponentElement, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import EChartsReactCore from 'echarts-for-react/lib/core';
import { NotificationMessage, HORIZON_COLORS as colors } from '@biss/react-horizon-web';
import { useMediaQuery } from 'usehooks-ts';
import cn from 'classnames';

import echartsConfig from '../../common/config/echarts-features';
import { useKeyboard } from '../../common/hooks/use-keyboard';
import useLogger from '../../common/hooks/use-logger/use-logger';
import TrackedEvent from '../../common/tracked-event';
import NoDataTracksPlaceHolder from '../../assets/no-data-tracks-placeholder.svg';
import { isFirefox } from '../../utils';
import { YAxisRange } from '../../../analytics/scenes/process-record-detail/process-record-visualization/y-axis-range-inputs/y-axis-range-inputs.validation';
import YAxisRangeInputs from '../../../analytics/scenes/process-record-detail/process-record-visualization/y-axis-range-inputs';

import useShouldEnableTouch from './use-should-enable-touch';
import {
  SeriesName,
  SeriesId,
  DataPointObject,
  TimeSeriesChartProps,
  DataZoom,
  LegendSelectedChanged,
  ZoomResetDirection,
} from './time-series-chart.definitions';
import {
  getChartSettings,
  useTooltipFormatter,
  DATA_ZOOM_HEIGHT,
  CHART_TITLE_HEIGHT,
  MAX_SERIES_LENGTH,
  RESET_BUTTON_MARGIN,
  TOOLBOX_HEIGHT,
  LEGEND_TOP,
  GRID_MARGIN,
  CHART_TITLE_MARGIN,
  RESET_BUTTON_LEFT_MARGIN,
  getLegendData,
  formatSeriesName,
  yAxisDataRangeMin,
} from './time-series-chart.helpers';
import TimeSeriesChartInfoBox from './time-series-chart-info-box/time-series-chart-info-box';
import useHorizontalZoom from './use-horizontal-zoom';
import useVerticalZoom from './use-vertical-zoom';
import useResetZoom from './use-reset-zoom/use-reset-zoom';
import useMaxAllowedCanvasSize, { DEFAULT_MAX_CANVAS_SIZE } from './use-max-allowed-canvas-size';

function TimeSeriesChart({
  series,
  startTime,
  stopTime,
  variant = 'large',
  combinedGraph = false,
  showTooltip = false,
  xAxisFormatter,
  yAxisFormatter,
  useRelativeYAxis,
  seriesMarkLines,
  showToggleSplit,
  toggleSplitView,
  showLegend = false,
  seriesLegend,
  showZoom = false,
  showToolbox = false,
  legendSelected,
  tooltipStyles,
  showSideToolbox = false,
  defaultYAxisRanges,
  toggleLegendSelected,
}: TimeSeriesChartProps): FunctionComponentElement<TimeSeriesChartProps> {
  const intl = useIntl();

  // reference to the echarts component
  const echartsCore = useRef<EChartsReactCore>(null);

  // resize the plot whenever the window size changes
  useEffect(() => {
    const handler = () => echartsCore.current?.getEchartsInstance().resize();
    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  }, []);

  const [combined, setCombined] = useState(combinedGraph);
  const [toggleSplit, setToggleSplit] = useState(showToggleSplit);
  const seriesTypes: SeriesName[] = Object.keys(series);
  const tooltipFormatter = useTooltipFormatter(series, xAxisFormatter);
  const zoomX = useHorizontalZoom();

  // the "combined" zoom is used to manage zooming when the chart is combined
  const zoomY = useVerticalZoom([...seriesTypes, 'combined']);

  // range of the viewable and zoom-able area on the y axis of the chart
  const [yAxisRanges, setYAxisRanges] = useState<Map<SeriesName, YAxisRange>>(
    defaultYAxisRanges ?? new Map(),
  );

  const isMobile = useMediaQuery('(max-width: 768px)');
  const shouldEnableTouch = useShouldEnableTouch();
  const keyboard = useKeyboard();
  const logger = useLogger();
  const resetZoom = useResetZoom(zoomX, zoomY);

  // used to warn the user if the canvas element gets too big, and in firefox limit the canvas size
  const { data: maxAllowedCanvasSize = DEFAULT_MAX_CANVAS_SIZE } = useMaxAllowedCanvasSize();

  function enableLegend() {
    if (toggleLegendSelected) {
      toggleLegendSelected({});
    }
  }

  useEffect(() => {
    if (!showToggleSplit) {
      return;
    }
    if (Object.keys(series).length > MAX_SERIES_LENGTH) {
      setToggleSplit(false);
      if (combined) {
        enableLegend();
        setCombined(false);
      }
    } else {
      setToggleSplit(true);
    }
  }, [series, combined, showToggleSplit]);

  const toggleCombinedGraph = () => {
    logger.trackEvent(TrackedEvent.ToggleSplitOrCombinedChart);

    enableLegend();
    setCombined(!combined);
    resetZoom(ZoomResetDirection.Y);
    if (toggleSplitView) {
      toggleSplitView(!combined);
    }
  };

  if (!seriesTypes.length) {
    return (
      <div className="flex flex-row items-center justify-start p-4">
        <img
          className="max-h-72"
          src={NoDataTracksPlaceHolder}
          alt={intl.formatMessage({
            description: 'Time Series Chart: No data track provided.',
            defaultMessage: 'Please select a data track from the list',
            id: 'zHKDld',
          })}
        />
      </div>
    );
  }
  const chartVariant = isMobile ? 'small' : variant;
  const settings = getChartSettings(chartVariant, combined, showZoom, showLegend);

  const dataset: {
    dimensions: ['ts', 'v'];
    source: DataPointObject[];
    dataTrackId: SeriesId;
  }[] = seriesTypes.flatMap((type) =>
    series[type].map((dataTrack) => ({
      dimensions: ['ts', 'v'],
      source: dataTrack.dataPoints,
      dataTrackId: dataTrack.dataTrackId,
    })),
  );

  const yAxisSettings = () =>
    seriesTypes.map((type, index) => {
      const range: Record<'min' | 'max', null | number | Function> = {
        // calculate the min of the axis to be closer to the actual minimum of the data tracks values
        min: useRelativeYAxis ? yAxisDataRangeMin : null,
        max: null,
      };

      // override the min and max values by user input
      const maybeYAxisRange = yAxisRanges.get(type);
      if (maybeYAxisRange) {
        range.min = maybeYAxisRange.min ?? range.min;
        range.max = maybeYAxisRange.max ?? null;
      }

      return {
        type: 'value',
        name: series[type][0].engineeringUnit,
        position: 'left',
        gridIndex: combined ? 0 : index,
        alignTicks: true,
        fontSize: settings.fontSize,
        axisLine: {
          show: true,
        },
        axisLabel: {
          formatter: yAxisFormatter
            ? (value: number) => yAxisFormatter(value, series[type][0].fractionalDigits)
            : undefined,
          fontSize: settings.fontSize,
        },
        nameTextStyle: {
          fontSize: settings.fontSize,
          lineHeight: settings.lineHeight,
        },
        offset: combined ? index * settings.yAxisOffset : 0,
        ...range,
      };
    });

  const xAxisSettings = () => {
    if (combined) {
      return {
        gridIndex: 0,
        type: 'time',
        min: startTime,
        max: stopTime,
        axisLabel: {
          rotate: 45,
          fontSize: settings.fontSize,
          formatter: xAxisFormatter,
        },
      };
    }

    return seriesTypes.map((_type, index) => ({
      gridIndex: index,
      type: 'time',
      min: startTime,
      max: stopTime,
      axisLabel: {
        rotate: 45,
        fontSize: settings.fontSize,
        formatter: xAxisFormatter,
      },
    }));
  };

  const getMarkLines = () => {
    if (!seriesMarkLines) {
      return {};
    }
    return {
      symbol: ['none', 'none'],
      silent: true,
      data: seriesMarkLines.map((markLine) => ({
        name: markLine.name,
        xAxis: markLine.timestamp,
        label: {
          show: true,
          formatter: markLine.name,
          position: 'insideStartBottom',
          color: markLine.color,
          fontSize: settings.markLine.fontSize,
        },
        itemStyle: {
          color: markLine.color,
        },
        lineStyle: {
          color: markLine.color,
        },
      })),
    };
  };

  const getSeries = () =>
    seriesTypes.flatMap((type, gridIndex) =>
      series[type].map((item) => ({
        type: 'line',
        lineStyle: {
          type: item.lineType || 'line',
          width: item.width || settings.lineStyle.width,
        },
        xAxisIndex: combined ? 0 : gridIndex,
        yAxisIndex: gridIndex,
        name: formatSeriesName(item.dataTrackType, item.engineeringUnit),
        datasetIndex: dataset.findIndex((entry) => entry.dataTrackId === item.dataTrackId),
        animation: false,
        showSymbol: false,
        color: item.color,
        markLine: getMarkLines(),
      })),
    );

  const getGrid = () => {
    if (combined) {
      const toolboxHeight = showToolbox ? TOOLBOX_HEIGHT : 0;
      const topMargin = settings.legend.height + toolboxHeight;
      return {
        ...settings.grid,
        left: seriesTypes.length * settings.yAxisOffset,
        top: topMargin || GRID_MARGIN,
      };
    }
    return seriesTypes.map((_type, index) => ({
      ...settings.grid,
      top: `${settings.chartHeight * index + CHART_TITLE_HEIGHT}px`,
    }));
  };

  const getDataZoom = () => {
    if (!showZoom) {
      return null;
    }

    return seriesTypes.flatMap((track, index) => [
      {
        realtime: true,
        id: `xs${index}`,
        type: 'slider',
        start: zoomX.current.start,
        end: zoomX.current.end,
        orient: 'horizontal',
        // slider controls all x axis at the same time -> all charts zoomed simultaneously
        xAxisIndex: [0, index],
        filterMode: 'none',
        left: combined ? settings.yAxisOffset * seriesTypes.length : settings.dataZoom.left,
        right: 20,
        showDataShadow: false,
        height: DATA_ZOOM_HEIGHT,
        ...(combined
          ? { bottom: 10 }
          : {
              top:
                settings.chartHeight -
                settings.dataZoom.height -
                CHART_TITLE_MARGIN +
                settings.chartHeight * index,
            }),
        zoomLock: false,
        labelFormatter: xAxisFormatter,
      },
      {
        type: 'inside',
        id: `xi${index}`,
        start: zoomX.current.start,
        end: zoomX.current.end,
        orient: 'horizontal',
        xAxisIndex: [0, index],
        rangeMode: ['value', 'percent'],
        zoomLock: !(shouldEnableTouch || keyboard.Shift.held || keyboard.Control.held),
        zoomOnMouseWheel: 'shift',
        moveOnMouseWheel: 'ctrl',
        moveOnMouseMove: true,
        preventDefaultMouseMove: false,
        filterMode: 'none',
        labelFormatter: xAxisFormatter,
      },
      {
        type: 'slider',
        id: `y${track}`,
        start: combined
          ? zoomY.current.combined?.start
          : zoomY.current[track]?.start ?? { [track]: { start: 0, end: 100 }, ...zoomY.current },
        end: combined
          ? zoomY.current.combined?.end
          : zoomY.current[track]?.end ?? { [track]: { start: 0, end: 100 }, ...zoomY.current },
        rangeMode: ['percent', 'percent'],
        yAxisIndex: combined ? [0, index] : index,
        orient: 'vertical',
        filterMode: 'none',
        show: true,
        showDataShadow: false,
        brushSelect: false,
        showDetail: !combined,
      },
    ]);
  };

  const getToolbox = () => {
    if (!showToolbox) {
      return null;
    }
    return {
      show: true,
      itemGap: settings.toolbox.itemGap,
      feature: {
        myToggleSplit: {
          show: toggleSplit,
          title: 'Split/Combine the Graphs',
          icon: combined
            ? 'path://M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9'
            : 'path://M8.25 5.25L12 9 15.75 5.25m0 13.5L12 15 8.25 18.75',
          onclick: toggleCombinedGraph,
        },
        dataView: {
          readOnly: false,
          buttonColor: colors.blue.DEFAULT,
          buttonTextColor: '#FFFFFF',
        },
        saveAsImage: {
          name: 'BioNsight',
          pixelRatio: 2,
          onclick: () => {
            logger.trackEvent(TrackedEvent.ExportGraph);
          },
        },
      },
    };
  };

  const getTooltip = () => ({
    trigger: 'axis',
    show: showTooltip,
    order: 'seriesDesc',
    formatter: tooltipFormatter,
    ...tooltipStyles,
  });

  function getResetZoomButton(top: number, left: number) {
    return {
      type: 'image',
      style: {
        image: '/assets/icons/resetZoom.png',
        x: left,
        y: top,
        width: 20,
        height: 20,
      },
      onclick() {
        resetZoom();
      },
    };
  }

  function getLegend() {
    return {
      show: showLegend && combined,
      top: showToolbox ? LEGEND_TOP : 0,
      data: getLegendData(seriesLegend),
      selected: legendSelected,
    };
  }

  function getGraphic() {
    if (!showZoom) {
      return null;
    }
    const chartHeightFirst = settings.chartHeight - settings.dataZoom.height + RESET_BUTTON_MARGIN;
    const left =
      (combined ? settings.yAxisOffset * seriesTypes.length : settings.dataZoom.left) -
      RESET_BUTTON_LEFT_MARGIN;
    return [
      ...(combined
        ? [getResetZoomButton(settings.legend.height + chartHeightFirst, left)]
        : seriesTypes.flatMap((_, index) =>
            getResetZoomButton(chartHeightFirst + index * settings.chartHeight, left),
          )),
    ];
  }
  const onLegendSelectedChanged = ({ selected }: LegendSelectedChanged) => {
    if (toggleLegendSelected) {
      toggleLegendSelected(selected);
    }
  };

  const onDataZoomChanged = ({ start, end, batch, dataZoomId }: DataZoom) => {
    if (start !== undefined && end !== undefined && dataZoomId !== undefined) {
      // vertical zoom
      if (dataZoomId[0] === 'y') {
        if (combined) {
          // in a combined graph, all y-Zoom scales have to be set to the same %-values, otherwise they will jump out of sync on redraw.
          zoomY.current = Object.fromEntries(
            Object.keys(zoomY.current).map((key) => [key, { start, end }]),
          );
        } else {
          zoomY.current = { ...zoomY.current, [dataZoomId.slice(1)]: { start, end } };
        }
        return;
      }

      // horizontal zoom
      zoomX.current = { start, end };
      return;
    }

    if (batch) {
      const last = batch[batch.length - 1];
      zoomX.current = {
        start: last.start ?? zoomX.current.start,
        end: last.end ?? zoomX.current.end,
      };
    }
  };

  const chartHeight = combined ? settings.height : settings.chartHeight * seriesTypes.length;

  const handleYAxisRangeChange = (newRange: YAxisRange, seriesType: SeriesName) => {
    setYAxisRanges((prev) => {
      const newMap = new Map(prev);
      newMap.set(seriesType, newRange);
      return newMap;
    });
  };

  const toolbox =
    showSideToolbox &&
    seriesTypes.map((seriesType) => (
      <div className="pb-4 pl-4 pt-4" key={seriesType}>
        <small className="mb-2">{seriesType}</small>
        <YAxisRangeInputs
          className={cn('flex flex-wrap', {
            'flex-row gap-4': combined,
            'max-md:flex-row max-md:gap-4 md:h-[280px] md:flex-col md:justify-between': !combined,
          })}
          range={yAxisRanges.get(seriesType) ?? {}}
          onRangeChange={(e) => handleYAxisRangeChange(e, seriesType)}
        />
      </div>
    ));

  return (
    <>
      <div className="flex grid-cols-2 flex-col gap-4 px-4">
        {chartHeight > maxAllowedCanvasSize.height && (
          <NotificationMessage status="warning">
            <FormattedMessage
              description="Maximum Number Of Data Tracks Selected Message"
              defaultMessage="The chart has reached its maximum height, please adjust the number of selected Data Tracks."
              id="eTRA0M"
            />

            {isFirefox() && (
              <FormattedMessage
                description="Data Tracks Hidden"
                defaultMessage=" Some Data Tracks could not be visualized."
                id="ZG1FLm"
              />
            )}
          </NotificationMessage>
        )}

        {showToggleSplit && (
          <TimeSeriesChartInfoBox seriesLength={seriesTypes.length} combined={combined} />
        )}
      </div>
      <div
        className={cn({
          'flex flex-col md:grid md:grid-cols-[6rem,1fr]': combined === false && showSideToolbox,
        })}
        style={{
          gridTemplateRows: `repeat(${seriesTypes.length},1fr)`,
        }}
      >
        {combined ? <div className="flex flex-row flex-wrap">{toolbox} </div> : toolbox}

        <EChartsReactCore
          ref={echartsCore}
          echarts={echartsConfig}
          className="col-start-2 col-end-2 row-start-1 row-end-[-1]"
          style={{
            height: `${chartHeight}px`,
            // limit the canvas height on firefox so as to not risk crashing the browser
            ...(isFirefox() && { maxHeight: `${maxAllowedCanvasSize.height}px` }),
          }}
          onEvents={{
            datazoom: onDataZoomChanged,
            legendselectchanged: onLegendSelectedChanged,
          }}
          option={{
            dataset,
            title: seriesTypes?.map((type, index) => ({
              text: type,
              show: !combined,
              top: `${settings.chartHeight * index}px`,
              left: 'center',
              textStyle: {
                lineHeight: CHART_TITLE_HEIGHT,
              },
            })),
            grid: getGrid(),
            xAxis: xAxisSettings(),
            yAxis: yAxisSettings(),
            series: getSeries(),
            tooltip: getTooltip(),
            graphic: getGraphic(),
            legend: getLegend(),
            animation: false,
            dataZoom: getDataZoom(),
            toolbox: getToolbox(),
            axisPointer: {
              link: [
                {
                  xAxisIndex: 'all',
                },
              ],
            },
          }}
          notMerge
          lazyUpdate
          theme="horizon-web"
        />
      </div>
    </>
  );
}

export default TimeSeriesChart;
