import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import { ProcessRecordDescriptor } from '../../types/process-record';
import QKey from '../keys';

import { fetchProcessRecordList } from './use-process-record-list.helpers';

function useProcessRecordList(): UseQueryResult<ProcessRecordDescriptor[], Error> {
  const { acquireAccessToken } = useAuthentication();

  return useQuery({
    queryKey: [QKey.PROCESS_RECORDS],
    queryFn: async () => {
      const accessToken = await acquireAccessToken();
      return fetchProcessRecordList(accessToken);
    },
  });
}

export default useProcessRecordList;
