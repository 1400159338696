import { createCheckers } from 'ts-interface-checker';

import { ProcessRecordDescriptorDTO, ProcessRecordObjectDTO } from '../process-record-dto';

import { ProcessRecordEvent } from '../../process-record-event';

import { isValidIsoTimestamp, isDateString } from '../../../timestamp/dto/validators';

import ProcessRecordTI from './process-record-dto-validator-types';

function timestampsAreValid(object: ProcessRecordDescriptorDTO): boolean {
  return [
    isValidIsoTimestamp(object.startTimestamp),
    isValidIsoTimestamp(object.stopTimestamp),
    isValidIsoTimestamp(object.uploadTimestamp),
    object.inoculationTimestamp ? isDateString(object.inoculationTimestamp) : true,
  ].every(Boolean);
}

export function isProcessRecordDescriptorDTO(
  object: unknown,
): asserts object is ProcessRecordDescriptorDTO {
  const { ProcessRecordDescriptor } = createCheckers(ProcessRecordTI);

  // throws an error when object is not in the correct format
  ProcessRecordDescriptor.check(object);
  if (!timestampsAreValid(object as ProcessRecordDescriptorDTO)) {
    throw new Error();
  }
}

export function isProcessRecordObjectDTO(
  object: unknown,
): asserts object is ProcessRecordObjectDTO {
  const { ProcessRecordObject } = createCheckers(ProcessRecordTI);

  // throws an error when object is not in the correct format
  ProcessRecordObject.check(object);
  if (!timestampsAreValid(object as ProcessRecordObjectDTO)) {
    throw new Error();
  }
}

export function isProcessRecordEvent(object: unknown): asserts object is ProcessRecordEvent {
  const { ProcessRecordEvent: checker } = createCheckers(ProcessRecordTI);

  // throws an error when object is not in the correct format
  checker.check(object);
}
