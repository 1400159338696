import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Tile, PageLayout, ChevronLeftIcon, Tabs, PlusIcon } from '@biss/react-horizon-web';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'usehooks-ts';

import ProcessRecordHeaderList from '../../../components/process-record-header-list';
import { colorizeProcessRecords } from '../../process-record-comparison/process-record-comparison.helpers';
import ProcessRecordEventList from '../../../components/process-record-event-list/process-record-event-list';
import ProcessRecordAlignmentOptions from '../../../components/process-record-alignment-options';
import useFeatureFlag from '../../../../shared/common/hooks/use-feature-flag';
import FKey from '../../../../shared/common/feature-keys';
import RecipeOptimizationTransfer from '../../../components/recipe-optimization-transfer';
import DataTrackList from '../../../../shared/components/data-track-list';
import { DataTrackItem } from '../../../../shared/components/data-track-list/data-track-list.definitions';
import Footer from '../../../../shared/components/footer';
import useLogger from '../../../../shared/common/hooks/use-logger/use-logger';
import TrackedEvent from '../../../../shared/common/tracked-event';
import RouteDefinition from '../../../../shared/common/routes/routes.definitions';
import useDeleteCustomDataTrack from '../../../../shared/common/hooks/use-delete-custom-data-track';
import useProcessRecordOptimizationVariables from '../../../common/hooks/use-process-record-optimization-variables';
import { HttpStatus } from '../../../../shared/common/types/http';
import AddDataTrackPopOver from '../../../components/add-data-track-pop-over/add-data-track-pop-over';
import DataTrackUploadModal from '../../../components/data-track-upload-modal';

import {
  useDataTrackColorization,
  useDataTrackSelectionByType,
} from './process-record-visualization.helpers';
import {
  DEFAULT_DATATRACK_TYPES,
  DataTrackTimeAlignment,
  MarkedDataTrack,
  ProcessRecordVisualizationProps,
  SHORTCUTS,
  TabKey,
} from './process-record-visualization.definitions';
import ProcessRecordVisualizationChart from './process-record-visualization-chart';

/**
 * Visualization Component for a single process record
 *
 * includes the selectable data track list and chart
 */
function ProcessRecordVisualization({
  processRecords,
  totalProcessRecordCount = 1,
  pageTitle = 'Process Record',
  defaultSidebarOpen,
}: Readonly<ProcessRecordVisualizationProps>) {
  const inSingleView = totalProcessRecordCount === 1;

  if (processRecords.length <= 0) {
    throw new Error('No Process Records were provided');
  }

  const logger = useLogger();
  const isMobile = useMediaQuery('(max-width: 768px)');

  const [isOpen, setIsOpen] = useState(defaultSidebarOpen ?? !isMobile);

  const [tab, setTab] = useState<TabKey>('data-track-selection');

  const isDataTrackCalculationsEnabled = useFeatureFlag(FKey.ANALYTICS_DATA_TRACK_CALCULATIONS);

  const dataHowTransferEnabled = useFeatureFlag(FKey.ANALYTICS_DATAHOW_LAB_INTEGRATION);
  /** can the data how integration area be shown */
  const isDataHowLabAccessible = dataHowTransferEnabled && inSingleView;

  const { mutate: deleteDataTrack } =
    // the delete function is only possible on detail view where there is only one process record available
    useDeleteCustomDataTrack(processRecords[0].processRecordId);

  /** key used to cache computations dependant on the {@link processRecords} prop */
  const processRecordsCacheKey = processRecords
    .map(
      (pr) =>
        `${pr.processRecordId}${pr.dataTracks.map((dt) => dt.dataTrackId).join()}${Object.entries(
          pr.attributes,
        )
          .flat()
          .join()}`,
    )
    .join();

  const {
    data: dhlMappings,
    error: dhlMappingsError,
    isError: isDhlMappingError,
    isLoading: isDhlMappingLoading,
  } =
    // data how integration is only visible on detail view where there is only one process record available
    useProcessRecordOptimizationVariables(
      processRecords[0].processRecordId,
      isDataHowLabAccessible,
    );

  const isUserMissingDatahowSubscription = Boolean(
    dhlMappingsError &&
      'status' in dhlMappingsError &&
      dhlMappingsError.status === HttpStatus.PaymentRequired,
  );

  const showDataHowLab = isDataHowLabAccessible && !isUserMissingDatahowSubscription;

  const [timeAlignment, setTimeAlignment] = useState(
    () => DataTrackTimeAlignment.RelativeToStartTime,
  );

  // assign a color to each process record
  const coloredprocessRecords = useMemo(
    () => colorizeProcessRecords(processRecords),
    [processRecordsCacheKey],
  );

  // get all the available dataTrackTypes
  const allTypes = useMemo(
    () =>
      Array.from(
        new Set(coloredprocessRecords.flatMap((pr) => pr.dataTracks).map((dt) => dt.dataTrackType)),
      ),
    [processRecordsCacheKey],
  );

  // default datatracks that are available in this process record
  const availableDefaultDataTrackTypes = DEFAULT_DATATRACK_TYPES.filter((type) =>
    allTypes.includes(type),
  );

  const { selectedDataTrackTypes, toggleDataTrackType, setDataTrackTypeSelection } =
    useDataTrackSelectionByType(availableDefaultDataTrackTypes);

  // update data track selection when additional process records have been loaded
  //  in comparison not all process records might have the default data tracks,
  //  if the first one that is loaded does not contain them, they will be loaded once
  //  a process record containing them was loaded
  useEffect(() => {
    if (processRecords.length === totalProcessRecordCount) {
      setDataTrackTypeSelection(availableDefaultDataTrackTypes);
    }
  }, [processRecords.length === totalProcessRecordCount]);

  const { getColor } = useDataTrackColorization(availableDefaultDataTrackTypes);

  // give every datatrack of every process record a reference to the process record and a color
  // the color will be inherited from the process record in comparison view and chosen from the type color map in single view
  const markedDataTracks = useMemo(
    () =>
      coloredprocessRecords.map((pr) =>
        pr.dataTracks
          .filter((dt) => selectedDataTrackTypes.includes(dt.dataTrackType))
          .map((dt) => {
            const dataTrackColor = getColor(dt.dataTrackType);
            const processRecordColor = pr.color;

            return {
              ...dt,
              processRecord: pr,
              color: inSingleView ? dataTrackColor : processRecordColor,
            } satisfies MarkedDataTrack;
          }),
      ),
    [processRecordsCacheKey, selectedDataTrackTypes],
  );

  // assemble the datatrack list display data (displayName, engineeringUnit, dots)
  const datatrackItems: DataTrackItem[] = useMemo(
    () =>
      allTypes.map((dataTrackType) => {
        const datatracksOfType = processRecords
          .map((pr) => pr.dataTracks.map((dt) => ({ ...dt, processRecord: pr })))
          .flat()
          .filter((dt) => dt.dataTrackType === dataTrackType);

        const datatracksOfTypeProcessRecordIds = new Set(
          datatracksOfType.map((dt) => dt.processRecord.processRecordId),
        );

        const dots = coloredprocessRecords
          .filter((pr) => datatracksOfTypeProcessRecordIds.has(pr.processRecordId))
          .map((pr) => pr.color);

        const firstDatatrackOfType = datatracksOfType.at(0);
        if (firstDatatrackOfType === undefined) {
          throw new Error(`Could not find a single datatrack of type ${dataTrackType}`);
        }
        return {
          // take the data out of the first datatrack of type {@link dataTrackType}
          dataTrackType: firstDatatrackOfType.dataTrackType,
          displayName: firstDatatrackOfType.displayName,
          engineeringUnit: firstDatatrackOfType.engineeringUnit,
          fractionalDigits: firstDatatrackOfType.fractionalDigits,
          isCustom: firstDatatrackOfType.isCustom,
          isSaved: true,
          dots: dots.length === coloredprocessRecords.length ? [] : dots, // hide dots if datatrack is available for all process records
        } satisfies DataTrackItem;
      }),
    [processRecordsCacheKey],
  );

  const hasInoculationTime = useMemo(
    () =>
      processRecords
        .map((record) => record.inoculationTimestamp)
        .every((timeStamp) => timeStamp !== undefined),
    [processRecordsCacheKey],
  );

  const handleReset = () => {
    setDataTrackTypeSelection(availableDefaultDataTrackTypes);
    logger.trackEvent(TrackedEvent.DeselectAllOrReset);
  };

  const handleDeselectAll = () => {
    setDataTrackTypeSelection([]);
    logger.trackEvent(TrackedEvent.DeselectAllOrReset);
  };

  const handleSelect = (type: string) => {
    toggleDataTrackType(type);
  };

  // delete a datatrack from a process record
  // only possible in detail view
  const handleDelete = ({ dataTrackType }: DataTrackItem) => {
    // the single process record in the single view
    const onlyProcessRecord = processRecords[0];

    // find the data track that has the same type as the one that is supposed to be deleted
    const dataTrack = onlyProcessRecord.dataTracks.find((dt) => dt.dataTrackType === dataTrackType);

    if (!dataTrack) {
      throw new Error(
        `Data track with type "${dataTrackType}" could not be found within this process record.`,
      );
    }

    // type of the data track that is going to be deleted
    const deletableDataTrackType = dataTrack.dataTrackType;

    // was the data track selected before being deleted
    const isDeletableDataTrackSelected = selectedDataTrackTypes.includes(dataTrack.dataTrackType);

    if (isDeletableDataTrackSelected) {
      // deselect the data track before deleting it
      setDataTrackTypeSelection(selectedDataTrackTypes.filter((i) => i !== deletableDataTrackType));
    }

    deleteDataTrack(dataTrack.dataTrackId, {
      // if the data track was selected before being deleted, select it again after an error
      onError: () =>
        isDeletableDataTrackSelected &&
        setDataTrackTypeSelection([...selectedDataTrackTypes, deletableDataTrackType]),
    });
  };

  const dataTrackList = (
    <DataTrackList
      items={datatrackItems}
      selectedItems={selectedDataTrackTypes}
      onDeselectAll={handleDeselectAll}
      onReset={handleReset}
      onSelect={handleSelect}
      showTitle={false}
      canDeleteCustomDataTracks={inSingleView}
      onDelete={handleDelete}
      actions={
        inSingleView && (
          <div className="col-span-2 [&>button]:w-full">
            {isDataTrackCalculationsEnabled ? (
              <AddDataTrackPopOver processRecordId={processRecords[0].processRecordId} />
            ) : (
              <DataTrackUploadModal
                processRecordId={processRecords[0].processRecordId}
                trigger={
                  <Button
                    kind="secondary"
                    mood="neutral"
                    leftIcon={<PlusIcon />}
                    data-testid="add-data-track-button"
                  />
                }
              />
            )}
          </div>
        )
      }
    />
  );

  return (
    <PageLayout
      sidebarOpen={isOpen}
      data-testid="ProcessRecordVisualizationLayout"
      className="!h-[calc(100dvh-var(--header-bar-height,0))]"
    >
      <PageLayout.Main>
        <PageLayout.Main.ActionBar
          openText={showDataHowLab ? 'Open Panel' : 'Select Data Tracks'}
          closeText={showDataHowLab ? 'Close Panel' : 'Select Data Tracks'}
          pageTitle={pageTitle}
          sidePanelOpen={isOpen}
          onSidePanelOpenChange={setIsOpen}
          backButton={
            <Link
              to={`${RouteDefinition.Analytics}/process-records`}
              onClick={() => {
                logger.trackEvent(TrackedEvent.GoToProcessRecords);
              }}
            >
              <Button mood="neutral" leftIcon={<ChevronLeftIcon />}>
                <FormattedMessage
                  description="Process record detail view: link to the process records list."
                  defaultMessage="Go to Process Records"
                  id="xaqnRL"
                />
              </Button>
            </Link>
          }
        />
        <PageLayout.Main.Content className="flex flex-col gap-2 overflow-y-auto px-4">
          <ProcessRecordHeaderList
            headers={coloredprocessRecords}
            totalNumberOfHeaders={totalProcessRecordCount}
          />

          <Tile>
            {Object.keys(selectedDataTrackTypes).length > 0 && (
              <Tile.Header className="flex flex-row flex-wrap gap-4">
                <ProcessRecordAlignmentOptions
                  defaultValue={`${DataTrackTimeAlignment.RelativeToStartTime}`}
                  hasInoculationTime={hasInoculationTime}
                  onChangeAlignment={setTimeAlignment}
                />
              </Tile.Header>
            )}

            <ProcessRecordVisualizationChart
              inSingleView={inSingleView}
              processRecords={processRecords}
              selectedDataTrackTypes={selectedDataTrackTypes}
              timeAlignment={timeAlignment}
              markedDataTracks={markedDataTracks}
              processRecordsCacheKey={processRecordsCacheKey}
            />
            <Tile.Footer>{SHORTCUTS}</Tile.Footer>
          </Tile>

          {processRecords.length === 1 && ( // TODO(BIOCL-1587): event-list in comparison view will be implemented in
            <ProcessRecordEventList processRecordId={processRecords[0].processRecordId} />
          )}

          {/* TODO(BIOCL-2732): Move to a central place when the page layout is done */}
          <Footer />
        </PageLayout.Main.Content>
      </PageLayout.Main>
      <PageLayout.Sidebar
        isOpen={isOpen}
        onOpenChange={setIsOpen}
        openText="Open Panel"
        closeText="Close Panel"
      >
        {showDataHowLab === false && (
          <PageLayout.Sidebar.Title>
            <FormattedMessage
              defaultMessage="Data Track Selection"
              id="noSwjL"
              description="Data Track Selection Sidebar Title"
            />
          </PageLayout.Sidebar.Title>
        )}

        {
          // tabs are only shown when the dhl subscription is active, otherwise "data tracks" would be the only tab
          showDataHowLab ? (
            <>
              <Tabs
                defaultValue="data-track-selection"
                value={tab}
                onValueChange={(t) => setTab(t as TabKey)}
              >
                <Tabs.List>
                  <Tabs.Trigger value="data-track-selection">
                    <FormattedMessage
                      defaultMessage="Data Tracks"
                      id="sslnK6"
                      description="Sidebar Tabs: Data Track"
                    />
                  </Tabs.Trigger>

                  <Tabs.Trigger value="data-how-integration">
                    <FormattedMessage
                      defaultMessage="DataHowLab"
                      id="1dxgpZ"
                      description="Sidebar Tabs: Data How Lab"
                    />
                  </Tabs.Trigger>
                </Tabs.List>
              </Tabs>

              {tab === 'data-track-selection' && dataTrackList}

              {tab === 'data-how-integration' && (
                <RecipeOptimizationTransfer
                  mappings={dhlMappings}
                  isError={isDhlMappingError}
                  isLoading={isDhlMappingLoading}
                  processRecordId={processRecords[0].processRecordId}
                  dataTracks={processRecords[0].dataTracks}
                  attributes={processRecords[0].attributes}
                />
              )}
            </>
          ) : (
            dataTrackList
          )
        }
      </PageLayout.Sidebar>
    </PageLayout>
  );
}

export default ProcessRecordVisualization;
