import { joinPaths } from '../../../../shared/utils';
import { DataTrack, ProcessRecordId } from '../../../../shared/common/types/process-record';
import fetchData from '../../../../shared/common/fetch-data';
import { HttpMethods } from '../../../../shared/common/types/http';
import { projectConfigInstance } from '../../../../shared/globals/project-config/project-config-accessor';

export function createSubmitDatatracksUrl(processRecordId: ProcessRecordId) {
  return joinPaths(
    projectConfigInstance.get('PRS_BACKEND_URL'),
    'process-records',
    processRecordId,
    'data-tracks',
  );
}

export async function submitDatatracks(
  processRecordId: ProcessRecordId,
  datatracks: DataTrack[],
  accessToken: string,
) {
  return fetchData<DataTrack[]>(createSubmitDatatracksUrl(processRecordId), {
    method: HttpMethods.Post,
    headers: {
      Accept: 'application/json',
      'Accept-Encoding': 'gzip, deflate, br',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(datatracks),
  });
}
