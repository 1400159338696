import { Tile } from '@biss/react-horizon-web';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Footer from '../../../shared/components/footer';
import { CodeSnippet } from '../../../shared/components/code-snippet';

function DatatrackCalculationDocumentation() {
  return (
    <>
      <Tile className="m-4">
        <Tile.Header>
          <Tile.Title title="Calculating Data Tracks Using Formulas" />
        </Tile.Header>
        <Tile.Body>
          <article className="prose max-w-none">
            <FormattedMessage
              description="Data Track Calculations Documentation: Introduction"
              defaultMessage="You can create new data tracks by performing calculations on already available data tracks. As a result the data track gets added to the list of data tracks."
              id="eeCktZ"
            />
            <div>
              <FormattedMessage
                description="Data Track Calculations Documentation: Available operators documentation"
                defaultMessage="The following operators are available for calculations:"
                id="w5CSRB"
              />
              <ul>
                <li>
                  <FormattedMessage
                    description="Data Track Calculations Documentation: Relative functions"
                    defaultMessage="Accessing a data track and its values: {dataTrackAccess}."
                    id="KfYg/o"
                    values={{
                      dataTrackAccess: (
                        <CodeSnippet>
                          <FormattedMessage
                            description="Data Track Calculations Documentation: data track access"
                            defaultMessage="[DataTrackName]"
                            id="DVJbkz"
                          />
                        </CodeSnippet>
                      ),
                    }}
                  />
                </li>
                <li>
                  <FormattedMessage
                    description="Data Track Calculations Documentation: Basic operators"
                    defaultMessage="Fundamental arithmetic operators: {addition}, {subtraction}, {multiplication} and {division}."
                    id="jT7YYZ"
                    values={{
                      addition: (
                        <CodeSnippet>
                          <FormattedMessage
                            description="Data Track Calculations Documentation: +"
                            defaultMessage="+"
                            id="qLj3XE"
                          />
                        </CodeSnippet>
                      ),
                      subtraction: (
                        <CodeSnippet>
                          <FormattedMessage
                            description="Data Track Calculations Documentation: -"
                            defaultMessage="-"
                            id="U8Ow1h"
                          />
                        </CodeSnippet>
                      ),
                      multiplication: (
                        <CodeSnippet>
                          <FormattedMessage
                            description="Data Track Calculations Documentation: *"
                            defaultMessage="*"
                            id="YlB91A"
                          />
                        </CodeSnippet>
                      ),
                      division: (
                        <CodeSnippet>
                          <FormattedMessage
                            description="Data Track Calculations Documentation: /"
                            defaultMessage="/"
                            id="IFYQEW"
                          />
                        </CodeSnippet>
                      ),
                    }}
                  />
                </li>
                <li>
                  <FormattedMessage
                    description="Data Track Calculations Documentation: Relative functions"
                    defaultMessage="Brackets: {openBracket} and {closeBracket}."
                    id="20yhxK"
                    values={{
                      openBracket: (
                        <CodeSnippet>
                          <FormattedMessage
                            description="Data Track Calculations Documentation: ("
                            defaultMessage="("
                            id="CUSX5V"
                          />
                        </CodeSnippet>
                      ),
                      closeBracket: (
                        <CodeSnippet>
                          <FormattedMessage
                            description="Data Track Calculations Documentation: )"
                            defaultMessage=")"
                            id="xVR1qZ"
                          />
                        </CodeSnippet>
                      ),
                    }}
                  />
                </li>
                <li>
                  <FormattedMessage
                    description="Data Track Calculations Documentation: Exponential operator"
                    defaultMessage="Exponential operator and functions: {exponentiation}, {Exp}, {Ln} and {Log10}."
                    id="lyhVig"
                    values={{
                      exponentiation: (
                        <CodeSnippet>
                          <FormattedMessage
                            description="Data Track Calculations Documentation: **"
                            defaultMessage="**"
                            id="qWpR+O"
                          />
                        </CodeSnippet>
                      ),
                      Exp: (
                        <CodeSnippet>
                          <FormattedMessage
                            description="Data Track Calculations Documentation: Exp"
                            defaultMessage="Exp"
                            id="zygZ/t"
                          />
                        </CodeSnippet>
                      ),
                      Ln: (
                        <CodeSnippet>
                          <FormattedMessage
                            description="Data Track Calculations Documentation: Ln"
                            defaultMessage="Ln"
                            id="ouAVAR"
                          />
                        </CodeSnippet>
                      ),
                      Log10: (
                        <CodeSnippet>
                          <FormattedMessage
                            description="Data Track Calculations Documentation: Log10"
                            defaultMessage="Log10"
                            id="smNAt9"
                          />
                        </CodeSnippet>
                      ),
                    }}
                  />
                </li>
                <li>
                  <FormattedMessage
                    description="Data Track Calculations Documentation: Trigonometric functions"
                    defaultMessage="Trigonometric functions: {Sin}, {Cos}, {Tan}, {Acos}, {Asin} and {Atan}."
                    id="DAzJk4"
                    values={{
                      Sin: (
                        <CodeSnippet>
                          <FormattedMessage
                            description="Data Track Calculations Documentation: Sin"
                            defaultMessage="Sin"
                            id="My3jrk"
                          />
                        </CodeSnippet>
                      ),
                      Cos: (
                        <CodeSnippet>
                          <FormattedMessage
                            description="Data Track Calculations Documentation: Cos"
                            defaultMessage="Cos"
                            id="XcVvr1"
                          />
                        </CodeSnippet>
                      ),
                      Tan: (
                        <CodeSnippet>
                          <FormattedMessage
                            description="Data Track Calculations Documentation: Tan"
                            defaultMessage="Tan"
                            id="c6WmsV"
                          />
                        </CodeSnippet>
                      ),
                      Acos: (
                        <CodeSnippet>
                          <FormattedMessage
                            description="Data Track Calculations Documentation: Acos"
                            defaultMessage="Acos"
                            id="kyQ0VW"
                          />
                        </CodeSnippet>
                      ),
                      Asin: (
                        <CodeSnippet>
                          <FormattedMessage
                            description="Data Track Calculations Documentation: Asin"
                            defaultMessage="Asin"
                            id="LgCW8S"
                          />
                        </CodeSnippet>
                      ),
                      Atan: (
                        <CodeSnippet>
                          <FormattedMessage
                            description="Data Track Calculations Documentation: Atan"
                            defaultMessage="Atan"
                            id="IQzV/k"
                          />
                        </CodeSnippet>
                      ),
                    }}
                  />
                </li>
                <li>
                  <FormattedMessage
                    description="Data Track Calculations Documentation: Absolute functions"
                    defaultMessage="Absolute functions: {Abs}, {Ceiling}, {Floor}, {Max}, {Min}, {Round} and {Truncate}."
                    id="J4bt1o"
                    values={{
                      Abs: (
                        <CodeSnippet>
                          <FormattedMessage
                            description="Data Track Calculations Documentation: Abs"
                            defaultMessage="Abs"
                            id="iCA6X1"
                          />
                        </CodeSnippet>
                      ),
                      Ceiling: (
                        <CodeSnippet>
                          <FormattedMessage
                            description="Data Track Calculations Documentation: Ceiling"
                            defaultMessage="Ceiling"
                            id="gA33dS"
                          />
                        </CodeSnippet>
                      ),
                      Floor: (
                        <CodeSnippet>
                          <FormattedMessage
                            description="Data Track Calculations Documentation: Floor"
                            defaultMessage="Floor"
                            id="OTR1pY"
                          />
                        </CodeSnippet>
                      ),
                      Max: (
                        <CodeSnippet>
                          <FormattedMessage
                            description="Data Track Calculations Documentation: Max"
                            defaultMessage="Max"
                            id="GvGYx9"
                          />
                        </CodeSnippet>
                      ),
                      Min: (
                        <CodeSnippet>
                          <FormattedMessage
                            description="Data Track Calculations Documentation: Min"
                            defaultMessage="Min"
                            id="yjTJL3"
                          />
                        </CodeSnippet>
                      ),
                      Round: (
                        <CodeSnippet>
                          <FormattedMessage
                            description="Data Track Calculations Documentation: Round"
                            defaultMessage="Round"
                            id="QiufK5"
                          />
                        </CodeSnippet>
                      ),
                      Truncate: (
                        <CodeSnippet>
                          <FormattedMessage
                            description="Data Track Calculations Documentation: Truncate"
                            defaultMessage="Truncate"
                            id="eXdYq+"
                          />
                        </CodeSnippet>
                      ),
                    }}
                  />
                </li>
                <li>
                  <FormattedMessage
                    description="Data Track Calculations Documentation: Relative functions"
                    defaultMessage="Relative function: {Lag}."
                    id="xJ6RL2"
                    values={{
                      Lag: (
                        <CodeSnippet>
                          <FormattedMessage
                            description="Data Track Calculations Documentation: Lag"
                            defaultMessage="Lag"
                            id="EbuGRX"
                          />
                        </CodeSnippet>
                      ),
                    }}
                  />
                </li>
              </ul>
            </div>
            <FormattedMessage
              description="Data Track Calculations Documentation: Example introduction"
              defaultMessage="In the following an example formula is shown that transforms a temperature data track measured in Fahrenheit (°F) to Celsius (°C). The data track {exampleDataTrack} holding temperature data measured in °F can be transformed to °C using the following formula: {formula}. The resulting data track itself could be used for further calculations."
              id="JzCEHU"
              values={{
                exampleDataTrack: (
                  <CodeSnippet>
                    <FormattedMessage
                      description="Data Track Calculations Documentation: Example Data Track"
                      defaultMessage="T.PV"
                      id="xlr/H4"
                    />
                  </CodeSnippet>
                ),
                formula: (
                  <CodeSnippet>
                    <FormattedMessage
                      description="Data Track Calculations Documentation: Example Formula"
                      defaultMessage="([T.PV] * (9/5)) + 32"
                      id="osN39k"
                    />
                  </CodeSnippet>
                ),
              }}
            />
          </article>
        </Tile.Body>
      </Tile>
      {/* TODO(BIOCL-2732): Move to a central place when the page layout is done */}
      <Footer />
    </>
  );
}

export default DatatrackCalculationDocumentation;
