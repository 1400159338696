import React, { ChangeEvent, forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import classNames from 'classnames';

import { DateTimeInputProps, DateTimeInputRef } from './date-time-input.definitions';
import useDateTimeLocalFormatter from './date-time-input.helpers';

/**
 * this is an uncontrolled input.
 * the value of this input is not controlled by react but rather the DOM itself.
 * unmounting and remounting this component will result in the value the input to be lost.
 * rerenders will not cause an issue as long as the key of the input remains the same.
 */
const DateTimeInput = forwardRef<DateTimeInputRef, DateTimeInputProps>(
  (
    {
      label,
      id,
      onChange,
      initialDate,
      onDateChange,
      withSeconds = false,
      withBorder = true,
      error,
      ...props
    },
    ref,
  ) => {
    const formatDate = useDateTimeLocalFormatter(withSeconds);
    const initialValue = initialDate ? formatDate(initialDate) : '';

    // called when the input string is a valid date
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      onChange?.(event);
      onDateChange?.(new Date(event.currentTarget.value));
    };

    // used in tandem with the following effect to set the default value of the input
    // setting the default value through the props does not work on firefox, as
    // the value is attempted to be set on every rerender
    const internalRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
      if (internalRef.current) {
        internalRef.current.defaultValue = initialValue;
      }
    }, []);

    // combine the internal ref with the forwarded ref
    useImperativeHandle<DateTimeInputRef, DateTimeInputRef>(ref, () => internalRef.current, [
      internalRef.current,
    ]);

    return (
      <div>
        <label
          className={classNames('flex w-full flex-col text-xs font-light text-gray-900')}
          htmlFor={id}
        >
          {label}
          <input
            key={id}
            id={id}
            name={id}
            type="datetime-local"
            className={classNames(
              'min-h-[32px] w-full rounded  px-1.5 font-normal text-gray-900 focus:border-blue-500 focus:outline-none lg:text-sm',
              {
                'border-warning focus:border-warning': error,
                'border-gray-300 focus:border-accent': !error,
              },
              { border: withBorder },
            )}
            ref={internalRef}
            onChange={handleChange}
            // step size 1 results in allowing to select seconds in the time picker
            step={withSeconds ? '1' : '60'}
            // chrome allows for six digit years, this is set to only allow four digits for the year
            max="9999-12-31T00:00"
            // eslint-disable-next-line react/jsx-props-no-spreading -- allow prop spreading for generic components
            {...props}
          />
        </label>

        {error && (
          <div className="mt-1 block max-w-fit text-xs font-bold text-warning">{error}</div>
        )}
      </div>
    );
  },
);

export default DateTimeInput;
