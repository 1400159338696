import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import { ProcessRecordCreationDto } from '../../../components/add-process-record-modal/create-process-record-via-form/create-process-record-via-form.definitions';
import QKey from '../../../../shared/common/hooks/keys';
import { ProcessRecordDescriptor } from '../../../../shared/common/types/process-record';

import { createProcessRecord } from './use-create-process-record.helper';

export default function useCreateProcessRecord() {
  const queryClient = useQueryClient();

  const { acquireAccessToken } = useAuthentication();

  return useMutation({
    mutationFn: async (data: ProcessRecordCreationDto) => {
      const accessToken = await acquireAccessToken();

      return createProcessRecord(data, accessToken);
    },
    onSuccess(data) {
      const previousData = queryClient.getQueryData<ProcessRecordDescriptor[]>([
        QKey.PROCESS_RECORDS,
      ]);

      if (previousData === undefined) {
        return;
      }

      queryClient.setQueryData([QKey.PROCESS_RECORDS], () => [data, ...previousData]);
    },
  });
}
