import { useSearchParams } from 'react-router-dom';

import { ProcessRecordObject } from '../../../shared/common/types/process-record';
import {
  DataTrackTypes,
  MarkedDataTrack,
} from '../process-record-detail/process-record-visualization/process-record-visualization.definitions';

import {
  ColoredProcessRecord,
  PROCESS_RECORD_COLORS,
} from './process-record-comparison.definitions';

export default function useProcessRecordIdsFromSearchQuery(): string[] {
  const [searchParams] = useSearchParams();
  return searchParams.getAll('pr');
}

/**
 * @param records uncolored process records
 * @returns colored process records
 */
export function colorizeProcessRecords(records: ProcessRecordObject[]): ColoredProcessRecord[] {
  return records.map((record, index) => ({
    ...record,
    color: PROCESS_RECORD_COLORS[index % PROCESS_RECORD_COLORS.length],
  }));
}

/**
 * assign the color of the process records to their datatracks
 * @param processRecords
 * @returns
 */
export function markProcessRecordDatatracks(
  processRecords: ColoredProcessRecord[],
): DataTrackTypes {
  const colorizedDataTracks: MarkedDataTrack[] = processRecords.flatMap((processRecord) =>
    processRecord.dataTracks.map((dataTrack) => ({
      ...dataTrack,
      color: processRecord.color,
      processRecord,
    })),
  );

  return colorizedDataTracks.reduce<DataTrackTypes>((obj, dataTrack) => {
    if (obj[dataTrack.dataTrackType]) {
      return {
        ...obj,
        [dataTrack.dataTrackType]: [...obj[dataTrack.dataTrackType], dataTrack],
      };
    }

    return {
      ...obj,
      [dataTrack.dataTrackType]: [dataTrack],
    };
  }, {});
}
