import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import QKey from '../../../../shared/common/hooks/keys';
import { RETRY_DELAY, Setup } from '../../types/setup';
import { NO_RETRY_STATUS_CODES, RetryFunction } from '../../../../shared/common/query-client';

import { fetchSetup } from './use-setup.helpers';

const retryStrategy: RetryFunction = (_, error) =>
  // if the error is a http error and is not supposed to be retried
  !('status' in error && NO_RETRY_STATUS_CODES.includes(Number(error.status)));

function useSetup(id: string): UseQueryResult<Setup, Error> {
  const { acquireAccessToken } = useAuthentication();

  return useQuery({
    queryKey: [QKey.SETUPS, id],
    retry: retryStrategy,
    retryDelay: RETRY_DELAY,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const accessToken = await acquireAccessToken();
      return fetchSetup(id, accessToken);
    },
  });
}

export default useSetup;
