import React, { useState } from 'react';
import { Button, PlusIcon, Popover } from '@biss/react-horizon-web';
import { FormattedMessage } from 'react-intl';

import DataTrackUploadModal from '../data-track-upload-modal';
import DataTrackFormulaModal from '../data-track-formula-modal';

import {
  AddDataTrackPopOverOption,
  AddDataTrackPopOverProps,
} from './add-data-track-pop-over.definitions';

function AddDataTrackPopOver({
  processRecordId,
  defaultOption = undefined,
  defaultOpen = false,
}: AddDataTrackPopOverProps) {
  const [modalToOpen, setModalToOpen] = useState<AddDataTrackPopOverOption | undefined>(
    defaultOption,
  );

  const handleItemClick = (val: AddDataTrackPopOverOption) =>
    // the micro-delay is done to mitigate a bug
    // where radix components are focused in and out recursively hanging up the UI thread
    Promise.resolve().then(() => setModalToOpen(val));

  const handleModalOpenChange = (isOpen: boolean) => isOpen === false && setModalToOpen(undefined);

  return (
    <>
      <Popover
        defaultOpen={defaultOpen}
        side="bottom"
        trigger={
          <Button kind="secondary" leftIcon={<PlusIcon />} data-testid="add-data-track-button" />
        }
      >
        <Popover.Item
          data-testid="add-data-track-from-file-item"
          textValue="file"
          onClick={() => handleItemClick('file')}
        >
          <FormattedMessage
            defaultMessage="From File"
            description="Add new Data Track Option: From File"
            id="0Jyp8q"
          />
        </Popover.Item>

        <Popover.Item
          data-testid="add-data-track-from-formula-item"
          onClick={() => handleItemClick('formula')}
        >
          <FormattedMessage
            defaultMessage="From Formula"
            description="Add new Data Track Option: From Formula"
            id="tlFdX9"
          />
        </Popover.Item>
      </Popover>

      {modalToOpen === 'file' && (
        <DataTrackUploadModal
          processRecordId={processRecordId}
          onOpenChange={handleModalOpenChange}
          defaultOpen
        />
      )}

      {modalToOpen === 'formula' && (
        <DataTrackFormulaModal
          processRecordId={processRecordId}
          onOpenChange={handleModalOpenChange}
          defaultOpen
        />
      )}
    </>
  );
}

export default AddDataTrackPopOver;
