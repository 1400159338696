import { HORIZON_COLORS } from '@biss/react-horizon-web';

import { DataTrackType } from '../../common/types/process-record';
import { YAxisRange } from '../../../analytics/scenes/process-record-detail/process-record-visualization/y-axis-range-inputs/y-axis-range-inputs.validation';

export interface DataPointObject {
  ts: number;
  v: number;
}

export type SeriesId = string;
export type SeriesName = string;

export interface Series {
  dataTrackId: SeriesId;
  dataTrackType: SeriesName;
  displayName: string;
  engineeringUnit: string;
  fractionalDigits?: number;
  dataPoints: Array<DataPointObject>;
  color: string;
  width?: number;
  lineType?: string;
}

export type SeriesMarkLine = {
  color: string;
  timestamp: number;
  name: string;
};

export type SeriesLegend = {
  engineeringUnit: string;
  color: string;
};

export type SeriesLegendData = {
  name: DataTrackType;
  itemStyle: {
    color: string;
  };
  lineStyle: {
    color: string;
  };
};

export type SeriesLegendSelected = Record<string, boolean>;
export type LegendSelectedChanged = {
  type: string;
  name: string;
  selected: SeriesLegendSelected;
};
export type TooltipStyles = {
  backgroundColor?: string;
  borderColor?: string;
};

export type TimeSeriesChartProps = {
  series: Record<SeriesName, Series[]>;
  startTime: number;
  stopTime: number;
  variant?: ChartVariant;
  combinedGraph?: boolean;
  showTooltip?: boolean;
  xAxisFormatter: (ts: number) => void;
  yAxisFormatter?: (value: number, fractionalDigits?: number) => void;
  seriesMarkLines?: SeriesMarkLine[];
  showToggleSplit?: boolean;
  showLegend?: boolean;
  seriesLegend?: Record<DataTrackType, SeriesLegend>;
  toggleSplitView?: (isCombined: boolean) => void;
  useRelativeYAxis?: boolean;
  showZoom?: boolean;
  showToolbox?: boolean;
  legendSelected?: SeriesLegendSelected;
  toggleLegendSelected?: (value: SeriesLegendSelected) => void;
  tooltipStyles?: TooltipStyles;

  /** whether to render the y-axis range inputs beside the charts */
  showSideToolbox?: boolean;

  /** the viewable and zoom-able range of the yAxis */
  defaultYAxisRanges?: Map<SeriesName, YAxisRange>;
};

export type DataSet = {
  dataTrackId: string;
  dimensions: ['ts', 'v'];
  source: DataPointObject[];
};

export const CHART_VARIANT = ['small', 'large'] as const;
export type ChartVariant = (typeof CHART_VARIANT)[number];

export type ChartSettings = {
  height: number;
  chartHeight: number;
  yAxisOffset: number;
  fontSize?: number;
  lineHeight?: number;
  lineStyle: {
    width: number;
  };
  markLine: {
    fontSize: number;
  };
  dataZoom: {
    left: number;
    height: number;
  };
  toolbox: {
    itemGap: number;
  };
  legend: {
    height: number;
  };
  grid: {
    height?: number;
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
    containLabel?: boolean;
  };
};

export type Param = {
  data: DataPointObject;
  marker: string;
  seriesName: string;
  color: string;
};

export interface DataZoom {
  batch?: DataZoom[];
  type: string;
  from: string;
  dataZoomId: string;
  animation: Animation;
  start?: number;
  end?: number;
}

export interface Animation {
  easing: string;
  duration: number;
  delay: number;
}

export enum ZoomResetDirection {
  Both,
  X,
  Y,
}

export type ZoomRangeType = { start: number; end: number };

export const markLineColor = HORIZON_COLORS.gray['900'];
export const markLineInoculation = 'Inoculation';
export const markLineFinished = 'Process Finished';
export const markLineLastUpdate = 'Last Update';
