import { transformProcessRecordDescriptorDTO } from '../../types/process-record/dto';
import fetchData from '../../fetch-data';
import { isProcessRecordDescriptorDTO } from '../../types/process-record/dto/validators';
import { ProcessRecordDescriptor } from '../../types/process-record';
import { HttpMethods } from '../../types/http';
import { joinPaths } from '../../../utils';
import { projectConfigInstance } from '../../../globals/project-config/project-config-accessor';

export function createProcessRecordListUrl(): string {
  return joinPaths(projectConfigInstance.get('PRS_BACKEND_URL'), 'process-records/');
}

export async function fetchProcessRecordList(
  accessToken: string,
): Promise<ProcessRecordDescriptor[]> {
  const result = await fetchData(createProcessRecordListUrl(), {
    method: HttpMethods.Get,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (!Array.isArray(result)) {
    throw new Error('expected to receive an array');
  }

  return result.map((dto) => {
    isProcessRecordDescriptorDTO(dto);
    return transformProcessRecordDescriptorDTO(dto);
  });
}
