import fetchData from '../../../../shared/common/fetch-data';
import { HttpMethods } from '../../../../shared/common/types/http';
import { DataTrackDataPoints } from '../../types/data-track';
import {
  CONTROL_PROCEDURES,
  ControlProcedureId,
  DATA_TRACKS,
  SETUP,
  SetupId,
} from '../../types/setup';
import { joinPaths } from '../../../../shared/utils';
import { projectConfigInstance } from '../../../../shared/globals/project-config';

export function createAddDataPointsUrl(controlProcedureId: ControlProcedureId, setupId: SetupId) {
  return joinPaths(
    projectConfigInstance.get('PMS_BACKEND_URL'),
    CONTROL_PROCEDURES,
    controlProcedureId,
    SETUP,
    setupId,
    DATA_TRACKS,
  );
}
export async function addDataPoints(
  controlProcedureId: ControlProcedureId,
  setupId: SetupId,
  dataTracks: DataTrackDataPoints[],
  accessToken: string,
): Promise<void> {
  await fetchData(createAddDataPointsUrl(controlProcedureId, setupId), {
    method: HttpMethods.Put,
    headers: {
      Accept: 'application/json',
      'Accept-Encoding': 'gzip, deflate, br',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(dataTracks),
  });
}
