import { Setup, SetupDescriptor } from '..';

import { SetupDTO, SetupDescriptorDTO } from './setup-dto';

export function transformSetupDescriptorDTO(dto: SetupDescriptorDTO): SetupDescriptor {
  return {
    ...dto,
    startTimestamp: new Date(dto.startTimestamp),
    stopTimestamp: dto.stopTimestamp ? new Date(dto.stopTimestamp) : undefined,
    inoculationTimestamp: dto.inoculationTimestamp ? new Date(dto.inoculationTimestamp) : undefined,
    // TODO BIOCL-3935 remove connectionState when state is returned
    connectionState:
      !dto.connectionState || dto.connectionState === 'Unknown' ? 'Connected' : dto.connectionState,
  };
}

export function transformSetupDTO(dto: SetupDTO): Setup {
  const descriptor = transformSetupDescriptorDTO(dto);

  return {
    ...dto,
    ...descriptor,
    // disambiguation
    stopTimestamp: descriptor.stopTimestamp,
    inoculationTimestamp: descriptor.inoculationTimestamp,
  };
}
