import { DataTrack } from './data-track';

export type ProcessRecordId = string;

export interface ProcessRecordDescriptor {
  processRecordId: ProcessRecordId;
  displayName: string;
  title: string;
  deviceName: string;
  unit: string;
  uploadTimestamp: Date;
  startTimestamp: Date;
  stopTimestamp: Date;
  operator?: string;
  comment?: string;
  inoculationTimestamp?: Date;
}

export interface ProcessRecordAttributes {
  [key: string]: string;
}

export interface ProcessRecordObject extends ProcessRecordDescriptor {
  attributes: ProcessRecordAttributes;
  dataTracks: DataTrack[];
}

export const FRACTIONAL_DIGITS = 2;
