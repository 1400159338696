import { useMutation } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import { DataTrack } from '../../../../shared/common/types/process-record';
import QKey from '../../../../shared/common/hooks/keys';
import { CalculationRequest } from '../../../../shared/common/types/process-record/dto/calculator';

import { calculateDataTrack } from './use-calculate-data-track.helpers';

function useCalculateDataTrack() {
  const { acquireAccessToken } = useAuthentication();

  return useMutation<DataTrack, Error, CalculationRequest, unknown>({
    mutationKey: [QKey.CALCULATED_DATA_TRACK],
    async mutationFn(data) {
      const token = await acquireAccessToken();
      return calculateDataTrack(data, token);
    },
  });
}

export default useCalculateDataTrack;
