import React, { FunctionComponentElement } from 'react';
import cn from 'classnames';

import { CellContext } from '@tanstack/react-table';

import { FormattedDate, FormattedTime } from 'react-intl';

import DateTimeInput from '../../date-time-input/date-time-input';
import { CELL_ERROR_STYLES, EditableTableData } from '../editable-data-table.definitions';

function DateCell({
  getValue,
  row: { index },
  column: {
    id,
    columnDef: { meta },
  },
  table,
}: CellContext<EditableTableData, unknown>): FunctionComponentElement<
  CellContext<EditableTableData, unknown>
> {
  const isDisabled = meta?.disabled?.includes(index);
  const isError = meta?.error?.includes(index);
  const initialValue = getValue();

  const onChange = (date: Date | null) => {
    table.options.meta?.updateData(index, id, date);
  };

  // render formatted value if cell is not editable
  if (isDisabled) {
    return (
      <div
        data-testid="editable-table-date-value"
        className={`flex h-full items-center px-4 py-1
           ${cn({
             [CELL_ERROR_STYLES]: isError,
           })}`}
      >
        {typeof initialValue === 'number' && (
          <span className="break-words break-all">
            <FormattedDate value={initialValue} year="numeric" month="short" day="numeric" />
            <br />
            <span className="text-secondary">
              <FormattedTime
                value={initialValue}
                hour="numeric"
                minute="numeric"
                second="numeric"
              />
            </span>
          </span>
        )}
      </div>
    );
  }
  return (
    <div
      data-testid="editable-table-date-field-wrapper"
      className={`flex h-full items-center 
        ${cn({
          [CELL_ERROR_STYLES]: isError,
        })}`}
    >
      <DateTimeInput
        withSeconds
        id="timestamp"
        onDateChange={(date) => date && onChange(date)}
        initialDate={initialValue as Date}
        data-testid="editable-table-date-field"
        withBorder={false}
      />
    </div>
  );
}

export default DateCell;
