import React, { ChangeEvent, FocusEvent, FunctionComponentElement, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input, Modal } from '@biss/react-horizon-web';
import { useNavigate } from 'react-router-dom';

import { useCreateNewOrganization, useFormValidation } from '../../../common/hooks';
import {
  displayNameValidationPattern,
  organizationNameValidationPattern,
} from '../../../common/utils/validation-patterns';
import RouteDefinition from '../../../../shared/common/routes/routes.definitions';
import LoadingIndicator from '../../../../shared/components/loading-indicator';

import { NewOrganizationFormState } from './new-organization.definitions';

function NewOrganization(): FunctionComponentElement<unknown> {
  const { mutate, data: organization, isError, isPending } = useCreateNewOrganization();
  const [open, setOpen] = React.useState(true);

  const intl = useIntl();
  const navigate = useNavigate();
  const [inputs, setInputs] = useState<NewOrganizationFormState>({
    organizationName: '',
    administratorDisplayName: '',
    administratorEmail: '',
    errors: new Map(),
    validity: new Map([
      ['organizationName', false],
      ['administratorDisplayName', true],
      ['administratorEmail', false],
    ]),
  });
  const { updateErrorsFromFocusEvent, updateErrorsAndValidityFromChangeEvent } =
    useFormValidation();

  const onClose = () => {
    navigate(`${RouteDefinition.UserManagment}/organizations`);
  };
  const setFormState = (state: NewOrganizationFormState): NewOrganizationFormState => ({
    ...state,
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setInputs((values) =>
      setFormState({
        ...values,
        [name]: value,
        ...updateErrorsAndValidityFromChangeEvent(values, event),
      }),
    );
  };

  const handleSubmit = () => {
    mutate(
      {
        organizationName: inputs.organizationName,
        administratorDisplayName: inputs.administratorDisplayName,
        administratorEmail: inputs.administratorEmail,
      },
      {
        onSuccess: () => {
          setOpen(false);
          onClose();
        },
      },
    );
  };

  const areFormInputsInvalid = (): boolean | undefined =>
    Array.from(inputs.validity.values()).some((value) => value === false);

  const updateErrors = (
    state: NewOrganizationFormState,
    event: FocusEvent<HTMLInputElement>,
  ): NewOrganizationFormState => ({
    ...state,
    errors: updateErrorsFromFocusEvent(state, event),
  });

  const validateFormat = (e: FocusEvent<HTMLInputElement>) => {
    setInputs((values) => updateErrors(values, e));
  };

  return (
    <Modal
      open={open}
      defaultOpen
      onOpenChange={(isOpen: boolean) => {
        if (!isOpen) {
          onClose();
        }
      }}
      title={intl.formatMessage({
        defaultMessage: 'Register New Organization',
        id: 'zjTYm5',
        description: 'Register New Organization title.',
      })}
      size="xs"
    >
      <Modal.Content>
        {isError && (
          <div className="mb-4 rounded border border-red-400 bg-red-25 p-4 text-red-400">
            <FormattedMessage
              description="Organization registration view: error message when error occurred during loading."
              defaultMessage="An error occurred while loading the organization registration."
              id="6vEG1r"
            />
          </div>
        )}

        {isPending && (
          <span className="p-4">
            <LoadingIndicator />
          </span>
        )}
        <form className="w-full" onSubmit={handleSubmit}>
          <Input
            id="organizationNameInput"
            className="mb-4 w-full"
            name="organizationName"
            label={intl.formatMessage({
              defaultMessage: 'Organization name',
              id: 'ry6/ud',
              description: 'Label for organizationName input.',
            })}
            placeholder={intl.formatMessage({
              defaultMessage: 'Organization name',
              id: 'lVGMsg',
              description: 'Placeholder for organizationName input.',
            })}
            type="text"
            value={inputs.organizationName}
            onChange={handleChange}
            error={inputs.errors.get('organizationName')}
            onBlur={validateFormat}
            pattern={organizationNameValidationPattern.source}
            required
          />
          <Input
            id="organizationAdminNameInput"
            className="mb-4 w-full"
            name="administratorDisplayName"
            label={intl.formatMessage({
              defaultMessage: 'Administrator name',
              id: '/OqErA',
              description: 'Label for organizationAdminNameInput input.',
            })}
            placeholder={intl.formatMessage({
              defaultMessage: 'Administrator name',
              id: 'K66dox',
              description: 'Placeholder for organizationAdminNameInput input.',
            })}
            type="text"
            value={inputs.administratorDisplayName}
            onChange={handleChange}
            pattern={displayNameValidationPattern.source}
            onBlur={validateFormat}
            error={inputs.errors.get('administratorDisplayName')}
          />
          <Input
            id="organizationAdminEmailInput"
            className="mb-4 w-full"
            name="administratorEmail"
            label={intl.formatMessage({
              defaultMessage: 'Administrator email',
              id: 'V189U1',
              description: 'Label for organizationAdminNameEmail input.',
            })}
            placeholder={intl.formatMessage({
              defaultMessage: 'admin@example.com',
              id: 'L2yJmg',
              description: 'Placeholder for organizationAdminNameEmail input.',
            })}
            type="email"
            value={inputs.administratorEmail}
            onChange={handleChange}
            error={inputs.errors.get('administratorEmail')}
            onBlur={validateFormat}
            required
          />
        </form>
      </Modal.Content>
      <Modal.ButtonGroup>
        <Modal.Close asChild role="button">
          <Modal.Button>
            <FormattedMessage
              description="Cancel Button: label for cancel button"
              defaultMessage="Cancel"
              id="f4OIxJ"
            />
          </Modal.Button>
        </Modal.Close>
        <Modal.Button
          variant="positive"
          onClick={handleSubmit}
          disabled={organization !== undefined || areFormInputsInvalid() || isPending}
        >
          <FormattedMessage
            description="Register Button: label for register button"
            defaultMessage="Register"
            id="cnAHc4"
          />
        </Modal.Button>
      </Modal.ButtonGroup>
    </Modal>
  );
}

export default NewOrganization;
