import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import QKey from '../../../../shared/common/hooks/keys';
import { ApiError } from '../../../../shared/common/types/api-error/api-error';
import { DataTrackDataPoints } from '../../types/data-track';
import { ControlProcedureId, SetupId } from '../../types/setup';

import { addDataPoints } from './use-add-data-points.helpers';

function useAddDataPoints(
  { controlProcedureId, setupId }: { controlProcedureId: ControlProcedureId; setupId: SetupId },
  {
    onSuccess,
    ...options
  }: UseMutationOptions<unknown, ApiError, DataTrackDataPoints[], unknown> = {},
) {
  const { acquireAccessToken } = useAuthentication();

  return useMutation({
    ...options,
    mutationKey: [QKey.SETUPS, setupId, QKey.DATA_POINTS],
    mutationFn: async (dataTracks: DataTrackDataPoints[]) => {
      const accessToken = await acquireAccessToken();
      return addDataPoints(controlProcedureId, setupId, dataTracks, accessToken);
    },
    onSuccess: (data, variables, context) => {
      onSuccess?.(data, variables, context);
    },
  });
}

export default useAddDataPoints;
