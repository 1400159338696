import React, { FunctionComponentElement } from 'react';
import cn from 'classnames';

import { CellContext } from '@tanstack/react-table';

import { CELL_ERROR_STYLES, EditableTableData } from '../editable-data-table.definitions';

function TextCell({
  getValue,
  row: { index },
  column: {
    id,
    columnDef: { meta },
  },
  table,
}: CellContext<EditableTableData, unknown>): FunctionComponentElement<
  CellContext<EditableTableData, unknown>
> {
  const isDisabled = meta?.disabled?.includes(index);
  const isError = meta?.error?.includes(index);
  const type = meta?.type || 'text';
  const initialValue = getValue();

  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  // When the input is blurred, we'll call our table meta's updateData function
  const onBlur = () => {
    table.options.meta?.updateData(index, id, value);
  };

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value);
  };

  // render value if cell is not editable
  if (isDisabled) {
    return (
      <div
        className={`flex h-full items-center px-4 py-2 text-secondary
        ${cn({
          'justify-end': type === 'number',
          [CELL_ERROR_STYLES]: isError,
        })}`}
      >
        {value as string}
      </div>
    );
  }
  return (
    <input
      value={value as string}
      name={id}
      className={`h-full w-full rounded-none px-4 py-2 text-right focus:outline focus:outline-1 focus:outline-blue-500 
         ${cn({
           [CELL_ERROR_STYLES]: isError,
         })}`}
      onChange={onChange}
      onBlur={onBlur}
      type={type}
      data-testid="editable-table-text-field"
    />
  );
}

export default TextCell;
