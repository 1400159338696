import React, { FunctionComponentElement, useEffect, useRef, useState } from 'react';

import useDataTracks from '../../../common/hooks/use-data-tracks';
import DataTrackTimeAlignment from '../setup-details.definitions';
import ProcessRecordAlignment from '../../../components/process-record-alignment/process-record-alignment';
import ReferenceCurveSelector from '../reference-curve-selector';
import useProcessRecord from '../../../common/hooks/use-process-record/use-process-record';
import ProcessValues from '../process-values';
import FinishedUnitMessage from '../finished-unit-message';
import { DataTrack } from '../../../../shared/common/types/process-record';
import { getLastTimestamp, isFetched } from '../../../common/helpers';
import SetupTimeSeriesChart from '../setup-time-series-chart';
import TileWithSkeleton from '../../../../shared/tile-with-skeleton';
import ConnectionLostMessage from '../../../components/connection-lost-message';

import {
  getSeries,
  getMarkLines,
  getTimeDifference,
  getProcessValues,
  getDataTracksLastTimestamps,
  combineDataTracks,
  getFailedDataTracks,
  combineDataPoints,
} from './setup-details-content.helpers';
import { SetupDetailsContentProps } from './setup-details-content.definitions';

function SetupDetailsContent({
  setupId,
  dataTrackDescriptors,
  startTimestamp,
  stopTimestamp,
  inoculationTimestamp,
  requestedDataTrackIds,
  selectedDataTracks,
  referenceProcessId,
  seriesLegendSelected,
  lastDataPoints,
  isLoadingSetup,
  isDisconnected = false,
  updateIsLoadingSetup,
  updateDataTracksTimestamp,
  deleteDataTracksTimestamp,
  toggleSeriesLegendSelected,
  updateDataTrackColors,
  onReferenceProcessRecord,
}: SetupDetailsContentProps): FunctionComponentElement<SetupDetailsContentProps> {
  const [timeAlignment, setTimeAlignment] = useState(DataTrackTimeAlignment.RelativeToStartTime);
  const isFetchingPrev = useRef<boolean>(false);
  const results = useDataTracks(setupId, requestedDataTrackIds || []);
  const tracks = results
    .filter((result) => result.isSuccess && result.data)
    .map((result) => result.data as DataTrack);

  const [dataTracks, setDataTracks] = useState<DataTrack[]>([]);

  const {
    data: referenceProcessRecord,
    isLoading: isLoadingReferenceProcessRecord,
    isError: isErrorReferenceProcessRecord,
    error: errorReferenceProcessRecord,
  } = useProcessRecord(referenceProcessId);

  const isLoading = results.some((result) => result.isLoading);
  const isFetching = results.some((result) => result.isFetching);

  function updateDataTracks(dataTrackList: DataTrack[]) {
    setDataTracks(dataTrackList);

    if (!stopTimestamp) {
      const failedDataTracks = getFailedDataTracks(dataTrackList, requestedDataTrackIds);
      const dataTracksLastTimestamps = getDataTracksLastTimestamps(dataTrackList);
      updateDataTracksTimestamp([...dataTracksLastTimestamps, ...failedDataTracks]);
    }
    if (isFetched(isFetching, isFetchingPrev.current) && isLoadingSetup) {
      updateIsLoadingSetup(false);
    }
  }

  // set time aligment when a reference curve is selected
  useEffect(() => {
    const alignment =
      inoculationTimestamp && referenceProcessRecord?.inoculationTimestamp
        ? DataTrackTimeAlignment.RelativeToInoculationTime
        : DataTrackTimeAlignment.RelativeToStartTime;
    setTimeAlignment(alignment);
  }, [referenceProcessRecord]);

  // combine data points when receive last data points
  useEffect(() => {
    if (dataTracks.length && lastDataPoints?.length) {
      const combinedDataPoints = combineDataPoints(
        dataTracks,
        lastDataPoints,
        dataTrackDescriptors,
      );
      updateDataTracks(combinedDataPoints);
    }
  }, [lastDataPoints]);

  useEffect(() => {
    if (stopTimestamp) {
      // delete data tracks timestamps because finished setup is not updated automatically
      deleteDataTracksTimestamp();
    }
  }, [stopTimestamp]);

  // set time aligment when a reference curve is selected
  useEffect(() => {
    if (requestedDataTrackIds && !requestedDataTrackIds.length && isLoadingSetup) {
      updateIsLoadingSetup(false);
    }
  }, [requestedDataTrackIds, isLoadingSetup]);

  // update data tracks when receive new ones and save fetching state
  useEffect(() => {
    if (!isLoading && !isFetching) {
      updateDataTracks(combineDataTracks(dataTracks, tracks));
    }
    if (isFetchingPrev.current !== isFetching) {
      isFetchingPrev.current = isFetching;
    }
  }, [isLoading, isFetching, tracks.length]);

  const isFinished = Boolean(stopTimestamp);

  const timeDifference = getTimeDifference(
    timeAlignment,
    startTimestamp,
    inoculationTimestamp,
    referenceProcessRecord,
  );
  const processValues = getProcessValues(dataTracks, selectedDataTracks, isFinished);
  const series = getSeries(
    dataTracks,
    selectedDataTracks,
    timeDifference,
    referenceProcessRecord?.dataTracks,
  );
  const lastTimestamp = getLastTimestamp(dataTracks);
  const seriesMarkLines = getMarkLines(
    timeAlignment,
    timeDifference,
    inoculationTimestamp,
    referenceProcessRecord?.inoculationTimestamp,
    isDisconnected,
    lastTimestamp,
    stopTimestamp,
  );

  return (
    <>
      <ConnectionLostMessage
        visible={isDisconnected && !isFinished && !isLoadingSetup}
        lastTimestamp={lastTimestamp}
      />
      <ProcessValues
        processValues={processValues}
        isShowLoading={isLoadingSetup}
        isDisconnected={isDisconnected}
      />
      <FinishedUnitMessage visible={isFinished && !isLoadingSetup} stopTimestamp={stopTimestamp} />

      <TileWithSkeleton
        className="min-h-[66px] p-4"
        isLoading={isLoadingSetup}
        data-testid="referenceCurve"
      >
        <ReferenceCurveSelector
          setupId={setupId}
          onReferenceProcessRecord={onReferenceProcessRecord}
          referenceProcessRecord={referenceProcessRecord}
          isLoading={isLoadingReferenceProcessRecord}
          isError={isErrorReferenceProcessRecord}
          error={errorReferenceProcessRecord}
        />
        {referenceProcessRecord && (
          <ProcessRecordAlignment
            onChangeAlignment={setTimeAlignment}
            defaultValue={timeAlignment.toString()}
            defaultOpen={false}
            disabled={!(inoculationTimestamp && referenceProcessRecord?.inoculationTimestamp)}
          />
        )}
      </TileWithSkeleton>
      <SetupTimeSeriesChart
        startTimestamp={startTimestamp}
        stopTimestamp={stopTimestamp}
        selectedDataTracks={selectedDataTracks}
        seriesLegendSelected={seriesLegendSelected}
        seriesMarkLines={seriesMarkLines}
        toggleSeriesLegendSelected={toggleSeriesLegendSelected}
        isShowLoading={isLoadingSetup}
        updateDataTrackColors={updateDataTrackColors}
        series={series}
        dataTracks={dataTracks}
      />
    </>
  );
}

export default SetupDetailsContent;
