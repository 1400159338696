import fetchData from '../../../../shared/common/fetch-data';
import { HttpMethods } from '../../../../shared/common/types/http';
import { Setup } from '../../types/setup';
import { isSetupDTO } from '../../types/dto/validators';
import { transformSetupDTO } from '../../types/dto/setup-dto-transformer';
import { projectConfigInstance } from '../../../../shared/globals/project-config/project-config-accessor';
import { joinPaths } from '../../../../shared/utils';

export function createSetupUrl(setupId: string): string {
  return joinPaths(projectConfigInstance.get('PMS_BACKEND_URL'), 'setups', setupId);
}

export async function fetchSetup(setupId: string, accessToken: string): Promise<Setup> {
  const result = await fetchData(createSetupUrl(setupId), {
    method: HttpMethods.Get,
    headers: {
      Accept: 'application/json',
      'Accept-Encoding': 'gzip, deflate, br',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  try {
    isSetupDTO(result);
    return transformSetupDTO(result);
  } catch {
    throw new Error('Invalid Data');
  }
}
