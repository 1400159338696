import React, { FunctionComponentElement, useRef, useCallback, useState, useEffect } from 'react';
import { Card } from '@biss/react-horizon-web';

import { SetupDescriptor } from '../../../common/types';
import { DataTrackFromTimestamp, DataTrackLastTimestamp } from '../../../common/types/data-track';
import { DataTrackId } from '../../../../shared/common/types/process-record/data-track';
import useDeleteWorkflow from '../../../common/hooks/use-delete-workflow';
import useWorkflowIdle from '../../../common/hooks/use-workflow-idle';
import { useDataTracksLastTimestamp } from '../../../common/hooks/use-data-tracks-from-timestamp';
import { DATA_TRACKS_FROM_TIMESTAMP_INTERVAL, SetupId } from '../../../common/types/setup';

import ConnectionLostMessage from '../../../components/connection-lost-message';

import {
  getFirstTimestamp,
  isSameFirstDataPoint,
  isStartGapRecovery,
  getDataTrackLastTimestamp,
  getGapRecoveryTimestamp,
} from '../../../common/helpers';

import SetupOverviewUnit from './setup-overview-unit';
import { SetupOverviewWorkflowProps } from './setup-overview-workflow.definitions';
import RemoveWorkflowMessage from './delete-workflow-message';
import {
  getIsDisconnected,
  isFromTimestamp,
  setTimestamp,
} from './setup-overview-workflow.helpers';

function SetupOverviewWorkflow({
  autoUpdate,
  workflow,
  timeSpan,
  selectedDataTracks,
  isLoadingSetups,
  setIsLoadingSetups,
  updateDataTrackList,
}: SetupOverviewWorkflowProps): FunctionComponentElement<SetupOverviewWorkflowProps> {
  const dataTracksTimestamp = useRef<Record<SetupId, DataTrackLastTimestamp[]>>({});
  const [isIdle, setIsIdle] = useState<boolean>(false);
  const [idleSetups, setIdleSetups] = useState<string[]>([]);
  const [lastDataPoints, setLastDataPoints] = useState<DataTrackFromTimestamp[]>([]);
  const firstTimestampPrev = useRef<number | null>();
  const { controlProcedureId } = workflow[0];
  const { mutate: deleteWorkflow } = useDeleteWorkflow(controlProcedureId);
  const { mutate: workflowIdle } = useWorkflowIdle(controlProcedureId, setIsIdle);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const isDisconnectedPrev = useRef<boolean>(false);
  const gapRecoveryTimestamp = useRef<string | null>();
  const isDisconnected = getIsDisconnected(workflow);
  const autoUpdateCounter = useRef<number>(0);

  const updateDataTracksTimestamp = useCallback(
    (setupId: string, tracks: DataTrackLastTimestamp[]) => {
      dataTracksTimestamp.current = {
        ...dataTracksTimestamp.current,
        [setupId]: tracks,
      };
    },
    [],
  );

  const deleteDataTracksTimestamp = useCallback((setupId: string) => {
    if (dataTracksTimestamp?.current[setupId]) {
      delete dataTracksTimestamp.current[setupId];
    }
  }, []);

  const { getLastDataPoints } = useDataTracksLastTimestamp();

  const stopGapRecovery = () => {
    firstTimestampPrev.current = null;
    gapRecoveryTimestamp.current = null;
  };

  const gapRecovery = (data: DataTrackFromTimestamp[]) => {
    if (!data.length) {
      stopGapRecovery();
    } else {
      const firstTimestamp = getFirstTimestamp(data);
      if (isSameFirstDataPoint(firstTimestamp, firstTimestampPrev.current)) {
        stopGapRecovery();
      } else {
        firstTimestampPrev.current = firstTimestamp;
      }
    }
  };

  const getDataPoints = async () => {
    try {
      let lastTimestamps;
      if (isFromTimestamp(autoUpdateCounter.current, timeSpan, gapRecoveryTimestamp.current)) {
        lastTimestamps = getDataTrackLastTimestamp(
          Object.values(dataTracksTimestamp.current).flat(1),
          gapRecoveryTimestamp.current,
        );
      } else {
        lastTimestamps = setTimestamp(Object.values(dataTracksTimestamp.current).flat(1));
        autoUpdateCounter.current = 0;
      }
      if (!lastTimestamps.length) {
        return;
      }
      const data = await getLastDataPoints(controlProcedureId, lastTimestamps);
      if (data) {
        setLastDataPoints(data);
        if (gapRecoveryTimestamp.current) {
          gapRecovery(data);
        }
        autoUpdateCounter.current += 1;
      }
    } finally {
      timeoutRef.current = setTimeout(() => {
        getDataPoints();
      }, DATA_TRACKS_FROM_TIMESTAMP_INTERVAL);
    }
  };

  const clearTimeoutRef = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      autoUpdateCounter.current = 0;
    }
  };

  // uses to start or stop auto update of last data points
  useEffect(() => {
    if (autoUpdate && !isDisconnected) {
      getDataPoints();
    } else {
      clearTimeoutRef();
    }
    if (isStartGapRecovery(isDisconnected, isDisconnectedPrev.current)) {
      gapRecoveryTimestamp.current = getGapRecoveryTimestamp(
        Object.values(dataTracksTimestamp.current).flat(1),
      );
    }
    isDisconnectedPrev.current = isDisconnected;
    return () => {
      clearTimeoutRef();
    };
  }, [autoUpdate, isDisconnected]);

  useEffect(() => {
    if (idleSetups.length && idleSetups.length === workflow.length) {
      workflowIdle();
    }
  }, [idleSetups]);

  const addIdleSetup = useCallback(
    (setupId: DataTrackId) => {
      if (!idleSetups.includes(setupId)) {
        setIdleSetups((prev) => [...prev, setupId]);
      }
    },
    [idleSetups],
  );

  const removeIdleSetup = useCallback(
    (setupId: DataTrackId) => {
      if (idleSetups.includes(setupId)) {
        setIdleSetups((prev) => prev.filter((item) => item !== setupId));
        setIsIdle(false);
      }
    },
    [idleSetups],
  );

  return (
    <>
      <div className="mb-2">
        <RemoveWorkflowMessage
          visible={isIdle && !isDisconnected}
          workflowTitle={workflow[0].title}
          onDelete={deleteWorkflow}
        />
        <ConnectionLostMessage visible={isDisconnected} />
      </div>
      <Card.Grid>
        {workflow.map((setupItem: SetupDescriptor) => (
          <SetupOverviewUnit
            key={setupItem.setupId}
            setupItem={setupItem}
            timeSpan={timeSpan}
            lastDataPoints={lastDataPoints}
            selectedDataTracks={selectedDataTracks}
            updateDataTracksTimestamp={updateDataTracksTimestamp}
            deleteDataTracksTimestamp={deleteDataTracksTimestamp}
            updateDataTrackList={updateDataTrackList}
            isLoadingSetups={isLoadingSetups}
            setIsLoadingSetups={setIsLoadingSetups}
            addIdleSetup={addIdleSetup}
            removeIdleSetup={removeIdleSetup}
            isIdle={idleSetups.includes(setupItem.setupId)}
          />
        ))}
      </Card.Grid>
    </>
  );
}

export default SetupOverviewWorkflow;
