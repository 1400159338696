import { DATA_COLORS, palettes } from '@biss/react-horizon-web';

import { ProcessRecordId, ProcessRecordObject } from '../../../shared/common/types/process-record';

/** colors used to mark process records, and their graph colors */
export const PROCESS_RECORD_COLORS = palettes.categorical;

/** a single process record color from the pallete */
export type ProcessRecordColor = (typeof PROCESS_RECORD_COLORS)[number];

/** a process record that was asigned a color */
export type ColoredProcessRecord = ProcessRecordObject & {
  color: ProcessRecordColor;
};

/** object, mapping the id of process records to their colors */
export type ProcessRecordColors = {
  [key: ProcessRecordId]: (typeof DATA_COLORS)[keyof typeof DATA_COLORS];
};
