import fetchData from '../../../../shared/common/fetch-data';
import { HttpMethods } from '../../../../shared/common/types/http';
import { DataTrackId } from '../../../../shared/common/types/process-record';
import { projectConfigInstance } from '../../../../shared/globals/project-config';
import { joinPaths } from '../../../../shared/utils';
import {
  CONTROL_PROCEDURES,
  ControlProcedureId,
  SETUPS,
  DATA_TRACKS,
  SetupId,
} from '../../types/setup';

export function createDeleteDataTrackUrl(
  controlProcedureId: ControlProcedureId,
  setupId: SetupId,
  dataTrackId: DataTrackId,
): string {
  return joinPaths(
    projectConfigInstance.get('PMS_BACKEND_URL'),
    CONTROL_PROCEDURES,
    controlProcedureId,
    SETUPS,
    setupId,
    DATA_TRACKS,
    dataTrackId,
  );
}

export async function deleteDataTrack(
  controlProcedureId: ControlProcedureId,
  setupId: SetupId,
  dataTrackId: DataTrackId,
  accessToken: string,
): Promise<void> {
  await fetchData(createDeleteDataTrackUrl(controlProcedureId, setupId, dataTrackId), {
    method: HttpMethods.Delete,
    headers: {
      Accept: 'application/json',
      'Accept-Encoding': 'gzip, deflate, br',
      Authorization: `Bearer ${accessToken}`,
    },
  });
}
