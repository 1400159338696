import React, { FunctionComponentElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Checkbox, Input } from '@biss/react-horizon-web';
import { isEppendorfAdministrator, isEppendorfService, useAuthContext } from '@biss/react-auth-web';

import InvitationInfo from '../invitation-info/invitation-info';
import { displayNameValidationPattern } from '../../common/utils/validation-patterns';

import MemberGeneralInfoProps, { ROLE_NAME_MAP } from './member-general-info.definitions';

function MemberGeneralInfo({
  member,
  roles,
  onPropertyChanged,
  onRolesChanged,
  onMfaChanged,
  defaultMfaValue,
  onValueEntered,
  errors,
}: MemberGeneralInfoProps): FunctionComponentElement<MemberGeneralInfoProps> {
  const intl = useIntl();
  const readOnly = member !== undefined;
  const { account } = useAuthContext();
  const ownAccount = member !== undefined && member.memberId === account?.accountId;

  const readonlyMfa =
    account !== null && (isEppendorfAdministrator(account) || isEppendorfService(account));
  let mfaChecked = defaultMfaValue;
  if (member !== undefined) {
    mfaChecked = member.mfa;
  }
  const { formatMessage } = useIntl();

  return (
    <>
      <Input
        className="mb-4 w-full"
        id="memberDislayNameInput"
        name="memberDisplayName"
        label={intl.formatMessage({
          defaultMessage: 'Full name',
          id: 'i5A/c7',
          description: 'Label for memberDislayNameInput input.',
        })}
        placeholder={intl.formatMessage({
          defaultMessage: 'e.g. Lastname Firstname',
          id: 'Rv2J96',
          description: 'Placeholder for memberDislayNameInput input.',
        })}
        type="text"
        value={member?.memberDisplayName}
        onChange={onPropertyChanged}
        readOnly={readOnly}
        disabled={readOnly}
        pattern={displayNameValidationPattern.source}
        onBlur={onValueEntered}
        error={errors.get('memberDisplayName')}
      />
      <Input
        className="mb-4 w-full"
        id="memberEmailInput"
        name="memberEmail"
        label={intl.formatMessage({
          defaultMessage: 'E-mail address',
          id: 'h5UhNl',
          description: 'Label for memberEmailInput input.',
        })}
        placeholder={intl.formatMessage({
          defaultMessage: 'user@example.com',
          id: 'bP+Rrz',
          description: 'Placeholder for memberEmailInput input.',
        })}
        type="email"
        value={member?.memberEmail}
        onChange={onPropertyChanged}
        readOnly={readOnly}
        disabled={readOnly}
        error={errors.get('memberEmail')}
        onBlur={onValueEntered}
        required
      />
      <ul>
        {roles.map((role) => (
          <li key={`${role}RoleItem`} className="mb-4 flex w-fit flex-col gap-1">
            <Checkbox
              id={`${role}RoleInput`}
              name={role}
              checked={member && member.memberRoles.findIndex((r) => r === role) >= 0}
              onCheckedChange={(checked) =>
                onRolesChanged && onRolesChanged(role, checked === true)
              }
              disabled={member?.invitation !== undefined || ownAccount}
              label={ROLE_NAME_MAP.get(role) ?? role}
            />
          </li>
        ))}
      </ul>
      <div className="mb-2 text-xs">
        <FormattedMessage
          defaultMessage="Authentication"
          description="Authentication label in Member details dialog."
          id="NRAiNB"
        />
      </div>
      <Checkbox
        id="mfaInput"
        name="mfa"
        checked={mfaChecked}
        onCheckedChange={onMfaChanged}
        disabled={member?.invitation !== undefined || readonlyMfa || ownAccount}
        label={formatMessage({
          defaultMessage: 'Multi-Factor Authentication Required',
          description: 'MFA label',
          id: 'OAhzWZ',
        })}
      />
      {member?.invitation && <InvitationInfo invitation={member.invitation} />}
    </>
  );
}

export default MemberGeneralInfo;
