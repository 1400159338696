import React, { FunctionComponentElement } from 'react';
import { useMediaQuery } from 'usehooks-ts';

import { useFormatDateTime } from '../../../../shared/common/hooks/use-format-time';
import TimeSeriesChart from '../../../../shared/components/time-series-chart';

import {
  getSeriesLegend,
  yAxisFormatter,
} from '../setup-details-content/setup-details-content.helpers';

import TileWithSkeleton from '../../../../shared/tile-with-skeleton';

import { SetupTimeSeriesChartProps } from './setup-time-series-chart.definitions';

function SetupTimeSeriesChart({
  startTimestamp,
  stopTimestamp,
  selectedDataTracks,
  seriesLegendSelected,
  seriesMarkLines,
  toggleSeriesLegendSelected,
  updateDataTrackColors,
  isShowLoading,
  series,
  dataTracks,
}: SetupTimeSeriesChartProps): FunctionComponentElement<SetupTimeSeriesChartProps> {
  const xAxisFormatter = useFormatDateTime();
  const isMobile = useMediaQuery('(max-width: 768px)');

  const seriesLegend = getSeriesLegend(dataTracks, selectedDataTracks);
  const stopTime = stopTimestamp?.getTime() || new Date().getTime();

  const toggleSplitView = (isCombined: boolean) => {
    if (isCombined) {
      updateDataTrackColors();
    }
  };

  return (
    <TileWithSkeleton
      className={`${isMobile ? 'min-h-[383px]' : 'min-h-[507px]'} p-4`}
      isLoading={isShowLoading}
      data-testid="detail-graph"
    >
      {startTimestamp && (
        <TimeSeriesChart
          series={series}
          startTime={startTimestamp?.getTime()}
          stopTime={stopTime}
          combinedGraph
          showTooltip
          xAxisFormatter={xAxisFormatter}
          yAxisFormatter={yAxisFormatter}
          seriesMarkLines={seriesMarkLines}
          toggleSplitView={toggleSplitView}
          showToggleSplit
          showZoom
          showToolbox
          showLegend
          seriesLegend={seriesLegend}
          legendSelected={seriesLegendSelected}
          toggleLegendSelected={toggleSeriesLegendSelected}
        />
      )}
    </TileWithSkeleton>
  );
}

export default SetupTimeSeriesChart;
