import { ColumnDef } from '@tanstack/react-table';

export type TValue = string | number | unknown;
export type EditableTableData = Record<string, TValue>;

export interface EditableTableProps<T extends object> {
  /** table data */
  data: T[];

  /** table columns */
  columns: ColumnDef<T>[];

  /** table error message */
  errorMessage?: React.ReactNode;

  /** calls when editable cell is updated */
  updateTableData: (rowIndex: number, columnId: string, value: TValue) => void;

  /** renders Add Row button and calls when the button is clicked */
  addRow?: () => void;

  /** renders Delete Row button and calls when the button is clicked */
  deleteRow?: () => void;

  /** Remove the user selected row in the table */
  deleteSelectedRow?: (rowId: number) => void;
}

export const CELL_ERROR_STYLES = 'border-2 border-warning';
