import { UseQueryResult, UseQueryOptions, useQueries } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import QKey from '../../../../shared/common/hooks/keys';
import { DataTrack } from '../../../../shared/common/types/process-record';

import { fetchDataTrack } from './use-data-tracks.helpers';

function useDataTracks(
  setupId: string,
  dataTrackIds: string[],
): UseQueryResult<DataTrack, Error>[] {
  const { acquireAccessToken } = useAuthentication();

  return useQueries({
    queries: dataTrackIds.map<UseQueryOptions<DataTrack, Error>>((dataTrackId) => ({
      queryKey: [QKey.SETUPS, setupId, dataTrackId],
      queryFn: async () => {
        const accessToken = await acquireAccessToken();
        return fetchDataTrack(setupId, dataTrackId, accessToken);
      },
      refetchOnWindowFocus: false,
      retry: false,
    })),
  });
}

export default useDataTracks;
