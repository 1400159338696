import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Kbd } from '../../../../shared/components/kbd';
import {
  DataTrack,
  DataTrackType,
  ProcessRecordObject,
} from '../../../../shared/common/types/process-record';

export interface ProcessRecordVisualizationProps {
  processRecords: ProcessRecordObject[];
  totalProcessRecordCount?: number;
  pageTitle?: string;
  defaultSidebarOpen?: boolean;
}

export enum DataTrackTimeAlignment {
  RelativeToStartTime,
  RelativeToInoculationTime,
  Absolute,
}

export type TabKey = 'data-track-selection' | 'data-how-integration';

/**
 * default datatracks to select when viewing process records
 */
export const DEFAULT_DATATRACK_TYPES = ['pH.PV', 'DO.PV', 'T.PV'] satisfies DataTrackType[];

export const SHORTCUTS = (
  <ul className="flex flex-col">
    <li>
      <FormattedMessage
        defaultMessage="Use the scrollwheel on your mouse while pressing and holding"
        description="Modifier hints: Hold"
        id="wJXzIj"
      />
      <Kbd>
        <FormattedMessage defaultMessage="Shift" description="Modifier hints: Shift" id="o8AQHr" />
      </Kbd>
      <FormattedMessage
        defaultMessage="to zoom in and out."
        description="Modifier hints: Shift action"
        id="Olzeef"
      />
    </li>
    <li>
      <FormattedMessage
        defaultMessage="Use the scrollwheel on your mouse while pressing and holding"
        description="Modifier hints: Hold"
        id="wJXzIj"
      />
      <Kbd>
        <FormattedMessage defaultMessage="Ctrl" description="Modifier hints: Ctrl" id="JllEIq" />
      </Kbd>
      <FormattedMessage
        defaultMessage="to pan left and right."
        description="Modifier hints: Ctrl action"
        id="RQZt6B"
      />
    </li>
  </ul>
);

/**
 * data track with a reverse reference to its parent @see processRecord,
 * and an added @see color field
 * */
export interface MarkedDataTrack extends DataTrack {
  processRecord: ProcessRecordObject;
  color: string;
}

export type DataTrackTypes = Record<DataTrackType, MarkedDataTrack[]>;
