import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import { DeleteIcon, EditIcon, FailedIcon, SuccessIcon } from '@biss/react-horizon-web';

import { MIN_PILL_INPUT_WIDTH, PillProps, SEPARATOR } from './pill.definitions';
import {
  calculateStringWidth,
  getButtonClassName,
  getInputClassName,
  getIsButtonDisabled,
  getIsInputBeingEdited,
  getIsInputDisabled,
  getMood,
} from './pill.helpers';

function Pill({
  isBeingEdited = false,
  isEditable = true,
  isRemovable = true,
  disabled = false,
  name = '',
  nameInputLabel = 'pill-key-input',
  onNameChange,
  value = '',
  valueInputLabel = 'pill-value-input',
  onValueChange,
  onEditClick,
  onDeleteClick,
  onCancelClick,
  onConfirmClick,
  focusOnEdit,
  isError = false,
  isWarning = false,
  isCancelDisabled = false,
  isConfirmDisabled = false,
  isDeleteDisabled = false,
  isEditDisabled = false,
  isBeingRemoved = false,
  isSmall = false,
}: PillProps) {
  // used to focus on the inputs
  const nameInputRef = useRef<HTMLInputElement>(null);
  const valueInputRef = useRef<HTMLInputElement>(null);

  // if the `focusOnEdit` prop is set, focus on the corresponding input when entering edit state
  useEffect(() => {
    if (isBeingEdited === false) {
      return;
    }

    if (focusOnEdit === 'name') {
      nameInputRef.current?.focus();
      nameInputRef.current?.select();
    }

    if (focusOnEdit === 'value') {
      valueInputRef.current?.focus();
      valueInputRef.current?.select();
    }
  }, [isBeingEdited]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.currentTarget.value;
    onNameChange?.(newName);
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    onValueChange?.(newValue);
  };

  const handleEditClick = () => onEditClick?.(name, value);

  const handleDeleteClick = () => onDeleteClick?.(name, value);

  const handleCancelClick = () => onCancelClick?.(name, value);

  const handleConfirmClick = () => onConfirmClick?.(name, value);

  /**
   * generate the style that adapts the inputs width to its text value
   * adds the width of a single character to avoid flickering when typing
   */
  const adaptInputWidthToValue = (v: string) =>
    `calc(${calculateStringWidth(v)}px + ${isBeingEdited ? '1ch' : '0px'})`;

  const isNameInputDisabled = getIsInputDisabled(
    'name',
    disabled,
    isBeingEdited,
    isBeingRemoved,
    isEditable,
  );

  const isValueInputDisabled = getIsInputDisabled(
    'value',
    disabled,
    isBeingEdited,
    isBeingRemoved,
    isEditable,
  );

  const isNameInputBeingEdited = getIsInputBeingEdited('name', isBeingEdited, isEditable);

  const isValueInputBeingEdited = getIsInputBeingEdited('value', isBeingEdited, isEditable);

  const mood = getMood(isError, isWarning);

  const isConfirming = isBeingEdited || isBeingRemoved;

  const nameInputClassName = getInputClassName(isNameInputBeingEdited, mood);

  const valueInputClassName = getInputClassName(isValueInputBeingEdited, mood);

  const buttonClassName = getButtonClassName(mood);

  return (
    <div
      className={cn(
        'inline-flex flex-row flex-nowrap items-center rounded-full border',
        {
          'border-red-700 bg-red-25 text-red-700': mood === 'error',
          'border-yellow-700 bg-yellow-25 text-yellow-700': mood === 'warning',
          'border-blue-700 bg-blue-25 text-blue-700': mood === 'normal',
        },
        isSmall ? 'h-6 px-2' : 'h-8 px-3',
      )}
    >
      <input
        ref={nameInputRef}
        type="text"
        data-testid={nameInputLabel}
        aria-label={nameInputLabel}
        readOnly={isNameInputDisabled}
        value={name}
        onChange={handleNameChange}
        style={{
          width: adaptInputWidthToValue(name),
          minWidth: MIN_PILL_INPUT_WIDTH,
        }}
        className={nameInputClassName}
      />

      {
        // hide the separator and the value input if the value is empty and the pill is not being edited
        (isBeingEdited || value.length > 0) && (
          <>
            <span className="mr-2 select-none">{SEPARATOR}</span>

            <input
              ref={valueInputRef}
              type="text"
              data-testid={valueInputLabel}
              aria-label={valueInputLabel}
              readOnly={isValueInputDisabled}
              value={value}
              onChange={handleValueChange}
              style={{
                width: adaptInputWidthToValue(value),
                minWidth: MIN_PILL_INPUT_WIDTH,
              }}
              className={valueInputClassName}
            />
          </>
        )
      }

      {isConfirming ? (
        // confirmation buttons
        <>
          <button
            data-testid="cancel-button"
            type="button"
            disabled={getIsButtonDisabled(disabled, isCancelDisabled)}
            onClick={handleCancelClick}
            className={buttonClassName}
          >
            <FailedIcon className="h-6 w-6" />
          </button>

          <button
            data-testid="confirm-button"
            type="button"
            disabled={getIsButtonDisabled(disabled, isConfirmDisabled)}
            onClick={handleConfirmClick}
            className={buttonClassName}
          >
            <SuccessIcon className="h-6 w-6" />
          </button>
        </>
      ) : (
        // edit and remove buttons
        <>
          {isEditable && (
            <button
              data-testid="edit-button"
              type="button"
              disabled={getIsButtonDisabled(disabled, isEditDisabled)}
              onClick={handleEditClick}
              className={buttonClassName}
            >
              <EditIcon className="h-6 w-6" />
            </button>
          )}

          {isRemovable && (
            <button
              data-testid="delete-button"
              type="button"
              disabled={getIsButtonDisabled(disabled, isDeleteDisabled)}
              onClick={handleDeleteClick}
              className={buttonClassName}
            >
              <DeleteIcon className="h-6 w-6" />
            </button>
          )}
        </>
      )}
    </div>
  );
}

export default Pill;
