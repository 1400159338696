import { isDataTrackDTO } from '../../../../monitoring/common/types/dto/validators';
import fetchData from '../../../../shared/common/fetch-data';
import { HttpMethods } from '../../../../shared/common/types/http';
import { DataTrack } from '../../../../shared/common/types/process-record';
import { CalculationRequest } from '../../../../shared/common/types/process-record/dto/calculator';
import { projectConfigInstance } from '../../../../shared/globals/project-config';
import { joinPaths } from '../../../../shared/utils';

export function createCalculateDataTrackUrl() {
  return joinPaths(projectConfigInstance.get('PRS_BACKEND_URL'), 'calculator');
}

export async function calculateDataTrack(
  data: CalculationRequest,
  accessToken: string,
): Promise<DataTrack> {
  const json = JSON.stringify(data);

  const response = await fetchData(createCalculateDataTrackUrl(), {
    method: HttpMethods.Post,
    headers: {
      Accept: 'application/json',
      'Accept-Encoding': 'gzip, deflate, br',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: json,
  });

  isDataTrackDTO(response);

  return response;
}
