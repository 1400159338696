import { z } from 'zod';

export const minLesserThanMax = (arg: { min?: number; max?: number }): boolean => {
  if (arg.min === undefined || arg.max === undefined) {
    return true;
  }

  return arg.min < arg.max;
};

export const yAxisRangeSchema = z
  .object({
    min: z.union([
      z.number({
        message: 'please enter a number',
        coerce: false,
      }),
      z.undefined(),
    ]),

    max: z.union([
      z.number({
        message: 'please enter a number',
        coerce: false,
      }),
      z.undefined(),
    ]),
  })
  .refine(minLesserThanMax, {
    message: 'min should be greater than max',
    path: ['min'],
  });

export type YAxisRange = Partial<z.infer<typeof yAxisRangeSchema>>;
