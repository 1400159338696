import { PageLayout, Skeleton, Tile } from '@biss/react-horizon-web';
import React from 'react';

import { many } from '../../common/utils';
import Footer from '../../components/footer';

function WelcomeSkeleton() {
  return (
    <>
      <PageLayout.Main.ActionBar
        sidePanelOpen="none"
        pageTitle={<Skeleton variant="rectangular" width="14rem" />}
      />
      <PageLayout.Main.Content
        className="flex h-full flex-col px-4 lg:w-full"
        data-testid="WelcomeSkeleton"
      >
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <div className="flex flex-col gap-4">
            {many(
              (i) => (
                <Tile key={i}>
                  <Tile.Body className="flex flex-col gap-8">
                    <div className="flex flex-col">
                      <div className="mb-4 flex flex-row items-center gap-4">
                        <Skeleton variant="rectangular" width="32px" />
                        <Skeleton variant="text" width="12rem" />
                      </div>

                      <ul className="flex flex-col gap-4">
                        {many(
                          (j) => (
                            <li key={j} className="pl-12">
                              <Skeleton variant="text" width="16rem" />
                            </li>
                          ),
                          3,
                        )}
                      </ul>
                    </div>
                  </Tile.Body>
                </Tile>
              ),
              3,
            )}
          </div>
          {/* news section */}
          <Tile className="hidden">
            <Tile.Header>
              <Skeleton variant="text" width="16rem" />
            </Tile.Header>
            <Tile.Body>
              <ul className="flex flex-col gap-16">
                {many(
                  (j) => (
                    <li key={j} className="flex flex-col gap-4">
                      <Skeleton variant="rectangular" width="12rem" />
                      <Skeleton variant="text" width="100%" />
                      <Skeleton variant="text" width="100%" />
                    </li>
                  ),
                  3,
                )}
              </ul>
            </Tile.Body>
          </Tile>
        </div>
        <div className="mt-auto">
          <Footer />
        </div>
      </PageLayout.Main.Content>
    </>
  );
}

export default WelcomeSkeleton;
