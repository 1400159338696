import { useIntl } from 'react-intl';

import timeSpan, { Precision } from '../../../../../shared/utils/time-span';
import { ProcessRecordObject } from '../../../../../shared/common/types/process-record';
import { DataPointObject } from '../../../../../shared/components/time-series-chart';

export function getEarliestDate(dates: Date[]): Date {
  if (dates.length === 0) {
    throw new TypeError('The given dates may not be empty');
  }

  return dates.reduce((pre, cur) => (pre > cur ? cur : pre));
}

export function useFormatXAxisTicks(): (ts: number) => string {
  const intl = useIntl();

  return (ts: number) =>
    intl.formatDate(ts, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
}

export function useRelativeXAxisTimestamps(precision: Precision = 'hours'): (ts: number) => string {
  return (ts: number) => timeSpan(ts, precision);
}

export const getDataPointsTimestamps = (processRecords: ProcessRecordObject[]) => {
  let dataPoints: DataPointObject[] = [];
  processRecords.forEach((processRecord) => {
    processRecord.dataTracks.forEach((dataTrack) => {
      dataPoints = dataTrack.dataPoints;
    });
  });

  return dataPoints.map((dataPoint) => dataPoint.ts);
};

export function getFinalDate(dates: Date[]): Date {
  if (dates.length === 0) {
    throw new TypeError('The given dates may not be empty');
  }

  return dates.reduce((pre, cur) => (pre < cur ? cur : pre));
}

export function getBiggestDeltaBetweenStartAndInoculationTime(
  processRecords: ProcessRecordObject[],
) {
  let inoculationDelta = 0;
  processRecords.forEach((record) => {
    record.dataTracks.forEach((dataTrack) => {
      dataTrack.dataPoints.forEach((dataPoint) => {
        if (record.inoculationTimestamp) {
          const delta = dataPoint.ts - record.inoculationTimestamp.getTime();
          if (delta < inoculationDelta) {
            inoculationDelta = delta;
          }
        }
      });
    });
  });

  return inoculationDelta;
}

export function getBiggestDeltaBetweenInoculationAndStopTime(
  processRecords: ProcessRecordObject[],
) {
  let inoculationDelta = 0;
  processRecords.forEach((record) => {
    record.dataTracks.forEach((dataTrack) => {
      dataTrack.dataPoints.forEach((dataPoint) => {
        if (record.inoculationTimestamp) {
          const delta = dataPoint.ts - record.inoculationTimestamp.getTime();
          if (delta > inoculationDelta) {
            inoculationDelta = delta;
          }
        }
      });
    });
  });

  return inoculationDelta;
}
