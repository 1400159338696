import { UseQueryResult, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import QKey from '../../../../shared/common/hooks/keys';
import { DataTrackFromTimestamp, DataTrackLastTimestamp } from '../../types/data-track';

import { fetchDataTracksFromTimestamp } from './use-data-tracks-from-timestamp.helpers';

// Request data points for a setup
export function useDataTracksFromTimestamp(
  controlProcedureId: string,
  dataTracks: Array<DataTrackLastTimestamp>,
  setupId: string,
): UseQueryResult<DataTrackFromTimestamp[], Error> {
  const { acquireAccessToken } = useAuthentication();

  return useQuery({
    queryKey: [QKey.SETUPS, QKey.DATA_POINTS, setupId],
    enabled: !!dataTracks?.length,
    retry: false,
    queryFn: async () => {
      const accessToken = await acquireAccessToken();
      return fetchDataTracksFromTimestamp(accessToken, controlProcedureId, { dataTracks });
    },
  });
}

// Request data points for a control procedure
export function useDataTracksLastTimestamp() {
  const { acquireAccessToken } = useAuthentication();
  const queryClient = useQueryClient();

  const getLastDataPoints = async (
    controlProcedureId: string,
    dataTracks: Array<DataTrackLastTimestamp>,
  ): Promise<DataTrackFromTimestamp[]> => {
    try {
      return await queryClient.fetchQuery({
        queryKey: [QKey.DATA_POINTS, controlProcedureId, dataTracks[0].id],
        queryFn: async () => {
          const accessToken = await acquireAccessToken();
          return fetchDataTracksFromTimestamp(accessToken, controlProcedureId, {
            dataTracks,
          });
        },
        retry: false,
      });
    } catch (e) {
      return [];
    }
  };

  return { getLastDataPoints };
}
