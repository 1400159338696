import { isDataTrackDTO } from '../../../../monitoring/common/types/dto/validators';
import { DataTrack } from '../../../../shared/common/types/process-record';
import { projectConfigInstance } from '../../../../shared/globals/project-config/project-config-accessor';
import { joinPaths } from '../../../../shared/utils';

export function createUploadDataTrackUrl(): string {
  return joinPaths(projectConfigInstance.get('PRS_BACKEND_URL'), 'parser', 'data-tracks');
}

export function validateDataTrackArray(response: unknown): asserts response is DataTrack[] {
  if (!Array.isArray(response)) {
    throw new Error('data was not in the form of an array');
  }

  response.forEach((dt) => {
    isDataTrackDTO(dt);
  });
}
