import React, { FunctionComponentElement } from 'react';
import {
  List,
  DeleteIcon,
  DesktopComputerIcon,
  CloudIcon,
  ChevronRightIcon,
  Button,
  ChevronLeftIcon,
  UserIcon,
  VesselIcon,
} from '@biss/react-horizon-web';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';

import ConfirmDelete from '../confirm-delete';
import RouteDefinition from '../../../common/routes/routes.definitions';
import { ConditionalLink } from '../../conditional-link';

import { ProcessRecordListItemProps } from './process-record-item.definitions';

export default function ProcessRecordListItem({
  processRecord,
  isSelected = false,
  isActionBarDisabled = false,
  isLink = true,
  isClickable = false,
  onCheckedChange,
  onPrimary,
  onDelete,
  onClick,
}: ProcessRecordListItemProps): FunctionComponentElement<ProcessRecordListItemProps> {
  const handleDelete = () => {
    onDelete?.(processRecord.processRecordId);
  };

  const handleCheck = (checked: boolean) => {
    onCheckedChange?.(checked);
  };

  const handlePrimary = () => {
    onPrimary?.(processRecord);
  };

  const handleClick = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent> | React.KeyboardEvent<HTMLLIElement>,
  ) => {
    onClick?.(processRecord, e);
  };

  return (
    <List.Item
      key={processRecord.displayName}
      clickable={isClickable}
      select={isLink ? 'multi' : 'single'}
      lining="zebra"
      isSelected={isSelected}
      onCheckedChange={handleCheck}
      onClick={handleClick}
      actions={
        isActionBarDisabled ? undefined : (
          <List.Item.ActionArea stopPropagation>
            <ConfirmDelete
              record={processRecord}
              onConfirm={handleDelete}
              trigger={
                <Button
                  mood="neutral"
                  kind="ghost"
                  data-testid="ProcessRecordItem-deleteButton"
                  leftIcon={<DeleteIcon />}
                />
              }
            />
            <Button
              mood="neutral"
              kind="ghost"
              data-testid="ProcessRecordItem-detailButton"
              leftIcon={<ChevronRightIcon />}
              onClick={handlePrimary}
            />
          </List.Item.ActionArea>
        )
      }
    >
      <List.Item.Content>
        <div className="gap-4 text-sm max-md:flex max-md:flex-col md:grid md:grid-cols-[auto,15rem,15rem] md:grid-rows-1 xl:grid-cols-[2fr,1fr,1fr]">
          <div>
            <ConditionalLink
              condition={isLink}
              to={`${RouteDefinition.Analytics}/process-records/${processRecord.processRecordId}`}
              className="hover:underline"
            >
              <List.Item.Description>
                <List.Item.Title>
                  <div
                    className="break-words break-all text-gray-900"
                    data-testid="ProcessRecordItem-displayName"
                  >
                    {processRecord.displayName}
                  </div>
                </List.Item.Title>
                <List.Item.Subtitle>
                  <div data-testid="ProcessRecordItem-title" className="mb-2 break-words break-all">
                    {processRecord.title}
                  </div>
                  <div data-testid="ProcessRecordComment">{processRecord.comment}</div>
                </List.Item.Subtitle>
              </List.Item.Description>
            </ConditionalLink>
          </div>

          <div className="flex flex-col flex-wrap gap-x-4 font-light">
            <div className="flex items-center gap-x-2">
              <DesktopComputerIcon className="h-4 w-4 text-accent" />
              {processRecord.deviceName}
            </div>
            <div className="flex items-center gap-x-2">
              <VesselIcon className="h-4 w-4 text-accent" />
              {processRecord.unit}
            </div>
            <div className="flex items-center gap-x-2">
              <UserIcon className="h-4 w-4 text-accent" />
              <FormattedMessage
                defaultMessage="Operator:"
                description="Process Record Operator"
                id="v8h+BE"
              />
              &nbsp;
              {processRecord.operator}
            </div>
            <div className="flex items-center gap-x-2">
              <CloudIcon className="h-4 w-4 text-accent" />
              <FormattedMessage
                defaultMessage="Upload:"
                description="Process Record Upload Date"
                id="tWCIrb"
              />
              &nbsp;
              <div data-testid="ProcessRecordItem-uploadTime">
                <FormattedDate value={processRecord.uploadTimestamp} /> &nbsp;
                <FormattedTime value={processRecord.uploadTimestamp} />
              </div>
              &nbsp;
            </div>
          </div>

          <div>
            <div className="flex items-center gap-x-2">
              <ChevronLeftIcon className="h-4 w-4 text-accent" />
              <FormattedMessage
                defaultMessage="Start:"
                description="Process Record Start Date"
                id="I8EceJ"
              />
              &nbsp;
              <div data-testid="ProcessRecordItem-startTimestamp">
                <FormattedDate value={processRecord.startTimestamp} /> &nbsp;
                <FormattedTime value={processRecord.startTimestamp} />
              </div>
              &nbsp;
            </div>

            <div className="flex items-center gap-x-2">
              <ChevronRightIcon className="h-4 w-4 text-accent" />
              <FormattedMessage
                defaultMessage="End:"
                description="Process Record End Date"
                id="2zppjm"
              />
              &nbsp;
              <div data-testid="ProcessRecordItem-stopTimestamp">
                <FormattedDate value={processRecord.stopTimestamp} /> &nbsp;
                <FormattedTime value={processRecord.stopTimestamp} />
              </div>
              &nbsp;
            </div>
          </div>
        </div>
      </List.Item.Content>
    </List.Item>
  );
}
