import React, { FunctionComponentElement } from 'react';

import { useDataTracksFromTimestamp } from '../../../../common/hooks/use-data-tracks-from-timestamp';
import DataPointCreateModal from '../../../../../shared/components/data-point-create-modal';
import useAddDataPoints from '../../../../common/hooks/use-add-data-points';
import { DataPoint } from '../../../../../shared/components/data-point-create-modal/data-point-create-modal.definitions';
import useLogger from '../../../../../shared/common/hooks/use-logger/use-logger';
import TrackedEvent from '../../../../../shared/common/tracked-event';

import { AddEditDataPointsProps } from './add-edit-data-points.definition';
import {
  formatDataPoints,
  getDataPoints,
  handleAddDataPointsSuccess,
} from './add-edit-data-points.helpers';

function AddEditDataPoints({
  setupId,
  controlProcedureId,
  dataTrack,
  open,
  updateDataTrack,
  onChangeModal,
  isSelected,
}: AddEditDataPointsProps): FunctionComponentElement<AddEditDataPointsProps> {
  const logger = useLogger();

  const { dataTrackId } = dataTrack;
  const { data: dataTrackDataPoints, isFetching: isLoadingDataPoints } = useDataTracksFromTimestamp(
    controlProcedureId,
    [{ id: dataTrackId }],
    setupId,
  );

  const {
    mutate: addDataTrackDataPoints,
    isPending,
    isError: isAddDataPointsError,
  } = useAddDataPoints({
    controlProcedureId,
    setupId,
  });

  const dataPoints = getDataPoints(dataTrackId, dataTrackDataPoints);

  const addDataPoints = (newDataPoints: DataPoint[]) => {
    const dataTrackPoints = formatDataPoints(newDataPoints);
    addDataTrackDataPoints([{ dataTrackId, dataPoints: dataTrackPoints }], {
      onSuccess: () => {
        handleAddDataPointsSuccess(
          dataTrackId,
          isSelected,
          newDataPoints,
          updateDataTrack,
          onChangeModal,
        );
      },
    });

    logger.trackEvent(TrackedEvent.AddDataPointsToCustomDataTrack);
  };

  return (
    <DataPointCreateModal
      dataPoints={dataPoints}
      dataTrack={dataTrack}
      open={open}
      onChangeModal={onChangeModal}
      isLoading={isLoadingDataPoints}
      addDataPoints={addDataPoints}
      isPending={isPending}
      isAddDataPointsError={isAddDataPointsError}
    />
  );
}

export default AddEditDataPoints;
