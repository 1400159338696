import React, { FunctionComponentElement } from 'react';

import EventListSkeleton from '../../../../shared/components/event-list/event-list.skeleton';

import { SetupEventsProps } from './setup-events.definitions';
import SetupEventList from './setup-event-list';

function SetupEvents({
  systemId,
  startTimestamp,
  autoUpdate,
  unit,
  stopTimestamp,
  isLoadingSetup,
}: SetupEventsProps): FunctionComponentElement<SetupEventsProps> {
  return (
    <>
      {isLoadingSetup && <EventListSkeleton />}

      {unit && systemId && startTimestamp && (
        <SetupEventList
          autoUpdate={autoUpdate}
          unit={unit}
          systemId={systemId}
          startTimestamp={startTimestamp}
          stopTimestamp={stopTimestamp}
          isLoadingSetup={isLoadingSetup}
        />
      )}
    </>
  );
}

export default SetupEvents;
