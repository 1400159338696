import { useQueries, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import { ProcessRecordId, ProcessRecordObject } from '../../types/process-record';
import QKey from '../keys';

import { fetchProcessRecord } from './use-multiple-process-records.helpers';

export default function useMultipleProcessRecords(
  ids: ProcessRecordId[],
): UseQueryResult<ProcessRecordObject, Error>[] {
  const { acquireAccessToken } = useAuthentication();

  return useQueries({
    queries: ids.map<UseQueryOptions<ProcessRecordObject, Error>>((id) => ({
      queryKey: [QKey.PROCESS_RECORDS, id],
      queryFn: async () => {
        const accessToken = await acquireAccessToken();
        return fetchProcessRecord(id, accessToken);
      },
    })),
  });
}
