import {
  DataTrack,
  ProcessRecordAttributes,
  ProcessRecordId,
} from '../../../shared/common/types/process-record';
import { ProcessRecordOptimizationVariable } from '../../common/types';

export type RecipeOptimizationTransferTileProps = {
  processRecordId: ProcessRecordId;
  dataTracks: DataTrack[];
  attributes: ProcessRecordAttributes;

  /** the previous mapping data */
  mappings: ProcessRecordOptimizationVariable[] | undefined;

  /** whether the mappings are being loaded */
  isLoading?: boolean;

  /** whether the mappings failed to load */
  isError?: boolean;

  /** used in tests only */
  selectVariableDefaultOpen?: boolean;
};

/**
 * map used to rename variableCodes to more readable identifiers
 */
export const VARIABLE_RENAME_MAP = new Map([
  ['VCD', 'Viable Cell Density'],
  ['Volume', 'Initial Volume'],
]);
