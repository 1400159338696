import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import QKey from '../../../../shared/common/hooks/keys';
import { SetupDescriptor } from '../../types';
import { SETUPS_INTERVAL } from '../../types/setup';

import { fetchSetupList } from './use-setup-list.helpers';

export default function useSetupList(autoUpdate = false): UseQueryResult<SetupDescriptor[]> {
  const { acquireAccessToken } = useAuthentication();

  return useQuery({
    queryKey: [QKey.SETUPS],
    refetchInterval: autoUpdate ? SETUPS_INTERVAL : false,
    refetchIntervalInBackground: autoUpdate,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const accessToken = await acquireAccessToken();
      return fetchSetupList(accessToken);
    },
  });
}
