import { projectConfigInstance } from '../../../globals/project-config';
import { joinPaths } from '../../../utils';
import fetchData from '../../fetch-data';
import { HttpMethods } from '../../types/http';
import { DataTrackId, ProcessRecordId } from '../../types/process-record';

export function createDeleteDataTrackUrl(
  processRecordId: ProcessRecordId,
  dataTrackId: DataTrackId,
) {
  return joinPaths(
    projectConfigInstance.get('PRS_BACKEND_URL'),
    'process-records',
    processRecordId,
    'data-tracks',
    dataTrackId,
  );
}

export async function deleteDataTrack(
  processRecordId: ProcessRecordId,
  dataTrackId: DataTrackId,
  accessToken: string,
): Promise<void> {
  await fetchData(createDeleteDataTrackUrl(processRecordId, dataTrackId), {
    method: HttpMethods.Delete,
    headers: {
      Accept: 'application/json',
      'Accept-Encoding': 'gzip, deflate, br',
      Authorization: `Bearer ${accessToken}`,
    },
  });
}
