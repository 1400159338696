import React, { useState, FunctionComponentElement } from 'react';
import { ActivityIndicator, Modal, NotificationMessage, PlusIcon } from '@biss/react-horizon-web';
import { FormattedMessage } from 'react-intl';

import { FRACTIONAL_DIGITS } from '../../common/types/process-record/process-record';

import {
  DataTrackCreateModalProps,
  DataTrackCustomForm,
} from './data-track-create-modal.definitions';
import { ModalContent } from './modal-content';
import { validate } from './metadata-form/metadata-form.validation';

export const newDataTrack: DataTrackCustomForm = {
  displayName: '',
  dataTrackType: '',
  engineeringUnit: '',
  fractionalDigits: FRACTIONAL_DIGITS,
  isEdited: false,
};

function DataTrackCreateModal({
  trigger,
  defaultOpen = false,
  dataTrackTypes,
  isDisabled,
  isPending,
  isError,
  isOpen,
  setIsOpen,
  onSave,
}: DataTrackCreateModalProps): FunctionComponentElement<DataTrackCreateModalProps> {
  const [dataTracks, setDataTracks] = useState<DataTrackCustomForm[]>([newDataTrack]);
  const addDataTrack = () => {
    setDataTracks([...dataTracks, newDataTrack]);
  };
  const localTypes = dataTracks.map((dt) => dt.dataTrackType);

  const onChange = (data: DataTrackCustomForm, index: number) => {
    const newDataTracks = [...dataTracks];
    newDataTracks[index] = { ...data, isEdited: true };

    setDataTracks(newDataTracks);
  };

  const isValid = dataTracks.every((track) => validate(track, localTypes, dataTrackTypes).isValid);

  const onSaveDataTracks = () => {
    if (isValid) {
      const customDataTracks = dataTracks.map((item) => {
        const track = { ...item, displayName: item.dataTrackType };
        delete track.isEdited;
        return track;
      });
      onSave(customDataTracks);
    }
  };

  const onCancel = () => {
    setDataTracks([newDataTrack]);
  };

  const onOpenChange = (open: boolean) => {
    setIsOpen(open);
    setDataTracks([newDataTrack]);
  };

  return (
    <Modal
      defaultOpen={defaultOpen}
      open={isOpen}
      trigger={trigger}
      onOpenChange={onOpenChange}
      title={
        <FormattedMessage
          defaultMessage="Create Data Tracks"
          id="T936fJ"
          description="Create Data Tracks Title"
        />
      }
    >
      <Modal.Content>
        {isError && (
          <div className="mb-6">
            <NotificationMessage status="error">
              <FormattedMessage
                defaultMessage="An internal error occurred while saving the data tracks. Please wait a moment and try again."
                id="SW/unD"
                description="Create Data Tracks Error message"
              />
            </NotificationMessage>
          </div>
        )}
        <ModalContent
          dataTracks={dataTracks}
          takenTypes={dataTrackTypes}
          localTypes={localTypes}
          addDataTrack={addDataTrack}
          onChange={onChange}
          isDisabled={isDisabled}
        />
      </Modal.Content>
      <Modal.ButtonGroup>
        <Modal.Close asChild role="button">
          <Modal.Button data-testid="cancelDataTrackButton" onClick={onCancel}>
            <FormattedMessage
              description="Create Data Tracks: label for cancel button"
              defaultMessage="Cancel"
              id="+U8CTC"
            />
          </Modal.Button>
        </Modal.Close>

        <Modal.Button
          disabled={!isValid || isDisabled}
          variant="highlight"
          onClick={onSaveDataTracks}
          data-testid="createDataTrackButton"
          leadIcon={<PlusIcon />}
          tailIcon={isPending && <ActivityIndicator size="sm" />}
        >
          <FormattedMessage
            description="Create Data Tracks: label for Create Data Tracks button"
            defaultMessage="Create Data Tracks"
            id="ezoyGI"
          />
        </Modal.Button>
      </Modal.ButtonGroup>
    </Modal>
  );
}

export default DataTrackCreateModal;
