import { ProcessRecordDescriptor, ProcessRecordObject } from '..';

import { ProcessRecordDescriptorDTO, ProcessRecordObjectDTO } from './process-record-dto';

export function transformProcessRecordDescriptorDTO(
  dto: ProcessRecordDescriptorDTO,
): ProcessRecordDescriptor {
  return {
    ...dto,
    startTimestamp: new Date(dto.startTimestamp),
    stopTimestamp: new Date(dto.stopTimestamp),
    uploadTimestamp: new Date(dto.uploadTimestamp),
    inoculationTimestamp: dto.inoculationTimestamp ? new Date(dto.inoculationTimestamp) : undefined,
  };
}

export function transformProcessRecordObjectDTO(dto: ProcessRecordObjectDTO): ProcessRecordObject {
  const descriptor = transformProcessRecordDescriptorDTO(dto);

  return {
    ...dto,
    ...descriptor,
    // disambiguation
    inoculationTimestamp: descriptor.inoculationTimestamp,
  };
}
