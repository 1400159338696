import { ReactNode } from 'react';

import { ConsentContext } from './consent-globals';

export type ConsentProps = {
  children: (props: ConsentContext) => ReactNode;
  env: string;
};

/**
 * the consent settings used when no cookie is present on the users browser
 * or the user has not consented to anything yet
 */
export const DEFAULT_CONSENT_CONTEXT = {
  necessary: true,
  performance: false,
  functional: false,
} satisfies ConsentContext;

/**
 * key of the cookie set by one trust containing information about the accepted or declined cookies
 */
export const ONE_TRUST_CONSENT_COOKIE_KEY = 'OptanonConsent';

/**
 * key of the cookie set by one trust containing information about the cookie banner
 */
export const ONE_TRUST_DIALOG_CLOSED_COOKIE_KEY = 'OptanonAlertBoxClosed';

/**
 * key within the {@link ONE_TRUST_CONSENT_COOKIE_KEY} cookie that denotes whether the user has consented to the storage of strictly necessary cookies
 * */
export const NECESSARY_COOKIES_KEY = 'C0001';

/**
 * key within the {@link ONE_TRUST_CONSENT_COOKIE_KEY} cookie that denotes whether the user has consented to the storage of performance cookies
 * */
export const PERFORMANCE_COOKIES_KEY = 'C0002';

/**
 * key within the {@link ONE_TRUST_CONSENT_COOKIE_KEY} cookie that denotes whether the user has consented to the storage of functional cookies
 * */
export const FUNCTIONAL_COOKIES_KEY = 'C0003';

/**
 * the cookie banner will be closed with all the cookies accepted except for this environment
 */
export const ONE_TRUST_ENVIRONMENT = 'beta';
