import React from 'react';

import SetupOverviewTimespan from '../setup-overview-timespan/setup-overview-timespan';
import SetupOverviewFilter from '../setup-overview-filter';

import { SetupOverviewConfigurationProps } from './setup-overview-configuration.definitions';

function SetupOverviewConfiguration({
  timeSpan,
  setTimeSpan,
  dataTracks,
  filterDataTracks,
  selectDataTracks,
  toggleDataTrack,
  removeDataTrack,
}: SetupOverviewConfigurationProps): React.FunctionComponentElement<SetupOverviewConfigurationProps> {
  return (
    <>
      <SetupOverviewTimespan timeSpan={timeSpan} setTimeSpan={setTimeSpan} />
      <SetupOverviewFilter
        dataTracks={dataTracks}
        filterDataTracks={filterDataTracks}
        selectDataTracks={selectDataTracks}
        toggleDataTrack={toggleDataTrack}
        removeDataTrack={removeDataTrack}
      />
    </>
  );
}

export default SetupOverviewConfiguration;
