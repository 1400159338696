import React, { FunctionComponentElement } from 'react';

import Tile from '../../../../components/tile';
import DataTrackValue from '../../../../components/data-track-value';

import { ProcessValueTileProps } from './process-value-tile.definitions';
import ProcessValueIndicator from './process-value-indicator';

function ProcessValueTile({
  processValue,
  processValueType,
  isDisconnected,
}: ProcessValueTileProps): FunctionComponentElement<ProcessValueTileProps> {
  const variant = isDisconnected ? 'error' : 'default';
  return (
    <Tile color={processValue.color} variant={variant} testId="process-value-tile">
      <div className="flex w-full flex-col text-lg font-bold text-secondary">
        <div className="flex w-full flex-row justify-between">
          <div data-testid="process-value-type">{processValueType}</div>
          {isDisconnected && <ProcessValueIndicator />}
        </div>
        <div className="flex items-end justify-between">
          <span>
            {processValue.setPoint && (
              <DataTrackValue
                value={processValue.setPoint?.value}
                engineeringUnit={processValue.setPoint?.engineeringUnit}
                fractionalDigits={processValue.setPoint?.fractionalDigits}
              />
            )}
          </span>
          <span
            className={`text-3xl font-bold ${isDisconnected ? 'text-secondary' : 'text-primary'}`}
          >
            {processValue.processValue && (
              <DataTrackValue
                value={processValue.processValue?.value}
                engineeringUnit={processValue.processValue?.engineeringUnit}
                fractionalDigits={processValue.processValue?.fractionalDigits}
              />
            )}
          </span>
        </div>
      </div>
    </Tile>
  );
}

export default ProcessValueTile;
