import { UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import QKey from '../../../../shared/common/hooks/keys';
import { Setup } from '../../types';
import { ControlProcedureId, SetupId } from '../../types/setup';
import { DataTrackId } from '../../../../shared/common/types/process-record';

import { ApiError } from '../../../../shared/common/types/api-error/api-error';

import { deleteDataTrack } from './use-delete-data-track.helpers';

function useDeleteDataTrack(
  controlProcedureId: ControlProcedureId,
  setupId: SetupId,
  { onSuccess, ...options }: UseMutationOptions<unknown, ApiError, DataTrackId, unknown> = {},
) {
  const queryClient = useQueryClient();
  const sourceQueryKey = [QKey.SETUPS, setupId];
  const { acquireAccessToken } = useAuthentication();

  return useMutation({
    ...options,
    mutationFn: async (dataTrackId: DataTrackId) => {
      const accessToken = await acquireAccessToken();
      return deleteDataTrack(controlProcedureId, setupId, dataTrackId, accessToken);
    },
    onMutate: (dataTrackId: DataTrackId) => {
      const previousSetupData = queryClient.getQueryData<Setup>(sourceQueryKey);

      if (!previousSetupData) {
        throw new Error(`Expected query with key ${JSON.stringify(sourceQueryKey)} to exist.`);
      }

      queryClient.setQueryData<Setup>(sourceQueryKey, {
        ...previousSetupData,
        dataTracks: previousSetupData.dataTracks.filter((dt) => dt.dataTrackId !== dataTrackId),
      });

      return previousSetupData;
    },
    onError: (_, __, previous) => {
      queryClient.setQueryData(sourceQueryKey, previous);
    },
    onSuccess(data, variables, context) {
      onSuccess?.(data, variables, context);
    },
  });
}

export default useDeleteDataTrack;
