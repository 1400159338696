import { DataTrackType } from '../../../../../shared/common/types/process-record';
import {
  DataTrackDescriptor,
  DataTrackId,
} from '../../../../../shared/common/types/process-record/data-track';
import { SetupDescriptor } from '../../../../common/types';
import {
  DataTrackFromTimestamp,
  DataTrackLastTimestamp,
} from '../../../../common/types/data-track';
import { TimeSpan } from '../../../../common/types/setup';

export const LINE_WIDTH = 1.5;
export const SETUP_TIME_IN_MINUTES = 5;
export const MONITORING_TIME_SPAN = 24;

export interface SetupOverviewItemProps {
  setupItem: SetupDescriptor;
  selectedDataTracks: Record<DataTrackType, string>;
  lastDataPoints?: DataTrackFromTimestamp[];
  isLoadingSetups: boolean;
  isIdle: boolean;
  timeSpan: TimeSpan;
  updateDataTracksTimestamp: (setupId: string, tracks: DataTrackLastTimestamp[]) => void;
  deleteDataTracksTimestamp: (setupId: string) => void;
  updateDataTrackList: (dataTracks: DataTrackDescriptor[]) => void;
  setIsLoadingSetups: (value: boolean) => void;
  addIdleSetup: (setupId: DataTrackId) => void;
  removeIdleSetup: (setupId: DataTrackId) => void;
}

export type SetupStatus = 'default' | 'finished';
