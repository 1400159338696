/**
 * This module was automatically generated by `ts-interface-builder`
 */
import { iface, ITypeSuite, opt, name, array, union, lit } from 'ts-interface-checker';

export const DataPointObject = iface([], {
  ts: 'number',
  v: 'number',
});

export const DataTrackId = name('string');
export const DataTrackType = name('string');

export const DataTrackDescriptor = iface([], {
  dataTrackId: 'DataTrackId',
  dataTrackType: 'DataTrackType',
  displayName: 'string',
  engineeringUnit: 'string',
  fractionalDigits: opt('number'),
  isCustom: opt('boolean'),
});

export const DataTrack = iface(['DataTrackDescriptor'], {
  dataPoints: array('DataPointObject'),
});

export const DataTrackFromTimestamp = iface([], {
  dataTrackId: 'DataTrackId',
  dataPoints: array('DataPointObject'),
});

const SetupId = name('string');

export const SetupDescriptor = iface([], {
  setupId: 'SetupId',
  workflowId: 'string',
  controlProcedureId: 'string',
  title: 'string',
  deviceName: 'string',
  unit: 'string',
  startTimestamp: 'string',
  stopTimestamp: opt('string'),
  inoculuationTimestamp: opt('string'),
  connectionState: opt(union(lit('Connected'), lit('Disconnected'), lit('Unknown'))),
});

export const Setup = iface(['SetupDescriptor'], {
  dataTracks: array('DataTrackDescriptor'),
  includedDataTracks: opt(array('DataTrack')),
});

const Uuid = name('string');
const Timestamp = name('string');

const SetupEvent = iface([], {
  id: 'Uuid',
  eventType: union(lit('Single'), lit('Coming'), lit('Going'), lit('Cyclic')),
  message: 'string',
  timestamp: 'Timestamp',
  logicalReferenceComment: 'string',
  physicalReferenceComment: 'string',
  level: union(
    lit('Unknown'),
    lit('Debug'),
    lit('Info'),
    lit('Warn'),
    lit('Error'),
    lit('Fatal'),
    lit('Notice'),
    lit('Alarm'),
  ),
  applicationName: opt('string'),
  actorName: 'string',
});

const exportedTypeSuite: ITypeSuite = {
  DataPointObject,
  DataTrackId,
  DataTrackType,
  DataTrackDescriptor,
  DataTrack,
  DataTrackFromTimestamp,
  SetupId,
  SetupDescriptor,
  Setup,
  SetupEvent,
  Uuid,
  Timestamp,
};

export default exportedTypeSuite;
