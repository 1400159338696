import { useQuery } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import { ProcessRecordId } from '../../../../shared/common/types/process-record';
import QKey from '../../../../shared/common/hooks/keys';

import { getMappedVariables } from './use-process-record-optimization-variables.helpers';

export default function useProcessRecordOptimizationVariables(id: ProcessRecordId, enabled = true) {
  const { acquireAccessToken } = useAuthentication();

  return useQuery({
    queryKey: [QKey.RECIPE_OPTIMIZATION, id],

    queryFn: async () => {
      const accessToken = await acquireAccessToken();
      return getMappedVariables(id, accessToken);
    },

    enabled,
  });
}
