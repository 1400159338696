import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import {
  ProcessRecordId,
  ProcessRecordObject,
} from '../../../../shared/common/types/process-record';
import QKey from '../../../../shared/common/hooks/keys';

import { fetchProcessRecord } from '../../../../shared/common/hooks/use-multiple-process-records/use-multiple-process-records.helpers';

function useProcessRecord(id: ProcessRecordId): UseQueryResult<ProcessRecordObject, Error> {
  const { acquireAccessToken } = useAuthentication();

  return useQuery({
    queryKey: [QKey.PROCESS_RECORDS, id],
    queryFn: async () => {
      const accessToken = await acquireAccessToken();
      return fetchProcessRecord(id, accessToken);
    },
    enabled: !!id,
  });
}
export default useProcessRecord;
