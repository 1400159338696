import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import QKey from '../../../../shared/common/hooks/keys';
import { SetupDescriptor } from '../../types';

import { deleteWorkflow } from './use-delete-workflow.helpers';

function useDeleteWorkflow(controlProcedureId: string) {
  /** query key of the list of setups */
  const sourceQueryKey = [QKey.SETUPS];

  const queryClient = useQueryClient();

  const { acquireAccessToken } = useAuthentication();

  return useMutation({
    mutationFn: async () => {
      const accessToken = await acquireAccessToken();
      return deleteWorkflow(controlProcedureId, accessToken);
    },
    onMutate: () => {
      const previousSetupsData = queryClient.getQueryData<SetupDescriptor[]>(sourceQueryKey);

      if (!previousSetupsData) {
        throw new Error(`Expected query with key ${JSON.stringify(sourceQueryKey)} to exist.`);
      }

      queryClient.setQueryData<SetupDescriptor[]>(sourceQueryKey, [
        ...previousSetupsData.filter((setup) => setup.controlProcedureId !== controlProcedureId),
      ]);

      return previousSetupsData;
    },
    onError: (_, __, previous) => {
      queryClient.setQueryData(sourceQueryKey, previous);
    },
  });
}

export default useDeleteWorkflow;
