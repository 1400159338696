import { createCheckers } from 'ts-interface-checker';

import { DataTrackFromTimestamp as DataTrackFromTimestampDTO } from '../../data-track';
import { DataTrack as DataTrackDTO } from '../../../../../shared/common/types/process-record/data-track';

import exportedTypeSuite from './setup-dto-validator-types';

export function isDataTrackDTO(object: unknown): asserts object is DataTrackDTO {
  const { DataTrack } = createCheckers(exportedTypeSuite);
  // throws an error when object is not in the correct format
  DataTrack.check(object);
}

export function isDataTrackFromTimestampDTO(
  object: unknown,
): asserts object is DataTrackFromTimestampDTO {
  const { DataTrackFromTimestamp } = createCheckers(exportedTypeSuite);

  // throws an error when object is not in the correct format
  DataTrackFromTimestamp.check(object);
}
