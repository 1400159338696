import { palettes } from '@biss/react-horizon-web';

import { DataPointObject, DataTrack } from '../../shared/common/types/process-record';

import { DataTrackFromTimestamp, DataTrackLastTimestamp } from './types/data-track';

export const SETUP_TIME_IN_MINUTES = 5;

export const MINUTES = 60;
export const SECONDS = 60;
export const MILLISECONDS = 1000;

export const MILLISECONDS_IN_HOUR = MINUTES * SECONDS * MILLISECONDS;
export function filterColors(colors: string[], colorsFilter: string[]) {
  return colors.filter((item) => !colorsFilter.includes(item));
}

export function getGraphColors() {
  return Object.keys(palettes.categorical_unique);
}

export function filterAndSortDataPoints(dataPoints: DataPointObject[]) {
  const timestamps = dataPoints.map(({ ts }) => ts);
  const filtered = dataPoints.filter(({ ts }, index) => !timestamps.includes(ts, index + 1));
  filtered.sort((a, b) => a.ts - b.ts);
  return filtered;
}

export function isPrevDataPoints(dataPoints: DataPointObject[], dataPointsLast: DataPointObject[]) {
  if (!dataPointsLast.length || !dataPoints.length) {
    return false;
  }
  return dataPointsLast[0].ts <= dataPoints[dataPoints.length - 1].ts;
}

export function combineDataPoints(
  dataTrackList: DataTrack[],
  lastDataPoints?: DataTrackFromTimestamp[],
): DataTrack[] {
  if (!lastDataPoints?.length) {
    return dataTrackList;
  }
  return dataTrackList.map((track) => {
    const trackData = lastDataPoints.find((item) => track.dataTrackId === item.dataTrackId);
    if (trackData) {
      const dataPointsCombined = [...track.dataPoints, ...trackData.dataPoints];
      const dataPoints = isPrevDataPoints(track.dataPoints, trackData.dataPoints)
        ? filterAndSortDataPoints(dataPointsCombined)
        : dataPointsCombined;
      return {
        ...track,
        dataPoints,
      };
    }
    return track;
  });
}

// returns true if less than 5 minutes have passed since the setup started
export function getIsSetupSetting(date?: Date) {
  const seconds = 60;
  const milliseconds = 1000;
  if (!date) {
    return false;
  }
  const now = new Date();
  const setupTimeInMiliseconds = SETUP_TIME_IN_MINUTES * seconds * milliseconds;
  const timeDifference = now.getTime() - date.getTime();
  return timeDifference < setupTimeInMiliseconds;
}

export function isFetched(isFetching: boolean, isFetchingPrev: boolean) {
  return !isFetching && isFetchingPrev;
}

export function getLastTimestamp(dataTracks: DataTrackFromTimestamp[]): number | undefined {
  const timestamps = dataTracks.reduce((timestamp: number[], track: DataTrackFromTimestamp) => {
    if (track.dataPoints.length) {
      timestamp.push(track.dataPoints[track.dataPoints.length - 1].ts);
    }
    return timestamp;
  }, []);

  return timestamps.length ? Math.max(...timestamps) : undefined;
}

export function getFirstTimestamp(dataTracks: DataTrackFromTimestamp[]): number {
  const timestamps = dataTracks.map((track: DataTrackFromTimestamp) => track.dataPoints[0].ts);
  return Math.min(...timestamps);
}

export function isSameFirstDataPoint(firstTimestamp: number, firstTimestampPrev?: number | null) {
  return firstTimestampPrev && firstTimestamp === firstTimestampPrev;
}

export function isStartGapRecovery(isDisconnected: boolean, isDisconnectedPrev: boolean) {
  return isDisconnected === false && isDisconnectedPrev === true;
}

export function getGapRecoveryTimestamp(
  dataTracksTimetamps: DataTrackLastTimestamp[],
): string | null {
  const timestamps: number[] = [];
  dataTracksTimetamps.forEach((track: DataTrackLastTimestamp) => {
    if (track.timestamp) {
      timestamps.push(new Date(track.timestamp).getTime());
    }
  }, []);
  return timestamps.length ? new Date(Math.min(...timestamps)).toISOString() : null;
}

export function getDataTrackLastTimestamp(
  dataTracksTimestamp: DataTrackLastTimestamp[],
  timestamp?: string | null,
): DataTrackLastTimestamp[] {
  if (timestamp) {
    return dataTracksTimestamp.map((track: DataTrackLastTimestamp) => ({
      ...track,
      timestamp,
    }));
  }
  return dataTracksTimestamp;
}

export function subtractHours(date: Date, hours: number) {
  const dateToMilliseconds = date.getTime();
  const hoursToMilliseconds = hours * MILLISECONDS_IN_HOUR;
  return new Date(dateToMilliseconds - hoursToMilliseconds);
}
