import { UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import QKey from '../../../../shared/common/hooks/keys';
import { ApiError } from '../../../../shared/common/types/api-error/api-error';
import { ControlProcedureId, Setup, SetupId } from '../../types/setup';

import { DataTrackCustom } from '../../../../shared/components/data-track-create-modal/data-track-create-modal.definitions';

import { saveDataTracks } from './use-save-data-tracks.helpers';

function useSaveDataTracks(
  setupId: SetupId,
  {
    onSuccess,
    ...options
  }: UseMutationOptions<
    unknown,
    ApiError,
    { dataTracks: DataTrackCustom[]; controlProcedureId: ControlProcedureId },
    unknown
  > = {},
) {
  const { acquireAccessToken } = useAuthentication();
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    async mutationFn({ dataTracks, controlProcedureId }) {
      const token = await acquireAccessToken();
      return saveDataTracks(controlProcedureId, setupId, dataTracks, token);
    },
    mutationKey: [QKey.CUSTOM_DATA_TRACKS, setupId],
    onSuccess(data, variables, context) {
      // update cache
      queryClient.setQueryData<Setup>([QKey.SETUPS, setupId], (prev) => {
        if (!prev) {
          return undefined;
        }

        return {
          ...prev,
          dataTracks: [...prev.dataTracks, ...data],
        };
      });

      onSuccess?.(data, variables, context);
    },
  });
}

export default useSaveDataTracks;
