import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, DeleteIcon, Select } from '@biss/react-horizon-web';

import { VARIABLE_RENAME_MAP } from '../recipe-optimization-transfer.definitions';

import { DataTrackMappingProps, DataTrackVariableMapPair } from './data-track-mapping.definitions';

function DataTrackMapping({
  dataTracks,
  mappings: currentDatatrackMappings,
  onUpdate,
  allowDeletion = false,
  onDelete,
  disabled,
  defaultOpen = false,
}: Readonly<DataTrackMappingProps>) {
  /**
   * when a datatrack is selected
   * check if the variable was mapped before
   * if so update the mapping, otherwise add the variable to the mappings and call the endpoint
   * @param mappableKey - variable code
   * @param id - datatrack id
   */
  const handleValueChange = (mappableKey: string, id: string) => {
    // attempt to get previous mapping
    const previousMapIndex = currentDatatrackMappings.findIndex(
      (entry) => entry.variableCode === mappableKey,
    );

    const newMapping: DataTrackVariableMapPair = {
      dataTrackId: id,
      variableCode: mappableKey,
    };

    const newMappings: DataTrackVariableMapPair[] = [...currentDatatrackMappings];
    if (previousMapIndex >= 0) {
      // update previous mapping
      newMappings[previousMapIndex] = newMapping;
    } else {
      // add new mapping
      newMappings.push(newMapping);
    }

    onUpdate(newMappings);
  };

  return currentDatatrackMappings.map(({ variableCode, dataTrackId }) => (
    <div className="flex flex-col gap-2" key={variableCode}>
      <span>
        <FormattedMessage
          defaultMessage="{label}"
          description="Mappable Datatrack Label"
          id="NUY1/G"
          values={{ label: VARIABLE_RENAME_MAP.get(variableCode) ?? variableCode }}
        />
      </span>

      <div className="flex flex-row gap-2">
        <div className="flex-auto">
          <Select
            expand="auto"
            disabled={disabled}
            placeholder={`Select "${variableCode}" Data Track`}
            onValueChange={(id) => handleValueChange(variableCode, id)}
            value={dataTrackId ?? undefined}
            data-testid="select-datatrack-mapping"
            defaultOpen={defaultOpen}
          >
            {dataTracks.map((dataTrack) => (
              <Select.Item key={dataTrack.dataTrackId} value={dataTrack.dataTrackId}>
                {dataTrack.dataTrackType}
                {dataTrack.engineeringUnit && (
                  <FormattedMessage
                    description="data track list item: engineering unit"
                    defaultMessage=" ({engineeringUnit})"
                    values={{
                      engineeringUnit: dataTrack.engineeringUnit,
                    }}
                    id="pHlDa4"
                  />
                )}
              </Select.Item>
            ))}
          </Select>
        </div>

        {allowDeletion && (
          <Button
            data-testid="remove-datatrack-mapping"
            mood="destructive"
            leftIcon={<DeleteIcon />}
            onClick={() => onDelete?.(variableCode)}
          />
        )}
      </div>
    </div>
  ));
}

export default DataTrackMapping;
