import { DataTrackId } from '../../../../../shared/common/types/process-record';
import { DataPoint } from '../../../../../shared/components/data-point-create-modal/data-point-create-modal.definitions';
import { DataPointCustom, DataTrackFromTimestamp } from '../../../../common/types/data-track';

export function formatDataPoints(dataPoints: DataPoint[]): DataPointCustom[] {
  return dataPoints.map((item) => ({
    timestamp: new Date(item.ts).toISOString(),
    value: +item.v,
  }));
}

export function handleAddDataPointsSuccess(
  dataTrackId: DataTrackId,
  isSelected: boolean,
  dataPoints: DataPoint[],
  updateDataTrack: (dataTrack: DataTrackFromTimestamp[]) => void,
  onChangeModal: (open: boolean) => void,
) {
  if (isSelected) {
    const points = dataPoints.map((item) => ({
      ts: new Date(item.ts).getTime(),
      v: +item.v,
    }));
    // update selectd custom data track with new data points
    updateDataTrack([{ dataTrackId, dataPoints: points }]);
  }
  onChangeModal(false);
}

export function getDataPoints(dataTrackId: DataTrackId, dataTracks?: DataTrackFromTimestamp[]) {
  const dataTrack = dataTracks?.find((item) => item.dataTrackId === dataTrackId);
  return dataTrack ? dataTrack.dataPoints : [];
}
