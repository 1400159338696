/**
 * App config feature keys
 */
const enum FKey {
  // monitoring-detail-view
  MONITORING_DETAIL_VIEW_PROTOTYPING = 'BIOCL-2237/monitoring-detail-view-prototyping',
  MONITORING_ADD_EDIT_OFFLINE_DATA = 'rel_add-edit-offline-data',

  // process-record-visualization
  ANALYTICS_DATAHOW_LAB_INTEGRATION = 'BIOCL-2876/upload-to-datahowlab',

  ANALYTICS_DATA_TRACK_CALCULATIONS = 'rel_BIOCL-4141/DataTrack-Calulations',

  ANALYTICS_YAXIS_RANGE = 'rel_BIOCL-4222/y-axis-range',

  /** this key is only used within tests */
  TEST_KEY = 'TEST_KEY',
}

export default FKey;
