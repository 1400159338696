import fetchData from '../../fetch-data';
import { HttpMethods } from '../../types/http';
import { ProcessRecordId } from '../../types/process-record';
import { createProcessRecordUrl } from '../use-multiple-process-records/use-multiple-process-records.helpers';

export function createDeleteProcessRecordUrl(processRecordId: string) {
  return createProcessRecordUrl(processRecordId);
}

export async function deleteProcessRecord(
  processRecordId: ProcessRecordId,
  accessToken: string,
): Promise<void> {
  await fetchData(createDeleteProcessRecordUrl(processRecordId), {
    method: HttpMethods.Delete,
    headers: {
      Accept: 'application/json',
      'Accept-Encoding': 'gzip, deflate, br',
      Authorization: `Bearer ${accessToken}`,
    },
  });
}
