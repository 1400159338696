import {
  DataTrackTimeAlignment,
  MarkedDataTrack,
} from '../process-record-visualization.definitions';
import { ProcessRecordObject } from '../../../../../shared/common/types/process-record';

export const YAXIS_LABEL_DECIMALS = 2;

export interface ProcessRecordVisualizationChartProps {
  inSingleView: boolean;
  processRecordsCacheKey: string;
  timeAlignment: DataTrackTimeAlignment;

  selectedDataTrackTypes: string[];
  processRecords: ProcessRecordObject[];
  markedDataTracks: MarkedDataTrack[][];
}
