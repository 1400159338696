import { UseQueryResult, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import QKey from '../../../../shared/common/hooks/keys';
import { Uuid } from '../../../../shared/common/types/uuid';
import { Event } from '../../../../shared/common/types/event';

import { fetchSetupEvents } from './use-setup-events.helpers';

export function useSetupEvents(
  systemId: Uuid,
  timestamp: string,
  unit: string,
  stopTimestamp?: string,
): UseQueryResult<Event[], Error> {
  const { acquireAccessToken } = useAuthentication();

  return useQuery({
    queryKey: [QKey.SETUPS, unit, QKey.EVENTS],
    queryFn: async () => {
      const accessToken = await acquireAccessToken();
      return fetchSetupEvents(systemId, timestamp, accessToken, unit, stopTimestamp);
    },
    refetchOnWindowFocus: false,
    retry: false,
  });
}

export function useSetupEventsLastTimestamp() {
  const { acquireAccessToken } = useAuthentication();
  const queryClient = useQueryClient();

  const getLastEvents = async (
    systemId: Uuid,
    timestamp: string,
    unit: string,
  ): Promise<Event[]> => {
    try {
      return await queryClient.fetchQuery({
        queryKey: [unit, QKey.EVENTS],
        queryFn: async () => {
          const accessToken = await acquireAccessToken();
          return fetchSetupEvents(systemId, timestamp, accessToken, unit);
        },
        retry: false,
      });
    } catch (e) {
      return [];
    }
  };

  return { getLastEvents };
}
