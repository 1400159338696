import uniqueKeyGenerator from '../../../shared/utils/unique-key-generator';
import {
  AttributeVariable,
  DataTrackVariable,
  ProcessRecordOptimizationVariable,
} from '../../common/types';

export const predicateDatatrack = (
  variable: ProcessRecordOptimizationVariable,
): variable is DataTrackVariable => variable.type === 'dataTrack';

export const predicateAttribute = (
  variable: ProcessRecordOptimizationVariable,
): variable is AttributeVariable => variable.type === 'attribute';

export const predicateMandatory = <T extends ProcessRecordOptimizationVariable>(
  variable: T,
): variable is T & { mandatory: true } => variable.mandatory === true;

export const predicateOptional = <T extends ProcessRecordOptimizationVariable>(
  variable: T,
): variable is T & { mandatory: false } => variable.mandatory === false;

export const selectParameterKeyGenerator = uniqueKeyGenerator();
