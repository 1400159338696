import React, { Suspense } from 'react';
import { IntlProvider } from 'react-intl';
import { QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';

import queryClient from './shared/common/query-client/query-client';
import { ProjectConfigWaterfall } from './shared/globals/project-config';
import { Insights } from './shared/globals/insights';
import { Auth } from './shared/globals/auth';
import { Logging } from './shared/globals/logging';
import overrideUserAgent from './shared/utils/override-user-agent';
import { Consent } from './shared/globals/consent';
import Routes from './shared/common/routes';

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('@tanstack/react-query-devtools').then((d) => ({
    default: d.ReactQueryDevtools,
  })),
);

overrideUserAgent();
function App() {
  /**
   * The locale allows to manipulate of Unicode locales.
   * The locale identifier consists of a language identifier and extension tags (locale aspects).
   * 'en-GB' locale uses day-month-year order and the 24-hour time format
   * For more information about Intl.Locale, visit:
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Locale
   *
   * We are using `en-GB` to have the desired date/time format. When the locale becomes user con-
   * figurable or set by the browser, components that rely on the locale being `en-GB` might have
   * undesired side-effects.
   * */
  const locale = 'en-GB';

  return (
    <IntlProvider locale={locale} defaultLocale={locale}>
      <QueryClientProvider client={queryClient}>
        <ProjectConfigWaterfall>
          {(config) => (
            <Consent env={config.ENVIRONMENT}>
              {({ performance: isPerformanceCookiesAccepted }) => (
                <Insights
                  applicationInsightsInstrumentationKey={config.APPLICATION_INSIGHTS}
                  isCookieEnabled={isPerformanceCookiesAccepted}
                >
                  {(appInsights) => (
                    <Logging appInsights={appInsights}>
                      {(logger) => (
                        <BrowserRouter>
                          <Auth
                            logger={logger}
                            accountUrl={config.ACCOUNT_URL_IDP}
                            clientId={config.B2C_FRONTEND_APP_CLIENTID}
                            tenantId={config.B2C_TENANT_ID}
                          >
                            <Routes />
                          </Auth>
                        </BrowserRouter>
                      )}
                    </Logging>
                  )}
                </Insights>
              )}
            </Consent>
          )}
        </ProjectConfigWaterfall>
        {process.env.NODE_ENV === 'development' && (
          <Suspense>
            <ReactQueryDevtoolsProduction initialIsOpen={false} />
          </Suspense>
        )}
      </QueryClientProvider>
    </IntlProvider>
  );
}

export default App;
