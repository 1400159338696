import fetchData from '../../../../shared/common/fetch-data';
import { HttpMethods } from '../../../../shared/common/types/http';
import { Uuid } from '../../../../shared/common/types/uuid';
import { ProcessRecordEvent } from '../../../../shared/common/types/process-record/process-record-event';
import { isProcessRecordEvent } from '../../../../shared/common/types/process-record/dto/validators';
import { joinPaths } from '../../../../shared/utils';
import { projectConfigInstance } from '../../../../shared/globals/project-config/project-config-accessor';

export function createProcessRecordEventsUrl(processRecordId: Uuid): string {
  return joinPaths(
    projectConfigInstance.get('PRS_BACKEND_URL'),
    'process-records/',
    processRecordId,
    'events',
  );
}

export async function fetchProcessRecordEvents(
  processRecordId: Uuid,
  accessToken: string,
): Promise<ProcessRecordEvent[]> {
  const result = await fetchData(createProcessRecordEventsUrl(processRecordId), {
    method: HttpMethods.Get,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (!Array.isArray(result)) {
    throw new Error('expected to receive an array');
  }

  result.forEach((descriptor) => isProcessRecordEvent(descriptor));

  return result;
}
