import fetchData from '../../../../shared/common/fetch-data';
import { HttpMethods } from '../../../../shared/common/types/http';
import { projectConfigInstance } from '../../../../shared/globals/project-config';
import { joinPaths } from '../../../../shared/utils';

export function createControlProcedureIdleUrl(controlProcedureId: string): string {
  return joinPaths(
    projectConfigInstance.get('PMS_BACKEND_URL'),
    'control-procedures',
    controlProcedureId,
    'idle',
  );
}

export async function deleteWorkflow(controlProcedure: string, accessToken: string): Promise<void> {
  await fetchData(createControlProcedureIdleUrl(controlProcedure), {
    method: HttpMethods.Delete,
    headers: {
      Accept: 'application/json',
      'Accept-Encoding': 'gzip, deflate, br',
      Authorization: `Bearer ${accessToken}`,
    },
  });
}
