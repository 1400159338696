import { useMutation } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import fetchControlProcedureIdle from './use-workflow-idle.helpers';

function useWorkflowIdle(controlProcedureId: string, onMutated: (result: boolean) => void) {
  const { acquireAccessToken } = useAuthentication();

  return useMutation({
    mutationFn: async () => {
      const accessToken = await acquireAccessToken();
      return fetchControlProcedureIdle(controlProcedureId, accessToken);
    },
    onSuccess: (result) => {
      onMutated(result.isIdle);
    },
    onError: () => onMutated(false),
  });
}

export default useWorkflowIdle;
