import fetchData from '../../../../shared/common/fetch-data';
import { HttpMethods } from '../../../../shared/common/types/http';
import { projectConfigInstance } from '../../../../shared/globals/project-config/project-config-accessor';
import { joinPaths } from '../../../../shared/utils';
import { SetupDescriptor } from '../../types';
import { transformSetupDescriptorDTO } from '../../types/dto/setup-dto-transformer';
import { isSetupDescriptorDTO } from '../../types/dto/validators/setup-dto-validator';

export function createSetupsUrl(): string {
  return joinPaths(projectConfigInstance.get('PMS_BACKEND_URL'), 'setups');
}

export async function fetchSetupList(accessToken: string): Promise<SetupDescriptor[]> {
  const result = await fetchData(createSetupsUrl(), {
    method: HttpMethods.Get,
    headers: {
      Accept: 'application/json',
      'Accept-Encoding': 'gzip, deflate, br',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (!Array.isArray(result)) {
    throw new Error('expected to receive an array');
  }

  return result
    .filter((setup) => setup.startTimestamp)
    .map((dto) => {
      isSetupDescriptorDTO(dto);
      return transformSetupDescriptorDTO(dto);
    });
}
