import { z } from 'zod';

import fetchData from '../../../../shared/common/fetch-data';
import { HttpMethods } from '../../../../shared/common/types/http';
import { createControlProcedureIdleUrl } from '../use-delete-workflow/use-delete-workflow.helpers';

import { WorkflowIdle } from './use-workflow-idle.definitions';

function validateControlProcedureIdleResponse(response: unknown) {
  const schema = z.object({
    isIdle: z.boolean(),
  });
  const parsed = schema.safeParse(response);

  if (parsed.success) {
    return parsed.data;
  }

  throw new Error('Invalid Data');
}
async function fetchControlProcedureIdle(
  controlProcedureId: string,
  accessToken: string,
): Promise<WorkflowIdle> {
  const result = await fetchData(createControlProcedureIdleUrl(controlProcedureId), {
    method: HttpMethods.Get,
    headers: {
      Accept: 'application/json',
      'Accept-Encoding': 'gzip, deflate, br',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return validateControlProcedureIdleResponse(result);
}

export default fetchControlProcedureIdle;
