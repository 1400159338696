/**
 * This module was first automatically generated by `ts-interface-builder`
 *  and then modified manually. When the process record type changes this file
 *  has to be adjusted as well. Because there are unit tests in place using
 *  the ProcessRecordDescriptor and the ProcessRecordObject for type declarations
 *  and we use ts-node breaking changes on the interfaces will be caught.
 *
 *  TODO: this has to be revisited in BIOCL-720
 */
import { iface, name, array, opt, indexKey, ITypeSuite, union, lit } from 'ts-interface-checker';

export const DataPointObject = iface([], {
  ts: 'number',
  v: 'number',
});

export const DataTrackType = name('string');

export const DataTrack = iface([], {
  dataTrackId: 'string',
  dataTrackType: 'DataTrackType',
  displayName: 'string',
  engineeringUnit: 'string',
  dataPoints: array('DataPointObject'),
});

const ProcessRecordId = name('string');

const ProcessRecordDescriptor = iface([], {
  processRecordId: 'ProcessRecordId',
  displayName: 'string',
  uploadTimestamp: 'string',
  title: 'string',
  startTimestamp: 'string',
  stopTimestamp: 'string',
  deviceName: 'string',
  unit: 'string',
  operator: opt('string'),
  comment: opt('string'),
});

const ProcessRecordAttributes = iface([], {
  [indexKey]: 'string',
});

const ProcessRecordObject = iface(['ProcessRecordDescriptor'], {
  attributes: 'ProcessRecordAttributes',
  dataTracks: array('DataTrack'),
});

const Uuid = name('string');

const Timestamp = name('string');

const ProcessRecordEvent = iface([], {
  id: 'Uuid',
  eventType: union(lit('Single'), lit('Coming'), lit('Going'), lit('Cyclic')),
  message: 'string',
  timestamp: 'Timestamp',
  logicalReferenceComment: 'string',
  physicalReferenceComment: 'string',
  level: union(
    lit('Unknown'),
    lit('Debug'),
    lit('Info'),
    lit('Warn'),
    lit('Error'),
    lit('Fatal'),
    lit('Notice'),
    lit('Alarm'),
  ),
  applicationName: opt('string'),
  applicationVersion: opt('string'),
  actorName: 'string',
});

const exportedTypeSuite: ITypeSuite = {
  DataPointObject,
  DataTrackType,
  DataTrack,
  ProcessRecordId,
  ProcessRecordDescriptor,
  ProcessRecordAttributes,
  ProcessRecordObject,
  ProcessRecordEvent,
  Uuid,
  Timestamp,
};

export default exportedTypeSuite;
