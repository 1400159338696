import React, { useMemo } from 'react';
import { DATA_COLORS } from '@biss/react-horizon-web';

import TimeSeriesChart, {
  SeriesMarkLine,
} from '../../../../../shared/components/time-series-chart';
import {
  DataTrackTimeAlignment,
  DataTrackTypes,
} from '../process-record-visualization.definitions';
import {
  calculateRelativeTimestamps,
  getDataPointsTimestamps,
  getFinalDate,
} from '../process-record-visualization.helpers';

import useFeatureFlag from '../../../../../shared/common/hooks/use-feature-flag';

import FKey from '../../../../../shared/common/feature-keys';

import {
  getBiggestDeltaBetweenInoculationAndStopTime,
  getBiggestDeltaBetweenStartAndInoculationTime,
  getEarliestDate,
  useFormatXAxisTicks,
  useRelativeXAxisTimestamps,
} from './process-record-visualization-chart.helpers';

import {
  ProcessRecordVisualizationChartProps,
  YAXIS_LABEL_DECIMALS,
} from './process-record-visualization-chart.definitions';

function ProcessRecordVisualizationChart({
  processRecords,
  timeAlignment,
  inSingleView,
  selectedDataTrackTypes,
  markedDataTracks,
  processRecordsCacheKey,
}: ProcessRecordVisualizationChartProps) {
  const canSetYAxisRange = useFeatureFlag(FKey.ANALYTICS_YAXIS_RANGE);

  const dateFormatter = useFormatXAxisTicks();
  const relativeDateFormatter = useRelativeXAxisTimestamps();

  const xAxisFormatter =
    timeAlignment === DataTrackTimeAlignment.Absolute ? dateFormatter : relativeDateFormatter;
  const yAxisFormatter = (value: number) => parseFloat(value.toFixed(YAXIS_LABEL_DECIMALS));

  const calculateInoculationTime = (
    startTimeTimestamp?: number,
    inoculationTime?: number,
  ): number | undefined => {
    if (!inoculationTime) {
      return undefined;
    }

    if (timeAlignment === DataTrackTimeAlignment.Absolute) {
      return inoculationTime;
    }
    if (timeAlignment === DataTrackTimeAlignment.RelativeToInoculationTime) {
      return 0;
    }

    if (!startTimeTimestamp) {
      return 0;
    }

    return inoculationTime - startTimeTimestamp;
  };

  const getBiggestDeltaBetweenStartAndStartTime = () => {
    let minDelta = 0;

    processRecords.forEach((record) => {
      record.dataTracks.forEach((dataTrack) => {
        dataTrack.dataPoints.forEach((dataPoint) => {
          const delta = dataPoint.ts - record.startTimestamp.getTime();
          if (delta < minDelta) {
            minDelta = delta;
          }
        });
      });
    });

    return minDelta;
  };

  const relativeStopTime = (): number => {
    const recordDiffs = processRecords.map(
      ({ stopTimestamp, startTimestamp, inoculationTimestamp }) =>
        inoculationTimestamp && timeAlignment === DataTrackTimeAlignment.RelativeToInoculationTime
          ? stopTimestamp.getTime() - inoculationTimestamp.getTime()
          : stopTimestamp.getTime() - startTimestamp.getTime(),
    );
    recordDiffs.sort((a, b) => a - b);
    return recordDiffs.at(-1) as number;
  };

  const getBiggestDeltaBetweenStartAndStopTime = () => {
    let maxDelta = relativeStopTime();

    processRecords.forEach((record) => {
      record.dataTracks.forEach((dataTrack) => {
        dataTrack.dataPoints.forEach((dataPoint) => {
          const delta = dataPoint.ts - record.stopTimestamp.getTime();
          if (delta > maxDelta) {
            maxDelta = delta;
          }
        });
      });
    });

    return maxDelta;
  };

  const getStopTime = (): number => {
    const finalDate = getFinalDate(processRecords.map((record) => record.stopTimestamp)).getTime();

    if (timeAlignment === DataTrackTimeAlignment.Absolute) {
      return finalDate;
    }

    if (timeAlignment === DataTrackTimeAlignment.RelativeToInoculationTime) {
      return getBiggestDeltaBetweenInoculationAndStopTime(processRecords);
    }

    return getBiggestDeltaBetweenStartAndStopTime();
  };

  const getStartTime = (): number => {
    if (timeAlignment === DataTrackTimeAlignment.RelativeToStartTime) {
      // use earliest relative timestamp as start time
      return getBiggestDeltaBetweenStartAndStartTime();
    }

    if (timeAlignment === DataTrackTimeAlignment.RelativeToInoculationTime) {
      return getBiggestDeltaBetweenStartAndInoculationTime(processRecords);
    }

    return getEarliestDate(processRecords.map((record) => record.startTimestamp)).getTime();
  };

  const minTimestamp = () =>
    Math.min(getStartTime(), getStopTime(), Math.min(...getDataPointsTimestamps(processRecords)));

  const maxTimestamp = () =>
    Math.max(getStartTime(), getStopTime(), Math.min(...getDataPointsTimestamps(processRecords)));

  // prepare the datatrack data in a data structure acceptable by the chart component
  // align datatrack datapoints relative to the selected time alignment option
  const selected: DataTrackTypes = useMemo(
    () =>
      selectedDataTrackTypes.reduceRight<DataTrackTypes>(
        (selections, type) =>
          Object.assign(selections, {
            [type]: markedDataTracks
              .flat()
              .filter((dt) => dt.dataTrackType === type)
              .map((dt) => ({
                ...dt,
                // update the datapoints in the datatrack to match the relative to start time alignment
                ...(timeAlignment === DataTrackTimeAlignment.RelativeToStartTime && {
                  dataPoints: calculateRelativeTimestamps(
                    dt.processRecord.startTimestamp.getTime(),
                    dt.dataPoints,
                  ),
                }),
                // update the datapoints in the datatrack to match the relative to inoculation time alignment
                ...(dt.processRecord.inoculationTimestamp &&
                  timeAlignment === DataTrackTimeAlignment.RelativeToInoculationTime && {
                    dataPoints: calculateRelativeTimestamps(
                      dt.processRecord.inoculationTimestamp.getTime(),
                      dt.dataPoints,
                    ).map((dataPoint) => ({
                      v: dataPoint.v,
                      ts: dataPoint.ts,
                    })),
                  }),
                // update the datapoints in the datatrack to match the absolute time alignment
                ...(timeAlignment === DataTrackTimeAlignment.Absolute && {
                  dataPoints: dt.dataPoints,
                }),
              })),
          }),
        {} as DataTrackTypes,
      ),
    [selectedDataTrackTypes, processRecordsCacheKey, timeAlignment],
  );

  const markLines: SeriesMarkLine[] = [];

  Object.keys(selected).forEach((type) =>
    selected[type].forEach((dataTrack) => {
      const timestamp = calculateInoculationTime(
        dataTrack.processRecord.startTimestamp.getTime(),
        dataTrack.processRecord.inoculationTimestamp?.getTime(),
      );
      if (timestamp !== undefined) {
        markLines.push({
          color: inSingleView ? DATA_COLORS.gray : dataTrack.color,
          name: 'Inoculation',
          timestamp,
        });
      }
    }),
  );

  return (
    <TimeSeriesChart
      variant="large"
      series={selected}
      startTime={
        timeAlignment !== DataTrackTimeAlignment.Absolute ? getStartTime() : minTimestamp()
      }
      stopTime={timeAlignment !== DataTrackTimeAlignment.Absolute ? getStopTime() : maxTimestamp()}
      xAxisFormatter={xAxisFormatter}
      yAxisFormatter={yAxisFormatter}
      seriesMarkLines={markLines}
      useRelativeYAxis
      showTooltip
      showToggleSplit={inSingleView}
      showLegend={inSingleView}
      showZoom
      showToolbox
      showSideToolbox={canSetYAxisRange}
    />
  );
}

export default ProcessRecordVisualizationChart;
