import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import { ProcessRecordId } from '../../../../shared/common/types/process-record';
import QKey from '../../../../shared/common/hooks/keys';

import { EventListItem } from '../../../../shared/components/event-list/event-list.definitions';

import { fetchProcessRecordEvents } from './use-process-record-events.helpers';

export default function useProcessRecordEvents(
  id: ProcessRecordId,
): UseQueryResult<EventListItem[], Error> {
  const { acquireAccessToken } = useAuthentication();

  return useQuery({
    queryKey: [QKey.PROCESS_RECORDS, id, QKey.EVENTS],
    queryFn: async () => {
      const accessToken = await acquireAccessToken();
      return fetchProcessRecordEvents(id, accessToken);
    },
  });
}
