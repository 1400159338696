import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import {
  ProcessRecordAttributes,
  ProcessRecordObject,
} from '../../../../shared/common/types/process-record';
import QKey from '../../../../shared/common/hooks/keys';
import { ApiError } from '../../../../shared/common/types/api-error/api-error';

import { editProcessRecordAttributes } from './use-edit-process-record-attributes.helpers';
import { UseEditProccessRecordAttributesInput } from './use-edit-process-record-attributes.definitions';

function useEditProcessRecordAttributes({
  processRecordId,
  onMutate,
  onSuccess,
  onError,
}: UseEditProccessRecordAttributesInput) {
  const queryClient = useQueryClient();

  const { acquireAccessToken } = useAuthentication();

  return useMutation<
    ProcessRecordAttributes,
    ApiError,
    ProcessRecordAttributes,
    ProcessRecordObject
  >({
    mutationKey: ['edit-process-records-attribute', processRecordId],
    mutationFn: async (attributes) => {
      const accessToken = await acquireAccessToken();
      return editProcessRecordAttributes(processRecordId, attributes, accessToken);
    },
    onSuccess: (attributes) => {
      queryClient.invalidateQueries({
        queryKey: [QKey.PROCESS_RECORDS, processRecordId],
      });

      queryClient.setQueryData<ProcessRecordObject>(
        [QKey.PROCESS_RECORDS, processRecordId],
        (old) => old && { ...old, attributes },
      );

      onSuccess?.(attributes);
    },
    onMutate: async (attributes) => {
      // optimistic update

      // cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: [QKey.PROCESS_RECORDS] });

      // snapshot the previous value
      const previousTodos = queryClient.getQueryData<ProcessRecordObject>([
        QKey.PROCESS_RECORDS,
        processRecordId,
      ]);

      queryClient.setQueryData<ProcessRecordObject>(
        [QKey.PROCESS_RECORDS, processRecordId],
        (old) => old && { ...old, attributes },
      );

      onMutate?.(attributes);

      // return a context object with the snapshotted value
      return previousTodos;
    },
    // if the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newAttributes, previousProcessRecord) => {
      queryClient.setQueryData([QKey.PROCESS_RECORDS, processRecordId], previousProcessRecord);
      onError?.(err, newAttributes, previousProcessRecord);
    },
  });
}

export default useEditProcessRecordAttributes;
