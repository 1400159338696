import { transformProcessRecordObjectDTO } from '../../types/process-record/dto';
import { ProcessRecordId, ProcessRecordObject } from '../../types/process-record';
import fetchData from '../../fetch-data';
import { isProcessRecordObjectDTO } from '../../types/process-record/dto/validators';
import { HttpMethods } from '../../types/http';
import { joinPaths } from '../../../utils';
import { projectConfigInstance } from '../../../globals/project-config/project-config-accessor';

export function createProcessRecordUrl(processRecordId: ProcessRecordId): string {
  return joinPaths(
    projectConfigInstance.get('PRS_BACKEND_URL'),
    'process-records',
    processRecordId,
  );
}

export async function fetchProcessRecord(
  processRecordId: ProcessRecordId,
  accessToken: string,
): Promise<ProcessRecordObject> {
  const result = await fetchData(createProcessRecordUrl(processRecordId), {
    method: HttpMethods.Get,
    headers: {
      Accept: 'application/json',
      'Accept-Encoding': 'gzip, deflate, br',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  try {
    isProcessRecordObjectDTO(result);
    return transformProcessRecordObjectDTO(result);
  } catch {
    throw new Error('Invalid Data');
  }
}
