import { UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import {
  DataTrack,
  ProcessRecordId,
  ProcessRecordObject,
} from '../../../../shared/common/types/process-record';
import QKey from '../../../../shared/common/hooks/keys';
import { ApiError } from '../../../../shared/common/types/api-error/api-error';

import { submitDatatracks } from './use-submit-data-tracks.helpers';

function useSubmitDatatracks(
  proccessRecordId: ProcessRecordId,
  { onSuccess, ...options }: UseMutationOptions<unknown, ApiError, DataTrack[], unknown> = {},
) {
  const { acquireAccessToken } = useAuthentication();
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    async mutationFn(datatracks: DataTrack[]) {
      const token = await acquireAccessToken();
      return submitDatatracks(proccessRecordId, datatracks, token);
    },
    mutationKey: [QKey.EXTERNAL_DATA_TRACK, proccessRecordId],
    onSuccess(data, variables, context) {
      // update cache
      queryClient.setQueryData<ProcessRecordObject>(
        [QKey.PROCESS_RECORDS, proccessRecordId],
        (prev) => {
          if (!prev) {
            return undefined;
          }

          return {
            ...prev,
            dataTracks: [...prev.dataTracks, ...data],
          };
        },
      );

      onSuccess?.(data, variables, context);
    },
  });
}

export default useSubmitDatatracks;
