/**
 * convert input string value to corresponding state
 */
// eslint-disable-next-line import/prefer-default-export -- helper file
export const coerceStringToNumber = (value: string) => {
  // disable bound if input is empty
  if (value.trim().length === 0) {
    return undefined;
  }

  const maybeNumber = Number(value);

  // interpret as string when not a number
  if (Number.isNaN(maybeNumber)) {
    return value;
  }

  // cast value to number
  return maybeNumber;
};
