import fetchData from '../../../../shared/common/fetch-data';
import { HttpMethods } from '../../../../shared/common/types/http';
import {
  ProcessRecordAttributes,
  ProcessRecordId,
} from '../../../../shared/common/types/process-record';
import { createProcessRecordUrl } from '../../../../shared/common/hooks/use-multiple-process-records/use-multiple-process-records.helpers';

export function createEditProcessRecordAttributesUrl(processRecordId: string) {
  return `${createProcessRecordUrl(processRecordId)}/attributes`;
}

export async function editProcessRecordAttributes(
  processRecordId: ProcessRecordId,
  attributes: ProcessRecordAttributes,
  accessToken: string,
): Promise<ProcessRecordAttributes> {
  const response = await fetchData(createEditProcessRecordAttributesUrl(processRecordId), {
    method: HttpMethods.Put,
    headers: {
      Accept: 'application/json',
      'Accept-Encoding': 'gzip, deflate, br',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(attributes),
  });
  return response as ProcessRecordAttributes;
}
