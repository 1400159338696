import fetchData from '../../../../shared/common/fetch-data';
import { HttpMethods } from '../../../../shared/common/types/http';
import { Uuid } from '../../../../shared/common/types/uuid';
import { joinPaths } from '../../../../shared/utils';
import { projectConfigInstance } from '../../../../shared/globals/project-config/project-config-accessor';
import { isSetupEvent } from '../../types/dto/validators/setup-dto-validator';
import { Event } from '../../../../shared/common/types/event';

export function createSetupEventsUrl(
  systemId: Uuid,
  timestamp: string,
  unit: string,
  stopTimestamp?: string,
): string {
  const stopTime = stopTimestamp ? `&stop-timestamp=${stopTimestamp}` : '';
  return joinPaths(
    projectConfigInstance.get('PMS_BACKEND_URL'),
    'systems/',
    systemId,
    `events?from-timestamp=${timestamp}&unit=${unit}${stopTime}`,
  );
}

export async function fetchSetupEvents(
  systemId: Uuid,
  timestamp: string,
  accessToken: string,
  unit: string,
  stopTimestamp?: string,
): Promise<Event[]> {
  const result = await fetchData(createSetupEventsUrl(systemId, timestamp, unit, stopTimestamp), {
    method: HttpMethods.Get,
    headers: {
      Accept: 'application/json',
      'Accept-Encoding': 'gzip, deflate, br',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (!Array.isArray(result)) {
    throw new Error('expected to receive an array');
  }

  result.forEach((descriptor) => isSetupEvent(descriptor));

  return result;
}
